import config from "../utils/config.js";

//import fetch from 'node-fetch';
const fetch = require('node-fetch');

//

const createTenantMetricRequest = async function createTenantMetricRequest (user) {

  if (!user) return {};

  const url = `${config.API_SERVER_URL}/api/v1/tenantmetrics/@me`;

  const request = {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
  };

  try {
    const response = await fetch(url, request);
    const status = response.status;
    if (status !== 200 && status !== 201) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'An error occurred.');
    };
    const jsonResponse = await response.json();
    return jsonResponse || {};
  } catch (err) {
    console.log(err);
    throw err;
  }

};

class TenantMetricService {

  static async get (user) {
    try {
      const tenantMetrics = await createTenantMetricRequest(user);
      console.log({msg:'services.tenantmetrics.get', tenantMetrics});
      if (tenantMetrics) return tenantMetrics;
      return;
    } catch (err) {
      console.error({msg:'services.tenantmetrics.get.err', err});
    }
  }

}

export default TenantMetricService;
