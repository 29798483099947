import { json } from "react-router-dom";
import config from "../utils/config.js";
const fetch = require('node-fetch');

//

class TenantService {

  static async getCurrentTenant (userToken) {

    const url = `${config.API_SERVER_URL}/api/v1/tenants/@me`;
    const request = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
    };

    try {
      const response = await fetch(url, request);
      const status = response.status;
      console.log({msg:'services.tenant.getCurrentTenant.status', status});
      if (status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || 'An error occurred.');
      };
      const jsonResponse = await response.json();
      console.log({msg:'services.tenant.getCurrentTenant.jsonResponse', jsonResponse});
      return jsonResponse || undefined;
    } catch (err) {
      throw err;
    }

  }

}

export default TenantService;
