import React, { Component } from "react";
import config from "../utils/config.js";
import { capitalizeWord } from "../utils/capitalizeWord.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheck,  faFireBurner, faHourglass, faSliders,faThermometer2 } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";

import "../css/components/ApplianceControlWidget.css";
import RecipeInteractionService from "../services/recipeinteraction.js";


//

function getDurationOptions () {
  const durationOptionValues = [ 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180 ];
  return durationOptionValues.map(val => <option value={val}>{formatDuration(val*60)}</option>);
}

function getCookModeOptions (applianceTypeId) {
  if (!applianceTypeId) return;
  const applianceTypeMap = {
    //'cooktop' : [ 'heat' ],
    //'stovetop' : [ 'heat' ],
    'stove' : [ 'heat' ],
    //'range' : [ 'bake', 'broil', 'convection', 'air-fry', 'warm', 'proof' ],
    'oven' : [ 'bake', 'broil', 'convection', 'air-fry', 'warm', 'proof' ],
    //'grill' : [ 'grill' ],
    'air-fryer' : [ 'air-fry', 'cook' ],
  };
  const cookModeOptionValues = applianceTypeMap[applianceTypeId];
  return cookModeOptionValues.map(val => <option value={val}>{formatCookMode(val)}</option>);
}

function getCookTempOptions (applianceTypeId, units) {
  console.log('getCookTempOptions ' + applianceTypeId);
  if (!applianceTypeId) return;
  const applianceTypeMap = {
    //'cooktop' : getCookTempCooktopOptions,
    //'stovetop' : getCookTempCooktopOptions,
    'stove' : getCookTempCooktopOptions,
    //'range' : getCookTempDegreesOptions,
    'oven' : getCookTempDegreesOptions,
    'air-fryer': getCookTempDegreesOptions,
  }
  return (applianceTypeMap[applianceTypeId])(units);
}

function getCookTempCooktopOptions () {
  console.log('getCookTempCooktopOptions');
  const cookTempCooktopOptions = ['simmer', 'low', 'medium-low', 'medium', 'medium-high', 'high'];
  return cookTempCooktopOptions.map(val => <option value={val}>{formatCooktopTemp(val)}</option>);
}

function getCookTempDegreesOptions (unit) {
  let cookTempOptions = [];
  for (let i = 100; i < 500; i+=5) {
    cookTempOptions.push(<option value={i}>{formatIntTempToString(i, unit)}</option>)
  }
  return cookTempOptions;
}

function formatDuration (duration) {
  if (!duration) return;
  duration = duration/60;
  if (duration < 60) return `${duration} minutes`;
  const hours = Math.floor(duration / 60);
  let mins = duration - (hours * 60);
  const hourLabel = hours > 1 ? 'hours' : 'hour';
  if (mins === 0) return `${hours} ${hourLabel}`;
  return `${hours} ${hourLabel} ${mins} mins`;
}

function formatCookMode (mode) {
  if (!mode) return '';
  if (mode) return capitalizeWord(mode);
}

function getCookDirectionsText (operation, tempChangeType, prevState) {
  console.log(`getCookDirectionsText: ${operation} ${tempChangeType}`);
  if (!operation && !tempChangeType) return '';
  if (operation && prevState.mode === "off") return 'Setting your ';
  // if (operation === "preheat" && tempChangeType === "increase") return 'Increasing the temperature of your';
  // if (operation === "preheat" && tempChangeType === "decrease") return 'Decreasing the temperature of your';
  if (operation === "preheat" && tempChangeType === "increase") return 'Setting the temperature of your';
  if (operation === "preheat" && tempChangeType === "decrease") return 'Setting the temperature of your ';
  if (operation === "bake") return 'When ready, place the dish in your ';
  return 'Setting your ';
}

function getCookOperationText (operation, tempChangeType, prevState) {
  console.log(`getCookOperationText: ${operation} ${tempChangeType}`);
  if (!operation && !tempChangeType) return '';
  if (operation === "preheat" && prevState.temperatureTarget === 0) return "Preheat";
  if (operation === "preheat" && prevState.mode === "baking") return "Bake";
  if (operation === "bake") return "Bake";
  return capitalizeWord(operation);
}

function formatCookTemp (applianceTypeId, cookTemp, cookTempUnit) {

  if (!applianceTypeId) return;
  if (!config.SUPPORTED_APPLIANCE_TYPES.includes(applianceTypeId)) return;

  const applianceTypeMap = {
    //'cooktop' : formatCooktopTemp,
    //'stovetop' : formatCooktopTemp,
    'stove' : formatCooktopTemp,
    //'range' : formatIntTempToString,
    'oven' : formatIntTempToString,
    'air-fryer': formatIntTempToString,
  };

  return applianceTypeMap[applianceTypeId](cookTemp, cookTempUnit);

}

function formatIntTempToString (temperature, unit='F') {
  return `${temperature}°${unit}`;
}

function formatCooktopTemp (temp) {
  const tempMap = {
    'simmer' : 'Simmer',
    'low' : 'Low',
    'medium-low': 'Medium-Low',
    'medium' : 'Medium',
    'medium-high' : 'Medium-High',
    'high' : 'High'
  };
  return tempMap[temp] || 'Unknown';
}

function formatTemp(tempStr) {

  if (!tempStr) return "";

  const regex = /\s*([0-9]+)\s*([CF])\s*$/i;

  tempStr = tempStr.replace(/\s/g, "");
  const match = tempStr.match(regex);

  if (!match) return tempStr;

  const temperature = match[1];
  const unit = match[2].toUpperCase();
  const result = `${temperature}°${unit}`;

  return result;

}

class ApplianceControlWidget extends Component {

  timer = null;

  constructor() {

    super();

    this.state = {
      recipe: undefined,
      showWidget: true,
      isExpanded: false,
      hasApplianceControls: false,
      applianceName: undefined,
      applianceType: undefined,
      applianceSettings: undefined,
      deltaMode: undefined,
      deltaTempChangeType: undefined,
      newTempTarget: undefined,
      newTempUnits: undefined,
      cookTime: undefined,
    };

  }

  componentDidMount () {

    const { recipe, data, user, tenant } = this.props;  
    
    console.log('componentDidMount', data);

    const { type, appliance, settings, } = data;

    const applianceName = appliance.name;
    const applianceType = appliance.applianceType;
    let applianceTypeId = type;
    const applianceSettings = settings;

    //
    const deltaMode = settings?.operation?.changeMode;
    const deltaTempChangeType = settings?.operation?.increaseTemperatureTo ? "increase" : settings?.operation?.decreaseTemperatureTo ? "decrease": undefined;
    const newTempTarget = settings?.newState?.temperatureTarget;
    const newTempUnits = settings?.newState?.temperatureUnits;
    const cookTime = settings?.duration;

    //
    const showWidget = deltaMode || deltaTempChangeType;
    const hasApplianceControls = (!(deltaMode && deltaMode === "bake")) || deltaTempChangeType;

    if (applianceTypeId === "stove" || applianceTypeId === "stovetop") applianceTypeId = "cooktop";

    this.setState({
      recipe,
      showWidget,
      hasApplianceControls,
      applianceName,
      applianceType,
      applianceTypeId,
      applianceSettings,
      deltaMode,
      deltaTempChangeType,
      newTempTarget,
      newTempUnits,
      cookTime,
    });

  }

  //

  addInteraction (type, data) {
    RecipeInteractionService.add({_id:this.state.recipe._id, updateType:type, data}, this.props.user);
  }

  //

  activateAppliance = () => {
    this.setState({applianceActivated:true});
    this.addInteraction('appliance-control', {
      type: this.state.applianceTypeId,
      mode: this.state.deltaMode,
      settings: this.state.applianceSettings,
    });
  };

  applianceBtnMouseDown = (event) => {
    const button = event.currentTarget;
    button.style.setProperty('--animation-time', '2s');
    button.classList.add('is-active');
    this.timer = setTimeout(this.activateAppliance, 2000);
  };

  applianceBtnMouseUp = (event) => {
    console.log('applianceBtnMouseUp');
    clearTimeout(this.timer);
    const button = event.currentTarget;
    button.classList.remove('is-active');
    button.style.transition = 'none';
  };

  toggleExpansion = () => {
    this.setState({isExpanded: !this.state.isExpanded });
  };
  
  handleSettingsChange = (event) => {
    
    console.log({msg:'handleSettingsChange', id:event.target.id, value:event.target.value });
    
    const id = event.target.id;
    const value = event.target.value;

    if (id === 'mode') this.setState({deltaMode: value});
    if (id === 'temperature') this.setState({newTempTarget: value});
    if (id === 'duration') this.setState({cookTime: value});

  }

  //

  getCookModeOptionsHtml = () => {
    if (!this.state.deltaMode) return <></>;
    return (
      <div>
        <label htmlFor="mode"><FontAwesomeIcon icon={faFireBurner} className="settings-icon" />Mode</label>
          <select id="mode" name="mode" defaultValue={this.state.deltaMode} onChange={this.handleSettingsChange}>
            {getCookModeOptions(this.state.applianceTypeId)}
          </select>
      </div>
    );
  }

  getCookTempOptionsHtml = () => {
    if (!this.state.newTempTarget) return <></>;
    return (
      <div>
        <label htmlFor="temperature"><FontAwesomeIcon icon={faThermometer2} className="settings-icon" />Temperature</label>
        <select id="temperature" name="temperature" defaultValue={this.state.newTempTarget} onChange={this.handleSettingsChange}>
          {getCookTempOptions(this.state.applianceTypeId, this.state.newTempUnits)}
        </select>
      </div>
    );
  }

  getCookTimeOptionsHtml = () => {
    if (!this.state.cookTime) return <></>;
    return (
      <div>
        <label htmlFor="duration"><FontAwesomeIcon icon={faHourglass} className="settings-icon" />Duration</label>
        <select id="duration" name="duration" defaultValue={this.state.cookTime} onChange={this.handleSettingsChange}>
          {getDurationOptions(this.state.cookTime)}
        </select>
      </div>
    );
  }

  getActivateButtonHtml = (applianceActivated) => {

    const currentButtonText = applianceActivated ? 'Activated' : `Press and hold for 2 seconds to run`;
    const currentButtonIcon = applianceActivated ? faCheck : faArrowRight;

    if (!this.state.hasApplianceControls) return <></>;
    return (
      <div className='consumerrecipecard-appliance-control-buttons'>
        <button className="activate" onMouseDown={this.applianceBtnMouseDown} onMouseUp={this.applianceBtnMouseUp} onMouseLeave={this.applianceBtnMouseUp}>
          <FontAwesomeIcon icon={currentButtonIcon} className="settings-icon" />
          <span>{currentButtonText}</span>
        </button>
      </div>
    );
  }

  //

  render() {

    console.log({msg:'render', 'state':this.state});

    const { showWidget, isExpanded, hasApplianceControls, applianceActivated, applianceName, applianceType, applianceTypeId, applianceSettings, deltaMode, deltaTempChangeType, newTempTarget, newTempUnits, cookTime, } = this.state;
    const { user, tenant } = this.props;

    const tenantId = tenant.id
    const tenantName = tenant.name;
    const brandPlatformName = tenant.brandData.platformName;
    const brandPlatformWebsite = tenant.brandData.platformWebsite;
    const brandColor = tenant.brandData.color || '#ffffff';
    const brandImageUrl = '/brands/' + tenant.id + '.png'

    // const brandApplianceInfo = tenantId !== 'ladle-default' ? <span style={{fontWeight:600}}>{tenantName} {applianceName} </span> : <></>;
    const brandApplianceInfo = tenantId !== 'ladle-default' ? <span style={{fontWeight:600}}>{applianceName} </span> : <></>;

    document.documentElement.style.setProperty('--brand-color', `${brandColor}`);
    document.documentElement.style.setProperty('--brand-color-2', `${brandColor}33`);
    
    const detailsDirectionsHtml = <>{getCookDirectionsText(deltaMode, deltaTempChangeType, applianceSettings?.previousState)}</>;
    // const detailsDeviceNameHtml = <><b>{tenantName} {applianceName}</b> {applianceTypeId}</>;
    const detailsDeviceNameHtml = <><b>{applianceName}</b> {applianceTypeId}</>;
    const detailsOperationHtml = (deltaMode || deltaTempChangeType) ? <> to <span className='detail-box'>{getCookOperationText(deltaMode, deltaTempChangeType, applianceSettings.previousState)}</span></> : <></>;
    const detailsTempHtml = newTempTarget ? <> at <span className='detail-box'>{formatCookTemp(applianceTypeId, newTempTarget, newTempUnits)}</span></> : <></>;
    const detailsTimeHtml = cookTime ? <> for <span className='detail-box'>{formatDuration(cookTime)}.</span></> : <></>;
    const detailsIcon = !hasApplianceControls ? <FontAwesomeIcon icon={faBell} style={{height:'20px', marginLeft: '4px', marginRight: '8px', color: 'grey'}} /> : <></>;
    const detailsTextHtml = <>{detailsIcon}{detailsDirectionsHtml}{detailsDeviceNameHtml}{detailsOperationHtml}{detailsTempHtml}{detailsTimeHtml}</>;


    const applianceWidgetHtml = (

      <div className="consumerrecipecard-appliance-control">

        <div className={`consumerrecipecard-appliance-control-header ${isExpanded ? 'header-expanded' : 'header-collapsed'}`} onClick={hasApplianceControls ? this.toggleExpansion : null}>

          <div className='consumerrecipecard-appliance-control-header-left'>

            <span className={`consumerrecipecard-appliance-control-header-icon ${isExpanded ? 'header-icon-expanded' : 'header-icon-collapsed'}`}>
              <img src="/icons/ladle-magic-icon-2-white-512.png" alt="ladle-ai" className="ladle-magic-icon" />
            </span>

            <span className="consumerrecipecard-appliance-control-header-title">
              <h4><span style={{fontWeight:300}}>Control your </span>{brandApplianceInfo}<span style={{fontWeight:300}}>{applianceTypeId} with {brandPlatformName}</span></h4>
            </span>

          </div>

          <div className='consumerrecipecard-appliance-control-header-right'>
            <a href={brandPlatformWebsite}><img className="brand-platform-logo" src={`/brands/${tenantId}/platform-logo.png`} alt={`${tenantId} logo`} /></a>
          </div>

        </div>

        <div className={`consumerrecipecard-appliance-control-body`}>

          <div className={`consumerrecipecard-appliance-control-settings ${isExpanded ? 'settings-expanded' : 'settings-collapsed'}`}>
            { this.getCookModeOptionsHtml(deltaMode) }
            { this.getCookTempOptionsHtml(newTempTarget) }
            { this.getCookTimeOptionsHtml(cookTime) }
          </div>

          <div className='consumerrecipecard-appliance-control-details'>
            <span className={`details-text ${!hasApplianceControls ? 'padded' : ''}`}>{detailsTextHtml}</span>
            { hasApplianceControls ? <span className='adjust-settings-button' onClick={this.toggleExpansion}><FontAwesomeIcon icon={faSliders} className="settings-icon"/>Adjust Settings</span> : <></> }
          </div>

          { this.getActivateButtonHtml(applianceActivated) }

        </div>

      </div>
    );

    if (showWidget) return applianceWidgetHtml;
    return <></>;

  }
}

export default ApplianceControlWidget;