import React, { Component } from 'react';
import { Link } from "react-router-dom";

import '../../css/account.css';
import AuthService from '../../services/auth';
import GuestService from '../../services/guest';
import MyFormComponent from './userForm';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../components/ui/dialog"
import PreferencesForm from '../consumer/form';

//

class Login extends Component {

    constructor(props) {

        super(props);

        document.title = `Ladle - Login`;

        this.state = {
            email: undefined,
            password: undefined,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value,
            invalidLogin: false,
        });
    }

    async handleSubmit(event) {

        event.preventDefault();

        const { email, password } = this.state;
        const { setLoginStateFunc } = this.props;

        const loginRes = await AuthService.login(email, password);
        console.log({ msg: 'handleSubmit', loginRes });

        const defaultUserType = "consumer";
        const { user } = loginRes;

        if (user) {
            console.log("success, logging in");
            let userType = (user.roles && user.roles[0]) ? user.roles[0] : defaultUserType;
            setLoginStateFunc({ userType, userToken: user.token });
        } else if (loginRes.error) {
            this.setState({ password: undefined, user: undefined, invalidLogin: 'Email or password were incorrect. Please try again.' });
        } else {
            this.setState({ password: undefined, user: undefined, invalidLogin: 'Email or password were incorrect. Please try again.' });
        }

    }

    async LoginFunc() {
        const { setLoginStateFunc } = this.props;
        const loginRes = await GuestService.login();
        console.log({ msg: 'handleSubmit', loginRes });

        const defaultUserType = "consumer";
        const { user } = loginRes;

        if (user) {
            console.log("success, logging in");
            let userType = (user.roles && user.roles[0]) ? user.roles[0] : defaultUserType;
            setLoginStateFunc({ userType, userToken: user.token });
        } else if (loginRes.error) {
            this.setState({ password: undefined, user: undefined, invalidLogin: 'Email or password were incorrect. Please try again.' });
        } else {
            this.setState({ password: undefined, user: undefined, invalidLogin: 'Email or password were incorrect. Please try again.' });
        }

    }
    render() {

        const { email, password, invalidLogin } = this.state;
        const invalidLoginHtml = invalidLogin ? <div className="account-error">{invalidLogin}</div> : <></>;

        return (

            <>
                <div className="account-app login">

                    <div className="account-app-background"></div>
                    <section className="section-content">

                        <div className="account-tile login">

                            <div className="account-tile-header">
                                <img className="logo" src="/ladle_logo_highres.png" alt="Ladle Logo" />

                                <span className="instruction-text">Please login below</span>
                            </div>

                            {invalidLoginHtml}

                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" name="email" value={email || ''} required onChange={this.handleInputChange} />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" name="password" value={password || ''} required onChange={this.handleInputChange} />
                                </div>
                                <button type="submit" className='green'>Login</button>
                                <div className="help-text text-center">Need help? <Link to="mailto:info@ladle.cooking">Send us a note</Link> or <Link to="/register">Create an Account</Link></div>
                            </form>
                            {/* <div>
                                <Dialog className="min-w-[60vw]" id="dialog-trigger" style={{
                                        background: "#f5f5f5",
                                        overflowY: "scroll !important"
                                    }}>
                                    <DialogTrigger><button
                                        className='bg-[#0e4621]  p-4 mt-4 px-12  text-white hover:bg-[#3e8e41] rounded-[32px] ;'>Try Now</button></DialogTrigger>
                                    <DialogContent className="w-[100%] min-w-[60vw] lg:max-w-screen-lg overflow-y-scroll h-[100%] md:h-[85vh] max-h-screen" style={{
                                        background: "#f5f5f5",
                                        overflowY: "scroll !important",
                                        
                                    }}>
                                        <DialogHeader>
                                            <DialogDescription >
                                                <PreferencesForm istryNow={true} onSubmit={() => this.LoginFunc()} />
                                            </DialogDescription>
                                        </DialogHeader>
                                    </DialogContent>
                                </Dialog>
                            </div> */}


                        </div>

                    </section>

                    <footer>
                        Copyright 2024 Ladle Cooking, Inc.
                    </footer>

                </div>

            </>

        );
    }
}

export default Login;