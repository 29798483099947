import React, { Component } from 'react';
import logger from "../../utils/log.js";

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faLink, faLinkSlash, faRemove, faXmark } from '@fortawesome/free-solid-svg-icons'

import { capitalizeWord } from '../../utils/capitalizeWord.js'

//

import '../../css/components/ConsumerApplianceRow.css';

const log = logger('components.consumer.ApplianceRow');

//

class ConsumerApplianceRow extends Component {

    constructor(props) {       
        super(props);
        this.state = {};
    }

    async componentDidMount() {
    }

    render() {


        console.log({msg:'render', 'props':this.props})

        const { userAppliance, user, tenant } = this.props;
        const { applianceType, connected, name } = userAppliance;
        const { brand, model, category, capabilities } = applianceType;

        const imageUrl = `/brands/${tenant.id}/appliances/${model.toLowerCase()}.png`;

        return (
            <>
                <TableRow className='applianceRowTableRow'>
                    <TableCell className='applianceRowTableCell image'>
                        <div className='applianceRowImageContainer'>
                            <img className='applianceImage' src={imageUrl} alt={name} />
                        </div>
                    </TableCell>
                    <TableCell className='applianceRowTableCell nameRow'>{capitalizeWord(name)}</TableCell>
                    <TableCell className='applianceRowTableCell modelRow'>{capitalizeWord(model)}</TableCell>
                    <TableCell className='applianceRowTableCell categoryRow'>{capitalizeWord(category)}</TableCell>
                    <TableCell className={`applianceRowTableCell connectedRow ${connected ? 'connected' : 'disconnected'}`}>{connected ? "Connected" : "Disconnected"}</TableCell>
                    <TableCell className='applianceRowTableCell manageButtonRow'>
                        <button className='white manageApplianceButton' onClick={this.props.onConnectClick}><FontAwesomeIcon className={`manageButtonIcon ${connected ? 'connected' : 'disconnected'}`} icon={connected ? faLinkSlash : faLink} />{connected ? "Disconnect" : "Connect"}</button>
                    </TableCell>
                    <TableCell className='applianceRowTableCell removeButtonRow'>
                        <button className='removeApplianceButton' onClick={this.props.onRemoveClick}><FontAwesomeIcon className='removeButtonIcon' icon={faRemove} /></button>
                    </TableCell>
                </TableRow>


            </>
        );
    }
}

export default ConsumerApplianceRow;
