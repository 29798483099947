import React, { Component } from 'react';
import ConsumerRecipeCard from '../../components/ConsumerRecipeCard';
import ConsumerRecipeCardDS from '../../components/ConsumerRecipeCardDesktop';

//

class ConsumerViewRecipeConsumerPage extends Component {

  constructor (props) {

    super(props);
    
    this.state = {
      recipe_id : this.props.params.recipe_id,
      isMobile: true
    };

  }

  componentDidMount() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.setState({
        isMobile: true
      })
    }else{
      this.setState({
        isMobile: false
      })
    }

  }

  render () {

    return (
      <>
        {/* <div style={{fontSize: '12pt', fontWeight: '500', paddingBottom: '24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ textAlign: 'right', width: '240px'}}></span>
        </div> */}
        {
          this.state.isMobile ? <ConsumerRecipeCard recipe_id={this.state.recipe_id} type='consumer' user={this.props.user} tenant={this.props.tenant} navigate={this.props.navigate} /> :
          <ConsumerRecipeCardDS recipe_id={this.state.recipe_id} type='consumer' user={this.props.user} tenant={this.props.tenant} navigate={this.props.navigate} />
        }
        
      </>
    )

  }

};

export default ConsumerViewRecipeConsumerPage;