import config from "../utils/config.js";

//import fetch from 'node-fetch';
const fetch = require('node-fetch');

//

const getApplianceTypes = async function getApplianceTypes (id="", options={}, user) {

  console.log(`services.appliancetype.getApplianceTypes: ${id}`);

  if (!user) return [];

  const queryString = new URLSearchParams(options);

  console.log(`${queryString}`);

  let url = `${config.API_SERVER_URL}/api/v1/appliancetypes`;
  if (id) {
      url += `/${id}`;
  }
  url += `?${queryString}`;

  console.log(`services.appliancetype.getApplianceTypes.url: ${url}`);

  const request = {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
  };

  try {
    const response = await fetch(url, request);
    const status = response.status;
    console.log({msg:'services.appliancetype.getApplianceTypes.status', status});
    if (status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'An error occurred.');
    };
    const jsonResponse = await response.json();
    console.log({msg:'services.appliancetype.getApplianceTypes.jsonResponse', jsonResponse});
    return jsonResponse || [];
  } catch (err) {
    throw err;
  }

};

const createApplianceType = async function createApplianceType (data, user) {
  
  console.log('services.appliancetype.createApplianceType', data);

  if (!user) return;

  const serverUrl = `${config.API_SERVER_URL}/api/v1/appliancetypes`;
  console.log(`services.appliancetype.createApplianceType.url: ${serverUrl}`);

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(serverUrl, request);
    const jsonResponse = await response.json();
    const responseCode = response.status;
    console.log({msg:'services.appliancetype.createApplianceType.jsonResponse', responseCode, jsonResponse});

    if (response.status !== 200 && response.status !== 201) return;
    return jsonResponse;

  } catch (err) {
    throw err;
  }

};

const updateApplianceType = async function updateApplianceType (id, data, user) {
  
  console.log('services.appliancetype.updateApplianceType', id, data, user);

  if (!user) return;

  const serverUrl = `${config.API_SERVER_URL}/api/v1/appliancetypes/${id}`;
  console.log(`services.appliancetype.updateApplianceType.url: ${serverUrl}`);

  const request = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(serverUrl, request);
    const jsonResponse = await response.json();
    const responseCode = response.status;
    console.log({msg:'services.appliancetype.updateApplianceType.jsonResponse', responseCode, jsonResponse});

    if (response.status !== 200) return;
    return jsonResponse;

  } catch (err) {
    throw err;
  }

};

//

class ApplianceTypeService {

  static async count (options={}, user) {
    console.log('services.appliancetype.count');
    try {
      const applianceTypes = await getApplianceTypes(undefined, options, user);
      if (applianceTypes) return applianceTypes.length;
      return 0;
    } catch (err) {
      console.error({msg:'services.appliancetype.count.err', err});
    }
  }

  static async list (options, user) {
    console.log('services.appliancetype.list');
    try {
      let applianceTypes = getApplianceTypes(undefined, options, user);
      if (applianceTypes) return applianceTypes;
      return [];
    } catch (err) {
      console.error({msg:'services.appliancetype.list.err', err});
      throw err;
    }
  }

  static async get (id, options, user) {
    try {
      const applianceType = await getApplianceTypes(id, options, user);
      console.log({msg:'services.appliancetype.get', applianceType});
      return applianceType;
    } catch (err) {
      console.error({msg:'services.appliancetype.get.err', err});
    }
  }

  static async update(id, data, user) {
    try {
      console.log({ msg: 'services.appliancetype.update', data });
      const response = await updateApplianceType(id, data, user);
      console.log({ msg: 'services.appliancetype.update', response });
      return response;
    } catch (err) {
      console.error({ msg: 'services.appliancetype.update > error', err });
      throw err;
    }
  }

  static async create(data, user) {
    try {
      console.log({ msg: 'services.appliancetype.create', data });
      const response = await createApplianceType(data, user);
      console.log({ msg: 'services.appliancetype.create', response });
      return response;
    } catch (err) {
      console.error({ msg: 'services.appliancetype.create > error', err });
      throw err;
    }
  }

}

export default ApplianceTypeService;
