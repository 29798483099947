import React, { Component } from 'react';

import config from "../utils/config.js";
import CreatorData from '../data/creatorData';
import BrandData from '../data/brandData';

import TenantMetricService from '../services/tenantmetric.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendUp, faChartSimple, faDollarSign, faFileLines, faUserCheck, faEye, faMagnifyingGlassDollar, faEyeDropper, faUsers, faPercent, faUsersCog } from '@fortawesome/free-solid-svg-icons'

import '../css/SideBar.css';

//

const getCreatorMetrics = () => {

    const cpc = .125;
    let totalViews = 295215;
    let totalEarnings = totalViews * cpc;

    let totalEarningsStr = totalEarnings.toLocaleString('en-us', { style: "currency", currency: "USD", maximumFractionDigits: 0 });
    let totalViewsStr = totalViews.toLocaleString('en-us');

    return [
        {
            icon: faDollarSign,
            color: '#ccfdcc',
            title: "TOTAL EARNINGS",
            value: totalEarningsStr,
        },
        {
            icon: faChartSimple,
            color: '#c5d6e9',
            title: "TOTAL VIEWS",
            value: totalViewsStr,
        },
        {
            icon: faArrowTrendUp,
            color: '#dbc6eb',
            title: "LIFETIME ENGAGEMENT",
            value: "31%",
        },
        {
            icon: faPercent,
            color: 'lightgrey',
            title: "CLICKTHROUGH RATE",
            value: "18%",
        },
        {
            icon: faMagnifyingGlassDollar,
            color: '#d1d18d',
            title: "$ PER VIEW",
            value: "$0.35",
        },
        {
            icon: faFileLines,
            color: 'lightgreen',
            title: "RECIPES/VIDEOS",
            value: "186/149",
        },
        {
            icon: faUserCheck,
            color: 'lightblue',
            title: "SPONSORSHIPS",
            value: "6",
        },
        {
            icon: faUsers,
            color: 'lightpink',
            title: "FOLLOWERS",
            value: "157,903",
        },
    ];

};

const getTenantMetrics = (tenantMetrics) => {

    const cpc = .125;
    
    let fakeViews = 295215;
    let totalViews = tenantMetrics.totalTenantViews + fakeViews;
    let totalViewsStr = totalViews.toLocaleString('en-us');

    return [
        {
            icon: faUsersCog,
            color: '#ccfdcc',
            title: "TOTAL REACH",
            value: "1,572,271",
        },
        {
            icon: faChartSimple,
            color: '#c5d6e9',
            title: "TOTAL VIEWS",
            value: totalViewsStr,
        },
        {
            icon: faArrowTrendUp,
            color: '#dbc6eb',
            title: "LIFETIME IMPRESSIONS",
            value: "458,920",
        },
        {
            icon: faPercent,
            color: 'lightgrey',
            title: "CLICKTHROUGH RATE",
            value: "37%",
        },
        {
            icon: faMagnifyingGlassDollar,
            color: '#d1d18d',
            title: "$ PER VIEW",
            value: "$0.35",
        },
        {
            icon: faFileLines,
            color: 'lightgreen',
            title: "TOTAL RECIPES",
            value: "3,923",
        },
        {
            icon: faUserCheck,
            color: 'lightblue',
            title: "ACTIVE SPONSORSHIPS",
            value: "12",
        },
        {
            icon: faUsers,
            color: 'lightpink',
            title: "FOLLOWERS",
            value: "35,903",
        },
    ];

};


class SideBar extends Component {

    constructor(props) {

        super(props);

        this.state = {
            user: undefined,
            tenant: undefined,
            tenantMetrics: undefined,
        }

    }

    //

    async componentDidMount () {
        console.log({msg:'SideBar > componentDidMount', 'user':this.props.user});
        const tenantMetrics = await this.getTenantMetrics(this.props.user);
        this.setState({
            user: this.props.user,
            tenant: this.props.tenant,
            tenantMetrics
        });
    }
    
    //

    async getTenantMetrics(user) {
        console.log({ msg: 'getTenantMetrics'});
        try {
            return await TenantMetricService.get(user);
        } catch (err) {
            console.error('getTenantMetrics > error', err);
        }
    }

    getMetrics = (currentMetrics) => {
        return currentMetrics.map(metric => (
            <div key={metric.title} className="floating-menu-content">
                <div className="floating-menu-icon" style={{ backgroundColor: metric.color }}>
                    <FontAwesomeIcon style={{ color: '#666' }} icon={metric.icon} />
                </div>
                <div className="floating-menu-metric">
                    <h4>{metric.title}</h4>
                    <h5>{metric.value}</h5>
                </div>
            </div>
        ));
    };

    getCreatorContent = () => {

        const currentCreatorId = localStorage.getItem('currentCreatorId') || config.DEFAULT_CREATOR_ID;
        const currentCreator = CreatorData[currentCreatorId];
        const metricsHtml = this.getMetrics(getCreatorMetrics());

        return (
            <div className='floating-menu-creator'>
                <div className="floating-menu-header" style={{paddingTop:'256px'}}>
                    <h1>{currentCreator.name}</h1>
                    <h2>@{currentCreator.handle}</h2>
                </div>
                <div className="floating-menu-section">
                    {metricsHtml}
                </div>
            </div>
        );
    };

    getTenantContent = (user, tenant, tenantMetrics) => {

        console.log({msg:'getTenantContent', user, tenant});

        const metricsHtml = this.getMetrics(getTenantMetrics(tenantMetrics));
        const tenantName = tenant.name;
        const tenantHandle = tenant.brandData.handle;
        const tenantColor = tenant.brandData.color || '#ffffff';
        const tenantImageUrl = '/brands/' + tenant.id + '.png'

        const brandImage = (
            <div className="floating-menu-image">
                <img className="brand-image" src={tenantImageUrl} alt={`${tenantName} Logo`} />
            </div>
        );

        const tenantColorStyle = {
            background: `linear-gradient(0deg, rgba(255,255,255,1) 60%, ${tenantColor}11 90%, ${tenantColor}33 100%)`
        };

        return (
            <div className='floating-menu-brand' style={tenantColorStyle}>
                {brandImage}
                <div className="floating-menu-header">
                    <h1>{tenantName}</h1>
                    <h2>@{tenantHandle}</h2>
                </div>
                <div className="floating-menu-section">
                    {metricsHtml}
                </div>
            </div>
        );
    };

    //

    render() {

        const { userType } = this.props;
        const { tenantMetrics, user, tenant } = this.state;

        if (!user || !tenant) return <></>;

        console.log('userType: ' + userType);

        return userType === 'creator' ? this.getCreatorContent() : this.getTenantContent(user, tenant, tenantMetrics);

    }

}

export default SideBar;