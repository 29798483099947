const creatorData = {
    honeykki: {
        id: "honeykki",
        name: "Honeykki",
        handle: "honeykki",
        description: "Simple & Easy Korean home cooking",
        values: "Bringing joy through cooking and delicious food.",
        types: [
            "Cooking",
            "Food vlogging"
        ],
        tags: [
            "Korean cuisine",
            "Home-cooked meals",
            "Step-by-step recipes"
        ],
        expertise: "Korean home cooking",
        geographic_reach: "Global",
        demographics: "Korean food enthusiasts, Home cooks",
        social: [
            {
                type: "youtube",
                profile: "honeykkicook",
                count: "2.12M"
            },
            {
                type: "instagram",
                profile: "honeykkicook",
                count: "500k"
            },
            {
                type: "twitter",
                profile: "honeykkicook",
                count: "100k"
            }
        ],
        url: "https://www.youtube.com/channel/UCvQPUPoMK0Smj-OHeUe9SEw",
        engaged: true,
        rating: 3,
        ppc: 0.61,
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    thekitchn: {
        id: "thekitchn",
        name: "The Kitchn",
        handle: "thekitchn",
        description: "Kitchen inspiration for home cooks.",
        values: "Creative, accessible, and delicious cooking.",
        types: [
            "Cookware"
        ],
        tags: [
            "Recipe inspiration",
            "Home cooking",
            "Food trends"
        ],
        expertise: "Recipe development and food trends",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "TheKitchnVideos",
                count: "300k"
            },
            {
                type: "tiktok",
                profile: "thekitchn",
                count: "200k"
            },
            {
                type: "instagram",
                profile: "thekitchn",
                count: "100k"
            }
        ],
        url: "https://www.thekitchn.com",
        engaged: true,
        rating: 2,
        ppc: 0.76,
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    fitmencook: {
        id: "fitmencook",
        name: "Kevin Curry",
        handle: "fitmencook",
        description: "Healthy recipes for busy people.",
        values: "Fitness, nutrition, and meal prepping.",
        types: [
            "Food"
        ],
        tags: [
            "Healthy eating",
            "Meal prepping",
            "Fitness nutrition"
        ],
        expertise: "Healthy meal planning and fitness nutrition",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "FitMenCook",
                count: "500k"
            },
            {
                type: "tiktok",
                profile: "fitmencook",
                count: "350k"
            },
            {
                type: "instagram",
                profile: "fitmencook",
                count: "400k"
            }
        ],
        url: "https://www.fitmencook.com",
        engaged: true,
        rating: 3,
        ppc: 0.93,
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    zoebakes: {
        id: "zoebakes",
        name: "Zoe Baker",
        handle: "zoebakes",
        email: "zoe@zoebakes.com",
        description: "Healthy recipes for busy people.",
        values: "Fitness, nutrition, and meal prepping.",
        types: [
            "Food"
        ],
        tags: [
            "Healthy eating",
            "Meal prepping",
            "Fitness nutrition"
        ],
        expertise: "Healthy meal planning and fitness nutrition",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "ZoeBakes",
                count: "500k"
            },
            {
                type: "tiktok",
                profile: "zoebakes",
                count: "350k"
            },
            {
                type: "instagram",
                profile: "zoebakes",
                count: "400k"
            }
        ],
        url: "https://www.zoebakes.com",
        engaged: true,
        rating: 3,
        ppc: 0.93,
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    halfbakedharvest: {
        id: "halfbakedharvest",
        name: "Tieghan Gerard",
        handle: "halfbakedharvest",
        description: "Inspiration for all of life's crazy beautiful moments.",
        values: "Creative, bold, and wholesome recipes.",
        types: [
            "Food"
        ],
        tags: [
            "Recipe inspiration",
            "Home cooking",
            "Wholesome meals"
        ],
        expertise: "Creative recipe development and food photography",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "halfbakedharvest",
                count: "200k"
            },
            {
                type: "tiktok",
                profile: "halfbakedharvest",
                count: "150k"
            },
            {
                type: "instagram",
                profile: "halfbakedharvest",
                count: "900k"
            }
        ],
        url: "https://www.halfbakedharvest.com",
        engaged: true,
        rating: 1,
        ppc: 0.42,
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    minimalistbaker: {
        id: "minimalistbaker",
        name: "Dana Shultz",
        handle: "minimalistbaker",
        description: "Simple recipes that make you feel good.",
        values: "Plant-based and gluten-free cooking.",
        types: [
            "Food"
        ],
        tags: [
            "Plant-based recipes",
            "Gluten-free",
            "Easy cooking"
        ],
        expertise: "Plant-based and gluten-free recipe development",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "minimalistbaker",
                count: "800k"
            },
            {
                type: "tiktok",
                profile: "minimalistbaker",
                count: "250k"
            },
            {
                type: "instagram",
                profile: "minimalistbaker",
                count: "1.2M"
            }
        ],
        url: "https://minimalistbaker.com",
        engaged: true,
        rating: 2,
        ppc: 0.61,
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    smittenkitchen: {
        id: "smittenkitchen",
        name: "Deb Perelman",
        handle: "smittenkitchen",
        description: "Fearless cooking from a tiny kitchen in New York City.",
        values: "Approachable and delicious homemade recipes.",
        types: [
            "Food"
        ],
        tags: [
            "Homemade cooking",
            "Recipe inspiration",
            "Baking"
        ],
        expertise: "Home cooking and recipe development",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "smittenkitchen",
                count: "150k"
            },
            {
                type: "tiktok",
                profile: "smittenkitchen",
                count: "50k"
            },
            {
                type: "instagram",
                profile: "smittenkitchen",
                count: "700k"
            }
        ],
        url: "https://smittenkitchen.com",
        engaged: true,
        rating: 3,
        ppc: 0.81,
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    maangchi: {
        id: "maangchi",
        name: "Emily Kim",
        handle: "maangchi",
        description: "Real Korean cooking.",
        values: "Authentic Korean cuisine made easy.",
        types: [
            "Food"
        ],
        tags: [
            "Korean cooking",
            "Traditional recipes",
            "Asian flavors"
        ],
        expertise: "Korean cuisine and traditional recipes",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "Maangchi",
                count: "1M"
            },
            {
                type: "tiktok",
                profile: "maangchi",
                count: "500k"
            },
            {
                type: "instagram",
                profile: "maangchi",
                count: "800k"
            }
        ],
        url: "https://www.maangchi.com",
        engaged: true,
        rating: 2,
        ppc: 0.67,
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    cookieandkate: {
        id: "cookieandkate",
        name: "Kathryne Taylor",
        handle: "cookieandkate",
        description: "Whole foods and vegetarian recipes.",
        values: "Real, whole foods and vibrant vegetarian cooking.",
        types: [
            "Food"
        ],
        tags: [
            "Vegetarian recipes",
            "Whole foods",
            "Healthy cooking"
        ],
        expertise: "Vegetarian recipe development and whole foods",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "cookieandkate",
                count: "250k"
            },
            {
                type: "tiktok",
                profile: "cookieandkate",
                count: "100k"
            },
            {
                type: "instagram",
                profile: "cookieandkate",
                count: "500k"
            }
        ],
        url: "https://cookieandkate.com",
        engaged: true,
        rating: 3,
        ppc: 0.91,
        recipe_count: Math.round((Math.random() * 350) + 45),
    },
    pinchofyum: {
        id: "pinchofyum",
        name: "Lindsay Ostrom",
        handle: "pinchofyum",
        description: "Food and recipe blog with simple and tasty recipes.",
        values: "Simple and flavorful recipes for everyday cooking.",
        types: [
            "Food"
        ],
        tags: [
            "Recipe inspiration",
            "Food photography",
            "Easy cooking"
        ],
        expertise: "Recipe development and food photography",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "pinchofyum",
                count: "400k"
            },
            {
                type: "tiktok",
                profile: "pinchofyum",
                count: "150k"
            },
            {
                type: "instagram",
                profile: "pinchofyum",
                count: "1.5M"
            }
        ],
        url: "https://pinchofyum.com",
        engaged: true,
        rating: 2,
        ppc: 0.71,
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    downshiftology: {
        id: "downshiftology",
        name: "Lisa Bryan",
        handle: "downshiftology",
        description: "Healthy, whole food recipes.",
        values: "Wholesome and flavorful recipes with a focus on wellness.",
        types: [
            "Food"
        ],
        tags: [
            "Healthy cooking",
            "Whole foods",
            "Wellness"
        ],
        expertise: "Healthy and wholesome recipe development",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "downshiftology",
                count: "350k"
            },
            {
                type: "tiktok",
                profile: "downshiftology",
                count: "120k"
            },
            {
                type: "instagram",
                profile: "downshiftology",
                count: "600k"
            }
        ],
        url: "https://downshiftology.com",
        engaged: true,
        rating: 1,
        ppc: 0.53,
        recipe_count: Math.round((Math.random() * 350) + 45),
    },
    thebakingchocolatess: {
        id: "thebakingchocolatess",
        name: "Kim Lange",
        handle: "thebakingchocolatess",
        description: "Indulgent dessert recipes.",
        values: "Decadent and delicious sweet treats.",
        types: [
            "Food"
        ],
        tags: [
            "Dessert recipes",
            "Baking inspiration",
            "Sweet treats"
        ],
        expertise: "Dessert recipe development and baking",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "thebakingchocolatess",
                count: "100k"
            },
            {
                type: "tiktok",
                profile: "thebakingchocolatess",
                count: "50k"
            },
            {
                type: "instagram",
                profile: "thebakingchocolatess",
                count: "300k"
            }
        ],
        url: "https://www.thebakingchocolatess.com",
        engaged: true,
        rating: 2,
        ppc: 0.62,
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    bromabakery: {
        id: "bromabakery",
        name: "Sarah Fennel",
        handle: "bromabakery",
        description: "Indulgent baking recipes with a modern twist.",
        values: "Modern and creative baking recipes.",
        types: [
            "Food"
        ],
        tags: [
            "Baking recipes",
            "Desserts",
            "Creative twists"
        ],
        expertise: "Baking recipe development and creative twists",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "bromabakery",
                count: "80k"
            },
            {
                type: "tiktok",
                profile: "bromabakery",
                count: "40k"
            },
            {
                type: "instagram",
                profile: "bromabakery",
                count: "250k"
            }
        ],
        url: "https://bromabakery.com",
        engaged: true,
        rating: 3,
        ppc: 0.88,
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    thefoodinmybeard: {
        id: "thefoodinmybeard",
        name: "Dan Whalen",
        handle: "thefoodinmybeard",
        description: "Unique and creative recipes.",
        values: "Inventive recipes for all occasions.",
        types: [
            "Food"
        ],
        tags: [
            "Creative recipes",
            "Unique flavors",
            "Food experimentation"
        ],
        expertise: "Inventive recipe development and flavor combinations",
        geographic_reach: "Global",
        demographics: "Millennials, Gen Z",
        social: [
            {
                type: "youtube",
                profile: "thefoodinmybeard",
                count: "70k"
            },
            {
                type: "tiktok",
                profile: "thefoodinmybeard",
                count: "30k"
            },
            {
                type: "instagram",
                profile: "thefoodinmybeard",
                count: "200k"
            }
        ],
        url: "https://www.thefoodinmybeard.com",
        engaged: true,
        rating: 2,
        ppc: 0.75,
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    internetshaquille: {
        id: 'internetshaquille',
        name: 'Victor Nevarez',
        handle: 'internetshaquille',
        description: 'Delicious and approachable recipes for all.',
        values: 'Simple and flavorful cooking for home chefs.',
        types: ['Food'],
        tags: ['Delicious recipes', 'Home cooking', 'Food inspiration'],
        expertise: 'Recipe development and culinary expertise',
        geographic_reach: 'Global',
        demographics: 'Millennials, Gen Z',
        social: [
            { type: 'youtube', profile: 'internetshaquille', count: '500k' },
            { type: 'tiktok', profile: 'internetshaquille', count: '250k' },
            { type: 'instagram', profile: 'internetshaquille', count: '700k' }
        ],
        url: 'https://www.internetshaquille.com',
        engaged: true,
        rating: 3,
        ppc: '0.83',
        recipe_count: Math.round((Math.random() * 350) + 45),
        optedin: true,
    },
    chadwithaj: {
        id: 'chadwithaj',
        name: 'Jad Slaibeh',
        handle: 'chadwithaj',
        description: 'Creative recipes with a twist.',
        values: 'Inventive and unique dishes for food enthusiasts.',
        types: ['Food'],
        tags: ['Creative cooking', 'Flavorful dishes', 'Food experimentation'],
        expertise: 'Innovative recipe development and culinary techniques',
        geographic_reach: 'Global',
        demographics: 'Millennials, Gen Z',
        social: [
            { type: 'youtube', profile: 'chadwithaj', count: '400k' },
            { type: 'tiktok', profile: 'chadwithaj', count: '200k' },
            { type: 'instagram', profile: 'chadwithaj', count: '600k' }
        ],
        url: 'https://www.chadwithaj.com',
        engaged: true,
        rating: 2,
        ppc: '0.71',
        recipe_count: Math.round((Math.random() * 350) + 45)
    },
};

export default creatorData;