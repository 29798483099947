import { json } from "react-router-dom";
import config from "../utils/config.js";
const fetch = require('node-fetch');

//

const createUser = async function createUser (data) {

  console.log('createUser', data);

  const serverUrl = `${config.AUTH_SERVER_URL}${config.AUTH_REGISTRATION_ENDPOINT}`;
  console.log(`services.user.createUser.url: ${serverUrl}`);

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(data),
  };

  // try {

    const response = await fetch(serverUrl, request);
    const jsonResponse = await response.json();
    const responseCode = response.status;
    //console.log({msg:'services.user.createUser.jsonResponse', request, responseCode, jsonResponse});

    if ((responseCode == 200 || responseCode == 201) && jsonResponse.user) {
      return { 'user': jsonResponse.user };
    } else {
      return { 'error': jsonResponse.error };
    }

};

class UserService {

  static async getCurrentUser (userToken) {

    const url = `${config.API_SERVER_URL}/api/v1/users/@me`;
    const request = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
    };

    try {
      const response = await fetch(url, request);
      const status = response.status;
      console.log({msg:'services.user.getCurrentUser.status', status});
      if (status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || 'An error occurred.');
      };
      const jsonResponse = await response.json();
      console.log({msg:'services.recipe.getCurrentUser.jsonResponse', jsonResponse});
      return jsonResponse || undefined;
    } catch (err) {
      throw err;
      localStorage.clear();
    }

  }

  static async create (data) {
    try {
      return await createUser(data);
    } catch (error) {
      return { error }
    }
  }

}

export default UserService;
