import TagPill from './TagPill.js';

//
import '../css/components/TagPill.css';


class RemovableTagPill extends TagPill {

    constructor (props) {
        super(props, true);
    }

    render () {
        return super.render();
    }

}

export default RemovableTagPill;