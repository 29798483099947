import { json } from "react-router-dom";
import config from "../utils/config.js";
const fetch = require('node-fetch');

//

class AuthService {

  static async login(email, password) {
    try {

      console.log('services.auth.login');

      const serverUrl = `${config.AUTH_SERVER_URL}${config.AUTH_LOGIN_ENDPOINT}`;
      console.log(`services.auth.login: ${serverUrl}`);

      const request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          email,
          password
        }),
      };

      const response = await fetch(serverUrl, request);
      const jsonResponse = await response.json();
      const responseCode = response.status;
      console.log({ msg: 'services.user.login.jsonResponse', request, responseCode, jsonResponse });

      if ((responseCode == 200 || responseCode == 201) && jsonResponse.user) {
        return { 'user': jsonResponse.user };
      } else {
        return { 'error': jsonResponse.error };
      }

    } catch (error) {
      return { error }
    }
  }

}

export default AuthService;
