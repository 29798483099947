import React, { Component } from 'react';
import { Link } from "react-router-dom";
import pluralize from 'pluralize';
import LoadingIcons from 'react-loading-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBowlFood, faLanguage, faEdit, faUsersViewfinder, faTools, faLink, faTags, faMagicWandSparkles, faCalculator } from '@fortawesome/free-solid-svg-icons';

import ContentMetadataService from '../services/contentmetadata';
import RecipeService from '../services/recipe';
import SmartRecipeService from '../services/smartrecipe';
import ParseRecipeRequestService from '../services/parsereciperequest';

import logger from "../utils/log.js";
import { capitalizeWord } from '../utils/capitalizeWord';
import getPrettyQuantity from '../utils/getPrettyQuantity';
import getPluralMeasurement from '../utils/getPluralMeasurement';
import minsToHours from '../utils/minsToHours';

import PersonalizationDropdown from './PersonalizationDropdown';

import { languageOptions, dietOptions, servingOptions, languageOptionsObj, dietOptionsObj, servingOptionsObj } from '../utils/personalizationOptions';


//

import '../css/RecipeCard.css';

//

const log = logger('components.ManageRecipeCard');

//

const placeholderImageUrl = "/public/loading-header-background.3dd9fdab3d8b7800753e.jpg";

class ManageRecipeCard extends Component {

    constructor(props) {

        super(props);

        this.state = {
            recipe_id: this.props.recipe_id,
            recipe: undefined,
            personalizationOptions: {},
            isLoading: true,
        };

        this.personalizeRecipeRequest = this.personalizeRecipeRequest.bind(this);

    }

    async componentDidMount() {

        const queryParams = new URLSearchParams(window.location.search)
        const personalizationOptions = {
            //recipe_id: this.state.recipe_id,
            language: queryParams.get("language") || undefined,
            includedIngredients: queryParams.get("include") ? queryParams.get("include").split(',') : undefined,
            excludedIngredients: queryParams.get("exclude") ? queryParams.get("exclude").split(',') : undefined,
        };

        log.debug({ msg: 'componentDidMount', personalizationOptions });

        this.setState({ personalizationOptions });

        if (personalizationOptions.language || personalizationOptions.includedIngredients || personalizationOptions.excludedIngredients) {
            this.personalizeRecipeRequest();
        } else {
            await this.getRecipe(this.state.recipe_id);
        }

    }

    //

    async getRecipe(recipe_id) {
        const recipe = await RecipeService.get(recipe_id, {}, this.props.user);
        console.log({msg:'getRecipe', recipe});
        if (!recipe) {
            this.setState({ recipe_id, recipe: undefined, contentMetadata: undefined, lastParseRequest: undefined, isLoading: false });
        } else {
            if (!recipe.description) recipe.description = "";
            const contentMetadata = await ContentMetadataService.getByRecipeId(this.state.recipe_id, this.props.user);
            const lastParseRequest = await ParseRecipeRequestService.getLastSuccessfulRequest(this.state.recipe_id, this.props.user);
            console.log({msg:'getRecipe', lastParseRequest});
            this.setState({ recipe_id, recipe, contentMetadata, lastParseRequest, isLoading: false });
        }
    }

    // async personalizeRecipeRequest() {

    //     const { recipe_id, reprocess, temperature, personalizationOptions } = this.state;

    //     log.debug({ msg: 'personalizeRecipeRequest', 'oldPersonalizationOptions': personalizationOptions, newPersonalizationOptions });

    //     const updatedPersonalizationOptions = Object.assign(personalizationOptions, newPersonalizationOptions);
    //     this.setState({ isLoading: true, updatedPersonalizationOptions, recipe: undefined });

    //     const { language, diet, servings, includedIngredients, excludedIngredients } = updatedPersonalizationOptions;

    //     const personalizationObj = {
    //         recipe_id,
    //         reprocess,
    //         temperature,
    //         personalizationOptions: {
    //             language: language ? language.id : undefined,
    //             diet: diet ? diet.id : undefined,
    //             servings: servings ? servings.id : undefined,
    //             includedIngredients: includedIngredients ? includedIngredients : undefined,
    //             excludedIngredients: excludedIngredients ? excludedIngredients : undefined,
    //         },
    //     };
    //     const smartrecipe = await SmartRecipeService.create(personalizationObj, this.props.user);

    //     document.title = `Ladle - ${smartrecipe.updatedTitle || smartrecipe.title}`;
    //     log.debug({ msg: 'personalizeRecipeRequest', smartrecipe });
    //     setTimeout(() => { this.setState({ isLoading: false, recipe: smartrecipe }) }, 2500);

    // }

    async personalizeRecipeRequest() {

        const { recipe_id, reprocess, aiTemperature, personalizationOptions } = this.state;
        const { language, diet, servings, temperatureUnits, measurementUnits, includedIngredients, excludedIngredients } = personalizationOptions;

        this.setState({ isLoading: true, recipe: undefined, personalizationDuration:undefined });

        const personalizationObj = {
            recipe_id,
            reprocess,
            aiTemperature,
            personalizationOptions: {
                language: language ? language.id : undefined,
                diet: diet ? diet.id : undefined,
                servings: servings ? servings.id : undefined,
                units: {
                    temperature: temperatureUnits ? temperatureUnits.id : undefined,
                    measurement: measurementUnits ? measurementUnits.id : undefined,
                },
                includedIngredients: includedIngredients ? includedIngredients : undefined,
                excludedIngredients: excludedIngredients ? excludedIngredients : undefined,
            },
        };

        const personalizationStartTime = new Date();
        const smartrecipe = await SmartRecipeService.create(personalizationObj, this.props.user);
        const personalizationDuration = (new Date() - personalizationStartTime) / 1000;

        if (smartrecipe) {
            document.title = `Ladle - Smart Recipe - ${smartrecipe.updatedTitle || smartrecipe.title}`;
            console.log({ msg: 'personalizeRecipeRequest', personalizationDuration, smartrecipe });
            //setTimeout(() => { this.setState({ isLoading: false, recipe: smartrecipe, personalizationDuration }) }, 1000);
            this.setState({ isLoading: false, recipe: smartrecipe, personalizationDuration });
        } else {
            this.setState({isLoading:false, recipe: undefined});
        }

    }

    createToolButton(label, icon, link) {
        return (
            <div className='personalize-dropdown'>
                <Link to={link}><button style={{backgroundColor:'#333333'}}><FontAwesomeIcon icon={icon} style={{ paddingRight: 6 }} /> {label}</button></Link>
            </div>
        );
    }
    
    //

    getQuantityString(quantity) {
        if (!quantity || quantity.length === 0) return '';
        if (quantity[0] && quantity[1] && quantity[0] === quantity[1]) return `${getPrettyQuantity(quantity[0])}`;
        if (quantity[0] && quantity[1]) return `${getPrettyQuantity(quantity[0])} - ${getPrettyQuantity(quantity[1])}`;
        if (quantity[0]) return `${getPrettyQuantity(quantity[0])}`;
        console.log({ msg: "Unknown Quantity", quantity });
        return;
    }

    getPrettyName(measurement, quantity, quantityStr, name) {
        name = measurement.unit === "unset" ? name.charAt(0).toUpperCase() + name.slice(1) : name;
        //console.log('getPrettyName > ', measurement, quantity, name)
        let singleQuantity = (quantity && Array.isArray(quantity) && quantity[0] === quantity[1]) ? quantity[0] : undefined;
        let rangeQuantity = (quantity && Array.isArray(quantity) && quantity[0] !== quantity[1]) ? true : false;
        if ((measurement.unit === "unset" && (singleQuantity && singleQuantity !== 1)) || rangeQuantity) {
            return pluralize(name, singleQuantity, false);
        } else {
            return name;
        }
    }

    //

    // define description & ingredients page
    createRequiredItemsSection(ingredients, cookware, heroImageUrl) {

        const ingredientsHtml = (
            <div className="listSection">
                <a href="#ingredients"><h2 id="ingredients">Ingredients</h2></a>
                <ul>{this.getIngredientList(ingredients)}</ul>
            </div>
        );

        const cookwareHtml = this.getCookwareList(cookware);
        const applianceHtml = this.getApplianceList(cookware);

        return (
            <div key={`required-items-section`} className='recipe-card'>
                <div className="required-items-container">
                    <div className="required-items-content">
                        {ingredientsHtml}
                        {applianceHtml}
                        {cookwareHtml}
                    </div>
                </div>
            </div>
        );
    }

    getCookwareHtml(cookwareItem, index) {

        let { _id, originalText, name, id, type, notes, size, quantity } = cookwareItem;
        let quantityStr = quantity || '1';
        let nameStr = name;
        let notesStr = notes ? ` (${notes})` : '';
        let prefixStr = size || '';

        return (
            <li key={`cookwareListItem${index}`} title={originalText}>
                <input type="checkbox" id={`checkbox${index}`} />
                <label htmlFor={`checkbox${index}`}>
                    <span id={`cookware/${id}`} className='cookware-target'>
                        <span style={{ color: '#333' }}>{quantityStr} </span>
                        <span style={{ color: '#666' }}>{prefixStr} <a style={{ textDecoration: 'underline' }} href={`../../cookware/${id}`}>{nameStr}</a></span>
                        <span style={{ color: '#999' }}>{notesStr}</span>
                    </span>
                </label>
            </li>
        );

    }

    getApplianceList(cookwareAndAppliances) {
        if (!cookwareAndAppliances || cookwareAndAppliances.length == 0) return <></>;
        const appliances = cookwareAndAppliances.filter(item => item.type == "appliance");
        if (!appliances || appliances.length == 0) return <></>;
        return (
            <div className="listSection">
                <a href="#appliances"><h2 id="appliances">Appliances</h2></a>
                <ul>{appliances.map(this.getCookwareHtml)}</ul>
            </div>
        );
    }

    getCookwareList(cookwareAndAppliances) {
        if (!cookwareAndAppliances || cookwareAndAppliances.length == 0) return <></>;
        let cookware = cookwareAndAppliances.filter(item => item.type == "cookware" || item.type == "supply");
        if (!cookware || cookware.length == 0) return <></>;
        cookware = cookware.sort((a,b) => {
            if (a.type < b.type) return -1;
            if (a.type > b.type) return 1;
            return 0;
        });
        return (
            <div className="listSection">
                <a href="#cookware"><h2 id="cookware">Cookware & Supplies</h2></a>
                <ul>{cookware.map(this.getCookwareHtml)}</ul>
            </div>
        );
    }

    getIngredientList(ingredients) {

        const ingredientList = ingredients.map((ingredient, index) => {

            const originalText = ingredient.originalText;
            const recipeIngredientId = ingredient._id;
            const ingredientsHtml = ingredient.ingredientOptions.map((entry, index) => {

                let { name, id, measurement, notes = '', preparation = '', size = '', type = '', quantity } = entry;

                //TODO: This is masking unknown unit names, should remove when debugging
                let measurementStr = measurement.unit ? ' ' + getPluralMeasurement(measurement, quantity) + ' ' : ' ';
                measurementStr = measurementStr.indexOf("unknown") > 0 ? "" : measurementStr;

                console.log({msg:'measurementStr', measurementStr});
                let quantityStr = this.getQuantityString(quantity) || '';
                let nameStr = this.getPrettyName(measurement, quantity, quantityStr, name);
                let notesStr = notes ? ' (' + notes.replace(/^\(|\)$/g, '') + ')' : ''; // remove existing ( ), [ ], { } at start/end of string then surround by ( )
                let prefixStr = `${size} ${type}`;
                //prefixStr = prefixStr.charAt(0).toUpperCase() + prefixStr.slice(1);
                let preparationStr = preparation  ? `, ${preparation}` : '';

                let html = (
                    <span key={`ingredient-${recipeIngredientId}-${index}`} id={`ingredients/${recipeIngredientId}`} className='ingredient-target text-base lg:text-lg xl:text-xl'>
                        <span style={{ color: '#333' }}>{quantityStr}{measurementStr}</span>
                        <span style={{ color: '#666' }}>{prefixStr} <a style={{ textDecoration: 'underline' }} href={`../../ingredients/${id}`}>{nameStr}</a>{preparationStr}</span>
                        <span style={{ color: '#999' }}>{notesStr}</span>
                        {index < ingredient.ingredientOptions.length - 1 && <span> or </span>}
                    </span>
                );
                return html;
            });

            return (
                <li key={`ingredientListItem${index}`} title={originalText}>
                    <input type="checkbox" id={`checkbox${index}`} />
                    <label htmlFor={`checkbox${index}`}>
                        {ingredientsHtml}
                    </label>
                </li>
            );

        });

        return ingredientList;

    }

    getStepList (steps) {
        return steps.map((step, index) => {
            return (<li key={index}>{step.text}</li>)
        });
    }

    //

    reprocessRecipe = async () => {
        console.log('reprocessRecipe: ' + this.state.recipe.source.url);
        const response = await ParseRecipeRequestService.create({ type: 'url', urls: [ 'http://' + this.state.recipe.source.url ] }, this.props.user);
        log.debug({ msg: 'reprocessRecipe', response });
    }
    //

    render() {
        const { isLoading, recipe, contentMetadata, lastParseRequest, personalizationOptions } = this.state;
        log.debug({ msg: 'render', isLoading, recipe });
        if (!isLoading && !recipe) return this.failedToLoad();
        if (isLoading && !recipe) return this.renderLoading();
        document.title = `Ladle - ${recipe.updatedTitle || recipe.title}`;
        return this.renderRecipe(recipe, contentMetadata, lastParseRequest, personalizationOptions);
    }

    failedToLoad() {
        return (
            <div className="recipecard">
                <div className="recipecard-loading-header">
                </div>
                <div className="recipecard-loading-body">
                    <div>Failed to load Recipe</div>
                </div>
            </div>
        )
    }

    renderLoading() {

        let loadingText = "Loading Recipe...";
        let personalizationText = "";

        const { diet, language, servings, includedIngredients, excludedIngredients } = this.state.personalizationOptions;

        if (servings || diet || language || includedIngredients || excludedIngredients) {
            loadingText = 'Personalizing Recipe';
            personalizationText = diet ? personalizationText + diet.label : personalizationText;
            personalizationText = (diet && servings) ? personalizationText + ', ' : personalizationText;
            personalizationText = servings ? personalizationText + servings.label + ' servings' : personalizationText;
            personalizationText = ((diet && language) || (servings && language)) ? personalizationText + ', ' : personalizationText;
            personalizationText = language ? personalizationText + language.label : personalizationText;
        } else if (!this.state.recipe) {
            loadingText = "Loading Recipe";
        }

        return (
            <div className="recipecard">
                <div className="recipecard-loading-header">
                </div>
                <div className="recipecard-loading-body">
                    <div><FontAwesomeIcon icon={faMagicWandSparkles} /> {loadingText}</div><div style={{ fontSize: '14pt', fontWeight: '200' }}>{personalizationText}</div>
                    <div><LoadingIcons.TailSpin height="48" stroke="#0e4621" fill="#0e4621" /></div>
                </div>
            </div>
        )

    }

    renderRecipe(recipe, contentMetadata, lastParseRequest, personalizationOptions) {

        console.log({msg:'renderRecipe', contentMetadata, recipe});

        let { _id, title, subtitle, author, times: cooktime, description, servings:recipeServings, source, media, ingredients, cookware, steps, tags,  cuisines, calories, createdAt, updatedAt } = recipe;

        let contentUrl, socialUrls;
        if (contentMetadata) {
            socialUrls = contentMetadata.socialUrls || undefined;
            contentUrl = contentMetadata.contentUrl || undefined;
        }

        let scrapingMethod;
        if (lastParseRequest && lastParseRequest.parsedData) {
            let method = lastParseRequest.method == "ai" ? "AI" : lastParseRequest.method;
            scrapingMethod = `Web scraping via ${method}` || "Not Sure";
        }

        const { diet, language, servings, includedIngredients, exludedIngredients } = personalizationOptions;
        const heroImageUrl = (media && media.heroImageUrl) ? media.heroImageUrl : placeholderImageUrl;

        // create tags
        const tagData = (tags !== undefined && tags.length > 0) ? tags.map(capitalizeWord).join(', ') : 'No tags set';

        const socialUrlsHtml = socialUrls ? socialUrls.map(url => <Link to={url}>{url}</Link>) : 'None Set';

        // generate cook/prep/total times + tooltip
        const prepTime = cooktime && cooktime.prepTime ? minsToHours(cooktime.prepTime) : '-';
        const cookTime = cooktime && cooktime.cookTime ? minsToHours(cooktime.cookTime) : '-';
        const totalTime = cooktime && cooktime.totalTime ? minsToHours(cooktime.totalTime) : '-';
        const timeTip = 'Prep: ' + prepTime + '\r\n' + 'Cook: ' + cookTime + '\r\n' + 'Total: ' + totalTime;

        // generate calories data
        const calLow = calories && calories.low;
        const calHigh = calories && calories.high;
        const caloriesSingle = (calLow && calHigh && calLow == calHigh) ? calLow : undefined;
        const caloriesString = caloriesSingle || ((calLow && calHigh) ? `${calLow} - ${calHigh}` : 'Not Set');

        const options = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };
          
        // format last updated date
        const createdAtStr = new Intl.DateTimeFormat('en-US', options).format(new Date(createdAt));
        const updatedAtStr = new Intl.DateTimeFormat('en-US', options).format(new Date(updatedAt));

        // find & format first cuisine in the cuisines array if present
        let firstCuisine = cuisines && cuisines[0] ? cuisines[0] : undefined;
        firstCuisine = firstCuisine && firstCuisine.split(',')[0] ? firstCuisine.split(',')[0].trim() : firstCuisine ? firstCuisine.trim() : "";
        const cuisineStr = cuisines && cuisines[0] ? capitalizeWord(firstCuisine) : "-";

        return (

            <div className="recipecard">

                <div className="recipecard-header">
                    <img src={heroImageUrl} className='header-image' alt="Recipe Image" />
                    <div className="recipecard-title">{title}</div>
                </div>

                <div className="recipecard-creator-tools">
                    <p><FontAwesomeIcon icon={faTools} style={{ paddingRight: 12 }} /> Creator Tools</p>
                    {this.createToolButton('Edit Recipe', faEdit, '')};
                    {this.createToolButton('View as User', faUsersViewfinder, `/${this.props.type}/recipes/${_id}/view`)};
                    <span style={{'marginRight': '16px'}}></span>
                    <PersonalizationDropdown id="servings" listOptions={servingOptions} listSelection={servings} personalizationId="servings" listIcon={faCalculator} listName="Servings" listLabel="Review Servings" personalizeRequestFunc={this.personalizeRecipeRequest} />
                    <PersonalizationDropdown id="diets" listOptions={dietOptions} listSelection={diet} personalizationId="diet" listIcon={faBowlFood} listName="Diet" listLabel="Review Diets" personalizeRequestFunc={this.personalizeRecipeRequest} />
                    <PersonalizationDropdown id="languages" listOptions={languageOptions} listSelection={language} personalizationId="language" listIcon={faLanguage} listName="Language" listLabel="Review Languages" personalizeRequestFunc={this.personalizeRecipeRequest} />
                </div>

                <div className="recipecard-details">

                    <div className="recipecard-metadata">
                        <h2>Metadata</h2>
                        <p><span style={{ color: '#555', fontSize: '13pt', fontWeight: 500 }}>Author:</span> {author && author.name || 'Zoe Baker'}</p>
                        <p><span style={{ color: '#555', fontSize: '13pt', fontWeight: 500 }}>Tags:</span> {tagData}</p>
                        <p><span style={{ color: '#555', fontSize: '13pt', fontWeight: 500 }}>Video Links: </span> {socialUrlsHtml}</p>
                        <p><span style={{ color: '#555', fontSize: '13pt', fontWeight: 500 }}>Ingested via: </span> {scrapingMethod} <span><a className="hover blue" onClick={this.reprocessRecipe}>(Reprocess Recipe)</a></span></p>
                        <p><span style={{ color: '#555', fontSize: '13pt', fontWeight: 500 }}>Created: </span> {createdAtStr}</p>
                        <p><span style={{ color: '#555', fontSize: '13pt', fontWeight: 500 }}>Updated: </span> {updatedAtStr}</p>
                    </div>

                    <div className="recipecard-data">
                        <div title={timeTip} className="entry">
                            <h4>Cook Time</h4>
                            <p>{totalTime}</p>
                        </div>
                        <div className="divider"></div>
                        <div className="entry">
                            <h4>Ingredients</h4>
                            <p>{ingredients.length}</p>
                        </div>
                        <div className="divider"></div>
                        <div className="entry">
                            <h4>Servings</h4>
                            <p>{recipeServings ? recipeServings : '-'}</p>
                        </div>
                        <div className="divider"></div>
                        <div className="entry">
                            <h4>Cuisine</h4>
                            <p>{cuisineStr}</p>
                        </div>
                        <div className="divider"></div>
                        <div className="entry">
                            <h4>Est. Cal / Serving</h4>
                            <p>{caloriesString}</p>
                        </div>
                    </div>

                    <div className="recipecard-description">
                        <h2>Description</h2>
                        {description}
                    </div>
                    {this.createRequiredItemsSection(ingredients, cookware, heroImageUrl)}
                    <div>
                        <h2>Directions</h2>
                        <ul className="recipecard-instructions">{this.getStepList(steps)}</ul>
                    </div>
                </div>

            </div>
        )

    }

}

export default ManageRecipeCard;