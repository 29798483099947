const capitalizeWord = (str) => {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const capitalizeAllWords = (str) => {
  if (!str) return;
  return str.replace(/\b\w/g, letter => letter.toUpperCase());
};

export {
  capitalizeWord,
  capitalizeAllWords
};