const pluralConversions = {
    "teaspoon" : "teaspoons",
    "tablespoon": "tablespoons",
    "cup": "cups",
    "fluid ounce": "fluid ounces",
    "fluid ounces": "fluid ounce",
    "pint": "pints",
    "quart": "quarts",
    "gallon": "gallons",
    "milliliter": "milliliters",
    "liter": "liters",
    "ounce": "ounces",
    "pound": "pounds",
    "gram": "grams",
    "kilogram": "kilograms",
    "inch": "inches",
    "centimeter": "centimeters",
    "pinch": "pinches",
    "dash": "dashes",
    "drop": "drops",
    "sprinkle": "sprinkles",
    "handful": "handfuls",
    "dozen": "dozens",
    "clove" : "cloves",
    "leaf" : "leaves",
    "leave" : "leaves",
    "slice" : "slices",
    "sprig" : "sprigs",
    "bunch" : "bunches"
};

const getPluralMeasurement = (measurement, quantity) => {
    let { unit, notes } = measurement;
    let singleQuantity;
    if (Array.isArray(quantity) && quantity[0] === quantity[1]) singleQuantity = quantity[0];
    //console.log({unit, quantity, notes});
    if (unit === "unset") unit = notes || '';
    if (singleQuantity && Math.ceil(singleQuantity) == 1) return unit;
    return pluralConversions[unit] ? pluralConversions[unit] : unit;
};


export default getPluralMeasurement;