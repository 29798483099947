import config from "../utils/config.js";

//import fetch from 'node-fetch';
const fetch = require('node-fetch');

//

const createRecipeMetricRequest = async function createRecipeMetricRequest (ids, options={}, user) {

  if (!user || !ids) return {};

  let url = `${config.API_SERVER_URL}/api/v1/recipemetrics`;

  const data = {
    ids
  };

  const request = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(url, request);
    const status = response.status;
    if (status !== 200 && status !== 201) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'An error occurred.');
    };
    const jsonResponse = await response.json();
    return jsonResponse || {};
  } catch (err) {
    console.log(err);
    throw err;
  }

};

class RecipeMetricService {

  // static async get (id, options, user) {
  //   try {
  //     const recipeMetrics = await getRecipeMetrics(id, options, user);
  //     console.log({msg:'services.recipemetrics.get', recipeMetrics});
  //     if (recipeMetrics) return recipeMetrics;
  //     return;
  //   } catch (err) {
  //     console.error({msg:'services.recipemetrics.get.err', err});
  //   }
  // }

  static async createRequest (idArr, options, user) {
    try {
      const recipeMetrics = await createRecipeMetricRequest(idArr, options, user);
      //console.log({msg:'services.recipemetrics.createRequest > result', recipeMetrics});
      if (recipeMetrics) return recipeMetrics;
      return;
    } catch (err) {
      console.error('services.recipemetrics.createRequest.err');
      console.log(err);
    }
  }

}

export default RecipeMetricService;
