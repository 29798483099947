import React, { useState, useEffect } from "react";
import config from "../utils/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ApplianceControlWidget from "./ApplianceControlWidget";
import TagPill from "./TagPill.js";
import { capitalizeWord } from "../utils/capitalizeWord";
import convertParagraphToSentenceArray from "../utils/converParagraphToSentenceArray";
import minsToHours from "../utils/minsToHours";
import getPrettyDuration from "../utils/getPrettyDuration.js";
import * as Dialog from '@radix-ui/react-dialog';
import toast from 'react-hot-toast';
import SmartRecipeService from '../services/smartrecipe';

import {
  faEarth,
  faCircle,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import {
  faInstagram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

//
import "../css/ConsumerRecipeCard.css";
import "../css/components/ApplianceControlWidget.css";
import ConsumerPersonaliseChat from "./ConsumerPersonaliseChat.js";

//
const ConsumerpersonalizedRecipeCard = () => {
  const [personaliseData, setpersonaliseData] = useState();
  const [showChat, setShowChat] = useState(false);
  const [contentMetadata, setcontentMetadata] = useState("");
  const [StateRecipe, setStateRecipe] = useState({
    recipe_id: undefined,
    recipe: undefined,
    reprocess: false,
    aiTemperature: 0.66,
    personalizationOptions: {},
    curPageNumber: 0,
    isLoading: true,
    animateName: "no animate",
    buttonClicked: false,
    myAppliances: "",
  });
  const [feedback, setFeedback] = useState('')

  useEffect(() => {
    const getItem = async () => {
      try {
        let data = await localStorage.getItem("personalized_recipe");
        let parseData = JSON.parse(data).personlized_recipe;
        console.log(parseData);
        await setpersonaliseData(parseData);
      } catch (error) {
        console.log(error);
      }
    };
    getItem();
  }, []);

  const getCookwareList = (cookwareAndAppliances, addInteraction) => {

    if (!cookwareAndAppliances || cookwareAndAppliances.length === 0)
      return <></>;

    return (
      <div className="listSection">
        <a
          href="#cookware"
          onClick={() => addInteraction("link", { type: `cookwaresection` })}
        >
          <h1 id="cookware" className="text-[14pt] lg:text-[24pt] text-[#0e4621] mt-3">Cookware & Supplies</h1>
        </a>
        <ul className="w-56 md:w-[100%] text-[10pt] lg:text-lg xl:text-xl">
          {cookwareAndAppliances.map((cookware, i) => {

            return (
              <li key={`cookwareListItem${i}`} title={cookware}>
                <input type="checkbox" id={`cookware-checkbox${i}`} />
                <label htmlFor={`cookware-checkbox${i}`}>
                  <span style={{ color: "#666" }}>
                    <a
                      style={{ textDecoration: "underline" }}
                    // href={`../../${type}/${id}`}
                    //   onClick={() =>
                    //     addInteraction("link", {
                    //       type: `${type}section`,
                    //       id,
                    //     })
                    //   }
                    >
                      {cookware}
                    </a>
                  </span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(false);
  }, [personaliseData]);

  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    //hour: 'numeric',
    //minute: 'numeric',
    //hour12: true,
  };
  // format last updated date

  const personalizationStartTime = new Date();
  const personalizationDuration =
    (new Date() - personalizationStartTime) / 1000;
  const updatedAtStr = new Intl.DateTimeFormat("en-US", options).format(
    new Date("2024-05-11T15:02:00.000Z")
  );
  const durationStr =
    !config.DEMO_MODE && personalizationDuration
      ? ` @ ${personalizationDuration.toString()}s`
      : "";


  function getTagPills(tags, count, randomColors = false) {
   
    return tags
      .slice(0, count)
      .map((tagLabel, index) => (
        <TagPill
          key={`${tagLabel}${index}`}
          label={tagLabel}
          color="green"
          size="medium"
         
        />
      ));
  }

  const tagPillsHtml = () => {
    return personaliseData?.tags !== undefined &&
      personaliseData?.tags?.length > 0
      ? getTagPills(personaliseData?.tags, 8)
      : undefined;
  };

  let url = "contentUrl";

  var index = url.indexOf("%");
  if (index !== -1) {
    url = url.substring(0, index);
  }

  // generate cook/prep/total times + tooltip
  const prepTime =
    personaliseData?.cook_time && personaliseData?.prep_time
      ? minsToHours(personaliseData?.prep_time)
      : "-";
  const cookTime =
    personaliseData?.cook_time && personaliseData?.cook_time
      ? minsToHours(personaliseData?.cook_time)
      : "-";
  const totalTime =
    personaliseData?.cook_time && personaliseData?.total_time
      ? minsToHours(personaliseData?.total_time)
      : "-";
  const timeTip = `Prep: ${prepTime}\r\nCook: ${cookTime}\r\nTotal: ${totalTime}`;

  const headerStyleNoVideo = {
    backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.55) 25%, rgba(255, 255, 255, 0.10) 100%), url('${personaliseData?.hero_image_src}')`,
  };

  const headerStyleNoVideoMobile = {
    backgroundImage: `url('${personaliseData?.hero_image_src}')`,
  };


  const getIngredientList = (ingredients) => {
    return ingredients?.map((ingredient, index) => {
      const originalText = ingredient.originalText;
      const ingredientListItems = ingredients?.map((ingredient) => {
        <span style={{ color: "#666" }}>
          <span className="ingredient-name">{ingredient}</span>
        </span>;
      });

      return (
        <li
          key={`ingredientListItem${index}`}
          title={originalText}
          className="animate__animated animate__backInUp animate__delay-2s text-[10pt] lg:text-lg xl:text-xl"
        >
          <input type="checkbox" id={`checkbox${index}`} />
          <label htmlFor={`checkbox${index}`}>{ingredient}</label>
        </li>
      );
    });
  };
  function getWindowScrolledPercentage() {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const visibleHeight = window.innerHeight || document.documentElement.clientHeight;
    const totalHeight = document.documentElement.scrollHeight;
    return parseFloat(((scrollPosition / (totalHeight - visibleHeight)) * 100).toFixed(2));
  }

  async function addInteraction(updateType, data = {}, recipe_id) {
    recipe_id = recipe_id || personaliseData.recipe_id;
    console.log({
      msg: "addInteraction",
      recipe_id: personaliseData.recipe_id,
      //   user: this.props.user,
      updateType,
      data,
    });
    data.scrolledPercentage = getWindowScrolledPercentage(); // always set window scrolled percentage
    // await RecipeInteractionService.add({_id:recipe_id, updateType, data, }, this.props.user);
  }

  function getCookwareHtml(cookwareItem, index) {
    console.log(cookwareItem);

    const { _id, originalText, name, id, type, notes, size, quantity } =
      cookwareItem;
    const quantityStr = quantity && quantity > 1 ? quantity : undefined;
    const prefixStr =
      quantityStr && size ? size : size ? capitalizeWord(size) : undefined;
    const nameStr = prefixStr ? name : capitalizeWord(name);
    const notesStr = notes ? ` (${notes})` : "";

    return (
      <li key={`cookwareListItem${index}`} title={originalText}>
        <input type="checkbox" id={`cookware-checkbox${index}`} />
        <label htmlFor={`cookware-checkbox${index}`}>
          <span id={`${type}/${id}`} className={`${type}-target`}>
            <span style={{ color: "#333" }}>{quantityStr || ""} </span>
            <span style={{ color: "#666" }}>
              <a
                style={{ textDecoration: "underline" }}
                href={`../../${type}/${id}`}
                onClick={() =>
                  addInteraction("link", { type: `${type}section`, id })
                }
              >
                {nameStr}
              </a>
            </span>
            <span style={{ color: "#999" }}>{notesStr}</span>
          </span>
        </label>
      </li>
    );
  }
  function getApplianceHtml({
    applianceItem,
    index,
    updateApplianceMode,
    addInteraction,
  }) {
    console.log({ msg: "getApplianceHtml", applianceItem });

    const {
      _id,
      originalText,
      name,
      id,
      type,
      notes,
      size,
      appliance,
      requiredModes,
      quantity,
    } = applianceItem;
    const quantityStr = quantity && quantity > 1 ? quantity : undefined;
    const prefixStr =
      quantityStr && size ? size : size ? capitalizeWord(size) : undefined;
    const nameStr = prefixStr ? appliance.name : capitalizeWord(appliance.name);
    const modesStr = requiredModes.join(", ");
    const notesStr = notes ? ` (${notes})` : "";

    let cookOptionsHtml;
    if (applianceItem.name === "Air-Fryer") {
      cookOptionsHtml = (
        <>
          <span
            className="detail-box"
            style={{ marginLeft: "44px", fontSize: "12pt", padding: "10px" }}
            onClick={() => updateApplianceMode("air-fry")}
          >
            <FontAwesomeIcon
              style={{ height: "20px", marginLeft: "4px", marginRight: "12px" }}
              icon={faLightbulb}
            />
            <span>Revert to regular Baking mode</span>
            <FontAwesomeIcon
              style={{
                height: "16px",
                marginLeft: "12px",
                marginRight: "8px",
                paddingTop: "4px",
                color: "#333",
              }}
              icon={faArrowRight}
            />
          </span>
        </>
      );
    } else {
      cookOptionsHtml = (
        <>
          <span
            className="detail-box"
            style={{ marginLeft: "44px", fontSize: "12pt", padding: "10px" }}
            onClick={() => updateApplianceMode("air-fry")}
          >
            <FontAwesomeIcon
              style={{ height: "20px", marginLeft: "4px", marginRight: "12px" }}
              icon={faLightbulb}
            />
            <span>
              Try <b>Air-Fry</b> mode in this recipe to save 10 minutes.
            </span>
            <FontAwesomeIcon
              style={{
                height: "16px",
                marginLeft: "12px",
                marginRight: "8px",
                paddingTop: "4px",
                color: "#333",
              }}
              icon={faArrowRight}
            />
          </span>
          <span
            className="detail-box"
            style={{
              marginLeft: "44px",
              fontSize: "12pt",
              padding: "10px",
              marginTop: "12px",
            }}
          >
            <FontAwesomeIcon
              style={{ height: "20px", marginLeft: "4px", marginRight: "12px" }}
              icon={faLightbulb}
            />
            <span>See other suggestions</span>
            <FontAwesomeIcon
              style={{
                height: "16px",
                marginLeft: "12px",
                marginRight: "8px",
                paddingTop: "4px",
                color: "#333",
              }}
              icon={faArrowRight}
            />
          </span>
        </>
      );
    }

    return (
      <li key={`cookwareListItem${index}`} title={originalText}>
        <span className="label-icon">
          <span className="appliance-icon-container">
            <img
              className="appliance-icon"
              src={`/brands/${appliance.brand.toLowerCase()}/appliances/${appliance.model.toLowerCase()}.png`}
              alt={`${appliance.model}`}
            />
          </span>
          <span id={`${type}/${id}`} className={`${type}-target`}>
            <span style={{ color: "#333" }}>{quantityStr || ""} </span>
            <span style={{ color: "#666" }}>
              {prefixStr || ""}{" "}
              <a
                style={{ textDecoration: "underline" }}
                href={`../../${type}/${id}`}
                onClick={() =>
                  addInteraction("link", { type: `${type}section`, id })
                }
              >
                {nameStr}
              </a>
              <span>
                &nbsp;&nbsp;•&nbsp;&nbsp;Using{" "}
                {applianceItem.name === "Air-Fryer" ? "Air-Fryer" : "Baking"}{" "}
                Mode
              </span>
            </span>
            <span style={{ color: "#999" }}>{notesStr}</span>
          </span>
        </span>
        <div style={{ marginTop: "12px" }}>{cookOptionsHtml}</div>
      </li>
    );
  }

  const getApplianceList = (cookwareAndAppliances, addInteraction) => {
    if (!cookwareAndAppliances || cookwareAndAppliances.length === 0)
      return <></>;
    const appliances = cookwareAndAppliances.filter(
      (item) => item.type === "appliance"
    );
    if (!appliances || appliances.length === 0) return <></>;
    return (
      <div className="listSection">
        <a
          href="#appliances"
          onClick={() => addInteraction("link", { type: `appliancesection` })}
        >
          <h1 id="appliances" className="text-[14pt] lg:text-[24pt] text-[#0e4621] mt-3">Appliances</h1>
        </a>
        <ul>
          {appliances.map((a) => {
            if (a.appliance) return getApplianceHtml(a);
            return getCookwareHtml(a);
          })}
        </ul>
      </div>
    );
  };

  function getCookwareHtml(cookwareItem, index) {
    const { _id, originalText, name, id, type, notes, size, quantity } =
      cookwareItem;
    const quantityStr = quantity && quantity > 1 ? quantity : undefined;
    const prefixStr =
      quantityStr && size ? size : size ? capitalizeWord(size) : undefined;
    const nameStr = prefixStr ? name : capitalizeWord(name);
    const notesStr = notes ? ` (${notes})` : "";

    return (
      <li key={`cookwareListItem${index}`} title={originalText}>
        <input type="checkbox" id={`cookware-checkbox${index}`} />
        <label htmlFor={`cookware-checkbox${index}`}>
          <span id={`${type}/${id}`} className={`${type}-target`}>
            <span style={{ color: "#333" }}>{quantityStr || ""} </span>
            <span style={{ color: "#666" }}>
              <a
                style={{ textDecoration: "underline" }}
                href={`../../${type}/${id}`}
                onClick={() =>
                  addInteraction("link", { type: `${type}section`, id })
                }
              >
                {nameStr}
              </a>
            </span>
            <span style={{ color: "#999" }}>{notesStr}</span>
          </span>
        </label>
      </li>
    );
  }

  function getStepHtml(s, showTags) {
    const getStyleItemStyle = (itemType, showTags) => {
      return `stepItem-${showTags ? "bubble" : "link"} ${itemType}`;
    };

    const regex = /\{\{(.*?)\}\}/g;
    const partsArray = s.split(regex).map((part, index, array) => {
      if (index % 2 === 0) {
        return (
          <React.Fragment key={`step-html-${index}`}>{part}</React.Fragment>
        );
      } else {
        let p = part.split(":");
        if (p[0] === "ingredient" || p[0] === "i")
          return (
            <a
              key={`link-ingredient-${p[2]}-${index}`}
              className={getStyleItemStyle("ingredient", showTags)}
              href={p[2] ? `#ingredients/${p[2]}` : null}
              onClick={() =>
                addInteraction("link", {
                  type: "steps-ingredient",
                  ingredient: p[2],
                })
              }
            >{`${p[1]}`}</a>
          );
        if (p[0] === "cookware" || p[0] === "c")
          return (
            <a
              key={`link-cookware-${p[1]}`}
              className={getStyleItemStyle("cookware", showTags)}
              href={p[1] ? `#cookware/${p[1]}` : null}
              onClick={() =>
                addInteraction("link", {
                  type: "steps-cookware",
                  cookware: p[1],
                })
              }
            >{`${p[1]}`}</a>
          );
        if (p[0] === "appliance" || p[0] === "a")
          return (
            <a
              key={`link-appliance-${p[1]}`}
              className={getStyleItemStyle("appliance", showTags)}
              href={p[1] ? `#appliance/${p[1]}` : null}
              onClick={() =>
                addInteraction("link", {
                  type: "steps-appliance",
                  appliance: p[1],
                })
              }
            >{`${p[1]}`}</a>
          );
        if (p[0] === "supply" || p[0] === "s")
          return (
            <a
              key={`link-supply-${p[1]}`}
              className={getStyleItemStyle("supply", showTags)}
              href={p[1] ? `#supply/${p[1]}` : null}
              onClick={() =>
                addInteraction("link", {
                  type: "steps-supply",
                  supply: p[1],
                })
              }
            >{`${p[1]}`}</a>
          );
      }
    });

    return partsArray;
  }
  function getApplianceControlWidgets(s) {
    if (!s.widgets || s.widgets.length === 0) return <></>;
    const applianceWidgets = s.widgets.filter(
      (widget) => widget.type === "appliance"
    );
    return applianceWidgets.map((widget, index) => {
      return (
        <ApplianceControlWidget
          recipe={personaliseData}
          data={widget.applianceControlData}
          user={this.props.user}
          tenant={this.props.tenant}
          key={`appliance-control-widget-${index}`}
        />
      );
    });
  }

  function CreateStepsSection({ steps } = steps, showTags) {
    const stepsSectionHeader = (
      <>
        <div className="consumerrecipecard-main-divider"></div>
        <a
          href="#directions"
          onClick={() => addInteraction("link", { type: "directions-section" })}
        >
          <h1 id="directions" className="text-[14pt] lg:text-[24pt] text-[#0e4621]">Directions</h1>
        </a>
      </>
    );

    const stepsHtml = steps?.map((curStep, index) => {
      const itemizedSteps = convertParagraphToSentenceArray(
        curStep.text
      );
      const itemizedStepsHtml = itemizedSteps.map((s, index) => {
        const stepHtml = getStepHtml(s, showTags);
        return (
          <p
            key={`itemized-step-${index}`}
            title={curStep.text}
            className="consumerrecipecard-step-text flex text-[10pt] lg:text-lg xl:text-xl"
          >
            <FontAwesomeIcon className="icon self-start mt-2 h-[7px] lg:h-[8px] text-[#DAA520]" icon={faCircle} />
            <span>{stepHtml}</span>
          </p>
        );
      });

      // let durationHtml = <></>;
      // if (curStep.duration && curStep.duration > 0) {
      //   if (curStep.duration < 120) curStep.duration = 120; // min duration (TODO: Move this to personalization/rendering API code)
      //   durationHtml = (
      //     <>
      //       <span
      //         style={{
      //           paddingLeft: "12px",
      //           paddingRight: "12px",
      //           color: "#999",
      //         }}
      //       >
      //         •
      //       </span>
      //       <span style={{ color: "#666" }}>
      //         {getPrettyDuration(curStep.duration)}
      //       </span>
      //     </>
      //   );
      // }

      const applianceWidgetHtml = getApplianceControlWidgets(curStep);

      return (
        <React.Fragment key={`steps-${index}`}>
          {index !== 0 ? (
            <div className="consumerrecipecard-step-divider"></div>
          ) : (
            <></>
          )}
          <div
            className={
              index === 0
                ? "consumerrecipecard-steps-section-step-first"
                : "consumerrecipecard-steps-section-step"
            }
          >
            <h2 className="text-[12pt] lg:text-xl xl:text-2xl mb-2">
              <span id={`step${curStep.number}`}>
                <a
                  href={`#step${curStep.number}`}
                  onClick={() =>
                    addInteraction("link", {
                      type: "step",
                      curStep: curStep.number,
                    })
                  }
                >
                  Step {curStep.number}
                </a>
              </span>
              <span
                style={{
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  color: "#999",
                }}
              >
                •
              </span>
              <span style={{ color: "#111" }}>
                {curStep.summary ? curStep.summary : ""}
              </span>
              {/* {durationHtml} */}
            </h2>
            {itemizedStepsHtml}
            {applianceWidgetHtml}
          </div>
        </React.Fragment>
      );
    });

    return (
      <div
        key="consumerrecipecard-steps-section"
        className="consumerrecipecard-steps-section lg:px-8"
      >
        {stepsSectionHeader}
        {stepsHtml}
      </div>
    );
  }

  const RequiredItemsSection = ({
    ingredients,
    cookware,
    addInteraction,
    getCookwareList,
    getApplianceList,
  }) => {
    const ingredientsHtml = (
      <div className="listSection">
        <a
          href="#ingredients"
          onClick={() =>
            addInteraction("link", { type: "ingredients-section" })
          }
        >
          <h1 id="ingredients" className="text-[14pt] lg:text-[24pt] text-[#0e4621] mt-3">Ingredients</h1>
        </a>
        <ul>{getIngredientList(ingredients)}</ul>
      </div>
    );

    const cookwareHtml = getCookwareList(cookware);
    const applianceHtml = getApplianceList(cookware);

    return (
      <div key={`required-items-section`} className="consumerrecipecard-card lg:px-8">
        <div className="required-items-container flex items-start">
          <div className="required-items-content">
            {ingredientsHtml}
            {applianceHtml}
            {cookwareHtml}
          </div>
          <div className="required-items-image w-[100%] md:pl-2 hidden lg:block">
            <img src={personaliseData?.hero_image_src} alt="Primary Recipe" />
          </div>
        </div>
      </div>
    );
  };

  const { contentUrl = '//' + personaliseData?.source, socialUrls = undefined } = contentMetadata;

  const placeholderImageUrl = "/empty-image.png";
  //
  const heroImageUrl = (personaliseData?.hero_image_src) ? personaliseData?.hero_image_src : placeholderImageUrl;
  const primaryVideoUrl = socialUrls && socialUrls[0] ? socialUrls[0] : undefined;

  const toggleChat = () => {
    setShowChat(!showChat);
  }
  function getVideoContainer(primaryVideoUrl) {
    let content;
    if (primaryVideoUrl) {
      // && this.isYouTubeURL(primaryVideoUrl)) {
      const youtubeParams = new URLSearchParams(
        new URL(primaryVideoUrl).search
      );
      const videoID = youtubeParams.get("v");
      primaryVideoUrl = `https://www.youtube.com/embed/${videoID}`;
      content = (
        <iframe
          width="560"
          height="315"
          title="Recipe Video"
          src={primaryVideoUrl}
          frameborder="0"
          allowfullscreen
        ></iframe>
      );
    } else {
      content = <></>;
    }

    return <div className="video-container">{content}</div>;
  }

  return (
    <div className="flex md:justify-center lg:mt-[1rem]">
      <div className="lg:max-w-[65%] consumerrecipecard lg:bg-[#fcfcfc] lg:shadow-lg lg:border lg:border-gray-300">
        <div className="lg:hidden">
          <div className="consumerrecipecard-title break-words text-xl text-[#0e4621] font-bold">
            {personaliseData?.title}
          </div>
        </div>
        {/*createRecipeHeader */}
        <div className="hidden lg:block">
          <div
            key="consumerrecipecard-header"
            className="p-4 md:p-8 consumerrecipecard-header w-full h-80 lg:h-auto justify-end"
            style={headerStyleNoVideo}
          >
            {getVideoContainer(primaryVideoUrl)}

            <div className="consumerrecipecard-info">
              <div className="hidden lg:block">
                <div className="consumerrecipecard-title break-words text-[34px] lg:text-[44px] text-[#333] font-medium">
                  {personaliseData?.title}
                </div>
                <p className="consumerrecipecard-tagline mb-[24px] lg:text-[16pt]">
                  {"Personalized recipe based on " + personaliseData?.title + ""}
                </p>
              </div>
              {/* <div>{tagsContainer}</div> */}
              <div
                style={{
                  paddingTop: "2px",
                  paddingBottom: "24px",
                  paddingRight: "28px",
                  display: "inline-flex",
                  alignItems: "center",
                  fontSize: "16pt",
                }}
              >
                {/* <img src='/user-logo-zoe.png' alt='User Profile Pic' style={{ height: '40px', paddingRight: 10 }} /> */}
                {/* {(author && author.name) ? author.name : 'Missing Creator Name'} {socialDivider} {socialIcons} */}
                <span className="hidden">
                  {updatedAtStr}
                  {durationStr}{" "}
                  {
                    <>
                      {config.DEMO_MODE ? (
                        <FontAwesomeIcon
                          className="social-icon"
                          icon={faYoutube}
                        />
                      ) : (
                        <></>
                      )}
                      {config.DEMO_MODE ? (
                        <FontAwesomeIcon
                          className="social-icon"
                          icon={faTiktok}
                        />
                      ) : (
                        <></>
                      )}
                      {config.DEMO_MODE ? (
                        <FontAwesomeIcon
                          className="social-icon"
                          icon={faInstagram}
                        />
                      ) : (
                        <></>
                      )}

                      <a
                        target="_blank"
                        href={url}
                        onClick={() =>
                          addInteraction("link", {
                            type: "social",
                            // contentUrl,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          className="social-icon"
                          icon={faEarth}
                        />
                      </a>
                    </>
                  }
                </span>
              </div>
              <div>
                <div className="consumerrecipecard-tags flex-wrap mb-0 lg:mb-[32px] gap-1">
                  {personaliseData?.tags !== undefined &&
                    personaliseData?.tags?.length > 0 ? (
                    personaliseData?.tags?.map((tag, index) => (
                      <div
                        key={index}
                        className="tag tag-outline-grey tag-grey tag-medium"
                        onClick={async () => {
                          console.log('clicked')
                          window.open(`/consumer/recipes?tags=${tag}`);
                        }}
                      >
                        {tag}
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {/*<div style={{ paddingTop: '24px', paddingBottom: '24px', paddingRight: '28px', display: 'inline-flex', alignItems: 'center' }}><FontAwesomeIcon className="consumerrecipecard-creator-image" icon={faLink} size='1x' /><Link to={contentUrl}>{contentUrl}</Link></div>*/}
            </div>

            <div className="hidden md:flex w-full">
              <div className="flex flex-wrap xl:flex-nowrap items-center justify-center w-full mx-auto  bg-opacity-60 bg-white border border-gray-300 rounded-lg overflow-hidden my-2">
                <div title={timeTip} className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[13%] xl:w-[30%] p-2 sm:p-0 lg:flex flex-col items-center">
                  <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center xl:w-32">Total Time</h4>
                  <p className="text-lg sm:text-xl lg:text-2xl text-center">{totalTime}</p>
                </div>
                <div className="hidden sm:block border-r border-gray-300 h-16 sm:h-24 lg:h-32 mx-2 sm:mx-4 lg:mx-6"></div>
                <div className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[13%] xl:w-[30%] p-2 sm:p-0 lg:flex flex-col items-center">
                  <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center">Ingredients</h4>
                  <p className="text-lg sm:text-xl lg:text-2xl text-center">{personaliseData?.ingredients_list?.length}</p>
                </div>
                <div className="hidden sm:block border-r border-gray-300 h-16 sm:h-24 lg:h-32 mx-2 sm:mx-4 lg:mx-6"></div>
                <div className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[10%] xl:w-[30%] p-2 sm:p-0 lg:flex flex-col items-center">
                  <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center">Servings</h4>
                  <p className="text-lg sm:text-xl lg:text-2xl text-center">{personaliseData?.servings_count ? personaliseData?.servings_count : "-"}</p>
                </div>
                <div className="hidden sm:block border-r border-gray-300 h-16 sm:h-24 lg:h-32 mx-2 sm:mx-4 lg:mx-6"></div>
                <div className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[13%] xl:w-[30%] p-2 sm:p-0 lg:flex flex-col items-center">
                  <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center">Cuisine</h4>
                  <p className="text-lg sm:text-xl lg:text-2xl text-center">{personaliseData?.cuisine}</p>
                </div>
                <div className="hidden sm:block border-r border-gray-300 h-16 sm:h-24 lg:h-32 mx-2 sm:mx-4 lg:mx-6"></div>
                <div className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[13%] xl:w-[30%] p-2 sm:p-0 lg:flex flex-col items-center">
                  <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center xl:w-40">Est. Cal / Serving</h4>
                  <p className="text-lg sm:text-xl lg:text-2xl text-center">{personaliseData?.calories}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:hidden">
          <div
            key="consumerrecipecard-header"
            className="p-4 md:p-8 consumerrecipecard-header h-80 lg:h-auto justify-end"
            style={headerStyleNoVideoMobile}
          >
            {getVideoContainer(primaryVideoUrl)}

            <div className="consumerrecipecard-info">
              <div className="hidden lg:block">
                <div className="consumerrecipecard-title break-words text-[34px] lg:text-[44px] text-[#333] font-medium">
                  {personaliseData?.title}
                </div>
                <p className="consumerrecipecard-tagline mb-[24px] lg:text-[16pt]">
                  {"Personalized recipe based on " + personaliseData?.title + ""}
                </p>
              </div>
              {/* <div>{tagsContainer}</div> */}
              <div
                style={{
                  paddingTop: "2px",
                  paddingBottom: "24px",
                  paddingRight: "28px",
                  display: "inline-flex",
                  alignItems: "center",
                  fontSize: "16pt",
                }}
              >
                {/* <img src='/user-logo-zoe.png' alt='User Profile Pic' style={{ height: '40px', paddingRight: 10 }} /> */}
                {/* {(author && author.name) ? author.name : 'Missing Creator Name'} {socialDivider} {socialIcons} */}
                <span className="hidden">
                  {updatedAtStr}
                  {durationStr}{" "}
                  {
                    <>
                      {config.DEMO_MODE ? (
                        <FontAwesomeIcon
                          className="social-icon"
                          icon={faYoutube}
                        />
                      ) : (
                        <></>
                      )}
                      {config.DEMO_MODE ? (
                        <FontAwesomeIcon
                          className="social-icon"
                          icon={faTiktok}
                        />
                      ) : (
                        <></>
                      )}
                      {config.DEMO_MODE ? (
                        <FontAwesomeIcon
                          className="social-icon"
                          icon={faInstagram}
                        />
                      ) : (
                        <></>
                      )}

                      <a
                        target="_blank"
                        href={url}
                        onClick={() =>
                          addInteraction("link", {
                            type: "social",
                            // contentUrl,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          className="social-icon"
                          icon={faEarth}
                        />
                      </a>
                    </>
                  }
                </span>
              </div>
              <div>
                <div className="consumerrecipecard-tags flex-wrap mb-0 lg:mb-[32px] gap-1">
                  {personaliseData?.tags !== undefined &&
                    personaliseData?.tags?.length > 0 ? (
                    personaliseData?.tags?.map((tag, index) => (
                      <div
                        key={index}
                        className="tag tag-outline-grey tag-grey tag-medium"
                      >
                        {tag}
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {/*<div style={{ paddingTop: '24px', paddingBottom: '24px', paddingRight: '28px', display: 'inline-flex', alignItems: 'center' }}><FontAwesomeIcon className="consumerrecipecard-creator-image" icon={faLink} size='1x' /><Link to={contentUrl}>{contentUrl}</Link></div>*/}
            </div>

            <div className="hidden md:flex w-full">
              <div className="flex flex-wrap xl:flex-nowrap items-center justify-center w-full mx-auto  bg-opacity-60 bg-white border border-gray-300 rounded-lg overflow-hidden my-2">
                <div title={timeTip} className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[13%] xl:w-[30%] p-2 sm:p-0">
                  <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center xl:w-32">Total Time</h4>
                  <p className="text-lg sm:text-xl lg:text-2xl text-center">{totalTime}</p>
                </div>
                <div className="hidden sm:block border-r border-gray-300 h-16 sm:h-24 lg:h-32 mx-2 sm:mx-4 lg:mx-6"></div>
                <div className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[13%] xl:w-[30%] p-2 sm:p-0">
                  <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center">Ingredients</h4>
                  <p className="text-lg sm:text-xl lg:text-2xl text-center">{personaliseData?.ingredients_list?.length}</p>
                </div>
                <div className="hidden sm:block border-r border-gray-300 h-16 sm:h-24 lg:h-32 mx-2 sm:mx-4 lg:mx-6"></div>
                <div className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[10%] xl:w-[30%] p-2 sm:p-0">
                  <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center">Servings</h4>
                  <p className="text-lg sm:text-xl lg:text-2xl text-center">{personaliseData?.servings_count ? personaliseData?.servings_count : "-"}</p>
                </div>
                <div className="hidden sm:block border-r border-gray-300 h-16 sm:h-24 lg:h-32 mx-2 sm:mx-4 lg:mx-6"></div>
                <div className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[13%] xl:w-[30%] p-2 sm:p-0">
                  <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center">Cuisine</h4>
                  <p className="text-lg sm:text-xl lg:text-2xl text-center">{personaliseData?.cuisine}</p>
                </div>
                <div className="hidden sm:block border-r border-gray-300 h-16 sm:h-24 lg:h-32 mx-2 sm:mx-4 lg:mx-6"></div>
                <div className="text-center sm:text-left w-full sm:w-1/2 md:w-[15%] lg:w-[13%] xl:w-[30%] p-2 sm:p-0">
                  <h4 className="text-[#0e4621] uppercase text-xs sm:text-sm lg:text-base text-center xl:w-40">Est. Cal / Serving</h4>
                  <p className="text-lg sm:text-xl lg:text-2xl text-center">{personaliseData?.calories}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='md:hidden'>
          <p className="consumerrecipecard-tagline lg:mb-[24px] lg:text-[16pt]">
            {"Personalized recipe based on " + personaliseData?.title + ""}
          </p>
          <div className='flex flex-col bg-white'>
            <div className="grid grid-cols-3 mx-auto bg-opacity-60 gap-2 overflow-hidden mt-2 w-full">
              <div title={timeTip} className="flex flex-col p-2 border rounded-xl border-[#DAA520]">
                <h4 className="text-[grey] uppercase text-[11px] italic">Total Time</h4>
                <p className="text-lg font-bold text-[#0e4621]">{totalTime}</p>
              </div>
              <div className="flex flex-col p-2 border rounded-xl border-[#DAA520]">
                <h4 className="text-[grey] uppercase text-[11px] italic">Ingredients</h4>
                <p className="text-lg font-bold text-[#0e4621]">{personaliseData?.ingredients_list?.length}</p>
              </div>
              <div className="flex flex-col p-2 border rounded-xl border-[#DAA520]">
                <h4 className="text-[grey] uppercase text-[11px]  italic">Servings</h4>
                <p className="text-lg font-bold text-[#0e4621]">{personaliseData?.servings_count ? personaliseData?.servings_count : "-"}</p>
              </div>
            </div>
            <div className='grid grid-cols-2 mx-auto bg-opacity-60 gap-2 overflow-hidden mt-2 w-full'>
              <div className="flex flex-col p-2 border rounded-xl border-[#DAA520]">
                <h4 className="text-[grey] uppercase text-[11px]  italic">Cuisine</h4>
                <p className="text-lg font-bold text-[#0e4621]">{personaliseData?.cuisine}</p>
              </div>
              <div className="flex flex-col p-2 border rounded-xl border-[#DAA520]">
                <h4 className="text-[grey] uppercase text-[11px]  italic">Est. Cal / Serving</h4>
                <p className="text-lg font-bold text-[#0e4621]">{personaliseData?.calories}</p>
              </div>
            </div>
          </div>
        </div>
        {/*createPersonalizationRow */}
        <div
          key={`personalization-options-row`}
          className="consumerrecipecard-options px-8 hidden lg:block"
        >
          <div className="h-[69px] flex items-center">
            <p>
              <img
                src="/icons/ladle-magic-icon-2-white-512.png"
                alt="ladle-ai"
                className="ladle-magic-icon"
              />
              Adjust with Ladle AI
            </p>
          </div>
          {/* <div
              style={{
                borderLeft: "1px solid #fff",
                height: "100%",
                width: "1px",
              }}
            >
            </div> */}
        </div>
        {/*createDescriptionSection */}
        <div key={`description-section`} className="consumerrecipecard-card mt-4 lg:px-8">
          {personaliseData?.description ? (
            <>
              <a
                href="#description"
                onClick={() =>
                  addInteraction("link", { type: "description-section" })
                }
              >
                <div className="consumerrecipecard-description">
                <p className="text-[10pt] lg:text-sm xl:text-sm text-gray-600 mb-3">
                We are making our best estimation for ingredient substitution. However, we are not medical professionals and cannot guarantee any substitutions will be safe for your consumption. Please consult your medical professional for allergy guidance.
                </p>
              </div>

                <h1 id="description" className="text-[14pt] lg:text-[24pt] text-[#0e4621]">
                  {personaliseData?.description
                    ? `Description`
                    : `Missing Description`}
                </h1>
              </a>
              
              
              <div className="consumerrecipecard-description">
                <p className="text-[10pt] lg:text-lg xl:text-xl">
                  {personaliseData?.description || "Missing a description"}
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {/*createRequiredItemsSection */}
        <RequiredItemsSection
          ingredients={personaliseData?.ingredients_list}
          getCookwareList={() =>
            getCookwareList(personaliseData?.equipments_list)
          }
          getApplianceList={() =>
            getApplianceList(personaliseData?.equipments_list)
          }
        />
        <CreateStepsSection steps={personaliseData?.steps_list} />
        <Dialog.Root>
                            <Dialog.Trigger asChild>
                              <div className="flex justify-center">
                                <button className="bg-[#0e4621] hover:bg-[#0e4621] text-white font-bold py-2 px-4 rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50">
                                    Submit a Feedback
                                </button>
                                </div>
                            </Dialog.Trigger>
                            <Dialog.Portal>
                                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
                                <Dialog.Content
                                    className="fixed inset-0 z-50 flex items-center justify-center p-4"
                                    style={{ overflowY: "auto" }}
                                >
                                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto relative">
                                        <Dialog.Title className="text-2xl font-bold mb-4 text-center text-gray-700">
                                            We'd Love Your Feedback!
                                        </Dialog.Title>
                                        <Dialog.Description asChild>
                                            <form
                                                action="#"
                                                method="POST"
                                                className="space-y-4"
                                                onSubmit={async (e) => {
                                                    e.preventDefault();
                                                    try {
                                                        // Call the addFeedback method and pass the necessary parameters
                                                        const added = await SmartRecipeService.addFeedback(
                                                            personaliseData.recipe_id,
                                                            feedback
                                                        );

                                                        // Show success toast
                                                        toast("Your feedback has been recorded! 😀", {
                                                            position: "top-right",
                                                        });

                                                        document.getElementById('close-btn-desk').click();
                                                    } catch (error) {
                                                        console.error("Error submitting feedback:", error);
                                                        toast.error("Failed to submit feedback. Please try again.");
                                                    }
                                                }}
                                            >
                                                <div>
                                                    <label
                                                        htmlFor="feedback"
                                                        className="block text-sm font-medium text-gray-600"
                                                    >
                                                        Feedback
                                                    </label>
                                                    <textarea
                                                        id="feedback"
                                                        name="feedback"
                                                        rows="4"
                                                        onChange={(e) => setFeedback(e.target.value)}
                                                        placeholder="Your Feedback"
                                                        required={true}
                                                        className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                    ></textarea>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <button
                                                        type="submit"
                                                        className="bg-[#0e4621] hover:bg-[#0e4621] text-white font-bold py-2 px-4 rounded-full shadow-lg transition-transform transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </Dialog.Description>
                                        <Dialog.Close asChild>
                                            <button
                                                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                                                aria-label="Close"
                                                id='close-btn-desk'
                                            >
                                                ✕
                                            </button>
                                        </Dialog.Close>
                                    </div>
                                </Dialog.Content>
                            </Dialog.Portal>
                        </Dialog.Root>
      </div>
      <div
        className="lg:max-w-[25%] rounded-t-2xl fixed lg:relative bottom-0 left-0 z-[999] lg:z-[0] hidden lg:block"
        style={{
          position: "sticky",
          top: "0%",
        }}
      >
       
        {personaliseData && (
          <ConsumerPersonaliseChat
            recipe={personaliseData}
            updateNewReceipe={() => {
              setStateRecipe({
                set: personaliseData,
                isLoading: true,
              });
              //   setStateRecipe({
              //     animateName: animatevalue,
              //   });
            }}
            setLoading={(isTrue = false) => { }}
          />
        )}
      </div>
      <div className="rounded-t-2xl fixed lg:relative bottom-0 left-0 z-10 lg:z-[0] lg:hidden" style={{
        // zIndex: "1000" 
      }}>
        {showChat &&
          <>
            <ConsumerPersonaliseChat
              recipe={personaliseData}
              updateNewReceipe={() => {
                setStateRecipe({
                  set: personaliseData,
                  isLoading: true,
                });
                //   setStateRecipe({
                //     animateName: animatevalue,
                //   });
              }}
              setLoading={(isTrue = false) => { }}
              onClose={toggleChat}
            />
          </>
        }
      </div>
      <button className="fixed bottom-4 right-4 bg-[#0e4621] text-white rounded-full p-3 shadow-lg lg:hidden" onClick={toggleChat}>
        <img src="/icons/ladle-magic-icon-2-white-512.png" alt="ladle-ai" className="w-7" />
      </button>
    </div>
  );
};
export default ConsumerpersonalizedRecipeCard;
