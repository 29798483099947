import React, { Component } from 'react';
import { Link } from "react-router-dom";

import '../../css/account.css';
import UserService from '../../services/user';
import { Snackbar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import config from "../../utils/config"

class RegisterUser extends Component {

    redirectTimer;
    ACCOUNT_LOGIN_REDIRECT_DELAY = 2000;

    constructor(props) {
        super(props);

        document.title = `Ladle - Create Account`;
        const urlParams = new URLSearchParams(window.location.search);
        const inviteCode = urlParams.get('invite-code') ? urlParams.get('invite-code').trim() : undefined;

        this.state = {
            email: undefined,
            password: undefined,
            userName: undefined,
            registrationError: undefined,
            accountCreated: undefined,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this.redirectTimer);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value,
            registrationError: undefined,
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        let { email, password, userName } = this.state;

     

        const userObj = { email, password, name: userName };

    

        try {
            const userRes = await UserService.create(userObj);
            console.log({ msg: 'handleSubmit > userRes: ', userRes });

            if (userRes.user) {
                console.log('Account created successfully!', userRes.user);
                // using fetch call /api/contest post reqiest and send userres.user.email as user_id and creator as chris

                if(sessionStorage.getItem("from_chris_source") == "true"){
                    const contestObj = {
                        user_email: userRes.user.email,
                        creator_name: "chris"
                    }
                    const contestRes = await fetch(`${config.API_SERVER_URL}/api/contest`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(contestObj),
                    });
                    const contestData = await contestRes.json();

                    console.log('Contest created successfully!', contestData);
                }
            
                this.setState({ accountCreated: true, user: userRes.user, registrationError: undefined });
                this.redirectTimer = setTimeout(this.redirectOnAccountCreation, this.ACCOUNT_LOGIN_REDIRECT_DELAY);
            } else if (userRes.error) {
                this.setState({ accountCreated: false, user: undefined, registrationError: userRes.error });
            } else {
                this.setState({ accountCreated: false, user: undefined, registrationError: 'Failed to create account' });
            }
        } catch (error) {
            this.setState({ accountCreated: false, user: undefined, registrationError: 'An error occurred while creating the account' });
        }
    }

    redirectOnAccountCreation = () => {
        console.group({ msg: 'redirectOnAccountCreation', props: this.props })
        this.props.navigate('/');
    }

    render() {
        const { registrationError, accountCreated } = this.state;
        const registrationErrorHtml = registrationError ? <div className="account-error">{registrationError}</div> : <></>;
        const accountCreatedHtml = accountCreated ? <div className="account-success">Account Created!<br /><span className='account-success-redirecting'>Redirecting to Login.</span></div> : <></>;

        return (
            <>
                <div className="account-app register">
                    <div className="account-app-background"></div>
                    <section className="section-content">
                        <div className="account-tile register">
                            <div className="account-tile-header">
                                <img className="logo" src="/ladle_logo_highres.png" alt="Ladle Logo" />
                                <span className="instruction-text text-center">Please fill in the details below to create an account</span>
                            </div>

                            {registrationErrorHtml}
                            {accountCreatedHtml}

                            <form onSubmit={this.handleSubmit}>
                              
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        name="userName"
                                        required
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        required
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        required
                                        onChange={this.handleInputChange}
                                    />
                                </div>

                                <button type="submit" className='green'>
                                    Create
                                    <FontAwesomeIcon icon={faPaperPlane} style={{ marginLeft: '8px' }} />
                                </button>
                                <div className="help-text text-center">
                                    Need help or want to learn more? <Link to="mailto:info@ladle.cooking">Send us a note</Link>
                                </div>
                            </form>
                        </div>
                    </section>
                    <footer>
                        Copyright 2024 Ladle Cooking, Inc.
                    </footer>
                </div>

                <Snackbar
                    open={accountCreated}
                    autoHideDuration={2000}
                    message='Redirecting to login...'
                />
            </>
        );
    }
}

export default RegisterUser;