import React, { Component } from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//
import '../css/components/MenuDropdown.css';
import { Link } from 'react-router-dom';

//

class MenuDropdown extends Component {

    constructor () {
        
        super();
        
        this.state = {
            isDropdownVisible: false,
        };

        this.dropdownRef = React.createRef();

    }

    //

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    //

    handleClickOutside = (event) => {
        if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
            this.setState({ isDropdownVisible: false });
        }
    };

    handleLinkClick = () => {
        this.setState({isDropdownVisible:!this.state.isDropdownVisible});
        this.props.onClick(this.props.id);
    };

    handleOptionClick = (selectedOption) => {
        this.setState({isDropdownVisible:false});
        this.props.handleOptionClick(this.props.id, selectedOption.id);
    };

    //

    render () {

        const { id, items, children } = this.props;

        // create list
        const list = items.map((curObj, index) => {
            return (
                <React.Fragment key={`${children}-${curObj.id}-${index}`}>
                    <li onClick={() => this.handleOptionClick(curObj)}>{curObj.label}</li>
                </React.Fragment>
            );
        });

        const dropdownVisible = this.state.isDropdownVisible ? 'visible' : undefined;
        const dropdownClass = dropdownVisible ? 'menu-dropdown enabled' : 'menu-dropdown';
        const buttonText = children;

        return (
            <div className={dropdownClass} ref={this.dropdownRef}>
                <span className='menu-dropdown-link' onClick={this.handleLinkClick}>{buttonText}</span>
                <ul className={`${dropdownVisible} overflow-y-scroll`}>{list}</ul>
            </div>
        );

    }

}

export default MenuDropdown;