import React, { Component, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle, faPaperPlane, faLink, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { faYoutube, faInstagram, faTwitch, faPinterest, faTiktok, faFacebook } from '@fortawesome/free-brands-svg-icons';
import LoadingIcons from 'react-loading-icons'

import logger from "../../utils/log.js";

//

import RecipeService from '../../services/recipe.js';
import ParseRecipeRequestService from '../../services/parsereciperequest.js';
import ContentMetadataService from '../../services/contentmetadata.js';

//
import '../../css/addrecipe.css';

//
const log = logger("pages.creator.addrecipe");

//
class CreatorAddRecipePage extends Component {

  timer;

  constructor(props) {

    super(props);

    document.title = `Ladle - Add Recipe`;

    this.state = {
      isLoading: false,
      addedRecipe: undefined,
      addedContentMetadata: undefined,
      errorAdding: false,
      scraperUrlTextFieldDisabled: false,
      scraperContentTextAreaDisabled: false,
      socialFields: [''],
      socialFieldValues: [''],
    };

    this.recipePrimaryUrlTextField = React.createRef();
    this.recipeScraperUrlTextField = React.createRef();
    this.recipeScraperContentTextArea = React.createRef();

  }

  componentDidMount() {
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  //

  handlRecipeUrlContentChange = (index, value) => {
    
    console.log('handlRecipeUrlContentChange');
    
    //const scrapeUrlText = this.recipeScraperUrlTextField.current.value;
    const scrapeUrlText = this.recipePrimaryUrlTextField.current.value;
    const scrapeContentText = this.recipeScraperContentTextArea.current.value;

    this.setState({  scraperUrlTextFieldDisabled: !!scrapeContentText, scraperContentTextAreaDisabled: !!scrapeUrlText });

  };

  handleAddSocialField = (event) => {
    event.preventDefault();
    console.log('handleAddSocialField');
    this.setState((prevState) => ({
      socialFields: [...prevState.socialFields, ''],
    }));
  };

  handleSocialRemoveField = (index) => {
    console.log('handleSocialRemoveField ' + index);
    const { socialFields } = this.state;
    const newSocialFields = [...socialFields];
    newSocialFields.splice(index, 1);
    this.setState({ socialFields: newSocialFields });
  };

  handleSocialFieldChange = (index, value) => {
    console.log('handleSocialFieldChange ' + index + ' ' + value);
    const { socialFieldValues } = this.state;
    socialFieldValues[index] = value;
    this.setState({ socialFieldValues });
  };

  getSocialMediaIcon = (index, link) => {

    if (!link) return <FontAwesomeIcon className="input-icon" key={'icon' + index} icon={faLink} />

    const socialMediaPlatforms = {
      'youtube.com': faYoutube,
      'instagram.com': faInstagram,
      'twitch.tv': faTwitch,
      'twitch.com': faTwitch,
      'pinterest.com': faPinterest,
      'tiktok.com': faTiktok,
      'facebook.com': faFacebook,
    };

    for (const platform in socialMediaPlatforms) {
      if (link.includes(platform)) {
        const icon = socialMediaPlatforms[platform];
        return <FontAwesomeIcon className="input-icon" key={index} icon={icon} />;
      }
    }

    return <FontAwesomeIcon className="input-icon" key={'icon' + index} icon={faLink} />;

  };

  //

  parseResponse = async (response) => {

    let recipe;
    if (response.result == "created" || response.result == "updated") {
      recipe = await RecipeService.get(response.recipe, {}, this.props.user);

      let { recipeUrl, socialFieldValues } = this.state;

      const contentMetadataPayload = {
        type: 'recipe',
        content_id: recipe._id,
        contentUrl : recipeUrl,
        socialUrls : socialFieldValues,
      };

      const contentMetadata = await ContentMetadataService.create(contentMetadataPayload, this.props.user);
      if (!contentMetadata) {
        this.setState({ isLoading: false, errorAdding: true, addedRecipe: undefined , addedContentMetadata: undefined });
      } else {
        this.setState({ isLoading: false, errorAdding: false, addedRecipe: recipe, addedContentMetadata: contentMetadata });
      }

    } else if (response.result == "error") {
      this.setState({ isLoading: false, errorAdding: true, addedRecipe: undefined, addedContentMetadata: undefined  });
    }
  }

  checkRequest = async (id) => {
    const response = await ParseRecipeRequestService.get(id, this.props.user);
    if (response.completed) {
      this.parseResponse(response);
      clearTimeout(this.timer);
    } else {
      this.timer = setTimeout(() => this.checkRequest(response._id), 2000);
    }
  }

  handleSubmit = async (event) => {

    event.preventDefault();

    //const scrapeUrl = this.recipeScraperUrlTextField.current.value;
    const scrapeUrl = this.recipePrimaryUrlTextField.current.value;
    const recipeUrl = this.recipePrimaryUrlTextField.current.value;

    this.setState({ addedRecipe: undefined, addedContentMetadata: undefined, errorAdding: false, isLoading: true, recipeUrl });
    const response = await ParseRecipeRequestService.create({ type: 'url', urls: [ scrapeUrl ] }, this.props.user);
    log.debug({ msg: 'handleSubmit', response });
    if (!response) {
      this.setState({ isLoading: false, errorAdding: true, addedRecipe: undefined, addedContentMetadata: undefined, recipeUrl: undefined, });
    } else if (response.completed) {
      this.parseResponse(response);
    } else {
      this.checkRequest(response._id);
    }
  };

  //

  renderNewRecipe (recipe) {

    // recipe = {
    //   _id: '646d16c3fdd950029acb7a99',
    //   title: 'Healthy Baked Chicken Parmesan with Zuchinni Noodles',
    // };
    // let recipeUrl = `/creator/recipes/${recipe._id}`;
    // let heroImageUrl = 'https://www.ambitiouskitchen.com/wp-content/uploads/2016/08/BakedChickenParmesan-4.jpg';

    const recipeUrl = `/creator/recipes/${recipe._id}/view`;
    const heroImageUrl = recipe.media.heroImageUrl;

    const bannerStyle = {
      background : 'url(' + heroImageUrl + '), rgba(160, 219, 128, 0.5)',
      backgroundSize: 'cover',
    };

    return (
      <>
        <div className="content-section" style={{margin:'0px', marginBottom:'16px'}}>
          <Link to={recipeUrl}>
            <div className="tile" style={bannerStyle}>
              <div style={{ textAlign: 'center', verticalAlign: 'middle', margin: '0px', alignItems: 'center', justifyContent: 'center', height: '50px', display: 'flex', flexDirection: 'row' }}>
                <div><h3>Recipe Added</h3>{recipe.displayName}</div>
              </div>
            </div>
          </Link>
        </div>
      </>
    );

  }

  renderErrorAddingRecipe () {

    return (
      <>
        <div className="content-section" style={{margin:'0px', marginBottom:'16px'}}>
          <div className="tile" style={{borderColor: '#dc9797', backgroundColor: '#f5e3e3' }}>
            <div style={{ textAlign: 'center', verticalAlign: 'middle', margin: '0px', alignItems: 'center', justifyContent: 'center', height: '50px', display: 'flex', flexDirection: 'row' }}>
              <h4><FontAwesomeIcon icon={faTriangleExclamation} style={{ marginRight: '12px' }} />There was a problem adding your recipe. Please try again.</h4>
            </div>
          </div>
        </div>
      </>
    );

  }

  renderLoading () {

    return (
      <>

        <div className="content-title">Add Recipe</div>
        <div className="content-description">Upload your latest recipes and video content.</div>

        <div className="content-section">
          <div className="tile">
            <div style={{ textAlign: 'center', verticalAlign: 'middle', alignItems: 'center', justifyContent: 'center', height: '400px', display: 'flex', flexDirection: 'column' }}>
              <p style={{ marginBottom: '32px' }}>Adding Recipe...</p>
              <LoadingIcons.TailSpin height="48" stroke="#0e4621" fill="#0e4621" />
            </div>
          </div>
        </div>

      </>
    );

  }

  render() {

    log.debug('render');

    const { isLoading, addedRecipe, addedContentMetadata, errorAdding, socialFields, socialFieldValues, scraperUrlTextFieldDisabled, scraperContentTextAreaDisabled } = this.state;

    if (isLoading) {
      return this.renderLoading();
    }

    const socialFieldsHtml = socialFields.map((field, index) => {
      const fieldName = 'socialField' + index;
      return (
        <div className="input-container" key={index}>
          <input className="icon" key={fieldName} type="url" id={fieldName} placeholder='Social URL' onChange={(e) => this.handleSocialFieldChange(index, e.target.value)} />
          {this.getSocialMediaIcon(index, this.state.socialFieldValues[index])}
          {index !== 0 && <FontAwesomeIcon className='removeFieldIcon' key={'removeFieldIcon' + index} icon={faMinusCircle} onClick={() => this.handleSocialRemoveField(index)} />}
        </div>
      )
    });

    const newRecipeHtml = addedRecipe ? this.renderNewRecipe(addedRecipe) : <></>;
    //const newRecipeHtml = true ? this.renderNewRecipe(addedRecipe) : <></>;
    const errorAddingHtml = errorAdding ? this.renderErrorAddingRecipe() : <></>;

    return (

      <>

        <div className="content-title">Add Recipe</div>
        <div className="content-description">Upload your latest recipes and video content.</div>

        {newRecipeHtml}
        {errorAddingHtml}

        <div className="content-section">

          <div className="tile">

            <div style={{ marginBottom: '48px' }}>
              <p style={{ fontSize: '12pt', lineHeight:'32px' }}>Provide details and links to your recipes social media and instructional content below.</p>
              <p style={{ paddingTop: '12px', fontSize: '12pt' }}>Ladle will automatically parse the recipe and take care of the rest.  Click the Submit button at the bottom once you're done.</p>
            </div>

            <form style={{ marginTop: '32px' }} onSubmit={this.handleSubmit}>

              <div className="form-section">
                <h3>Recipe URL</h3>
                <p>If you have this recipe hosted on your own website, provide it here so we can link back to it</p>
                <div className="input-container">
                  <input className="icon" type="url" placeholder='Recipe URL' required ref={this.recipePrimaryUrlTextField} onChange={this.handlRecipeUrlContentChange} disabled={scraperUrlTextFieldDisabled} />
                  <FontAwesomeIcon className="input-icon" icon={faLink} />
                </div>
              </div>

              <div className="form-section">
                <h3>Social Video Links</h3>
                <p>Add any URLs associated with this recipe video on social media.  Click 'Add Another' to add multiple links.</p>
                {socialFieldsHtml}
                <button className="green" onClick={this.handleAddSocialField}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '12px' }} />Add another</button>
              </div>

              <div style={{paddingTop: 24}} className="form-section">
                <h3>Recipe Content</h3>
                {/*<p>Enter a URL of a webpage that contains your recipe</p>
                <input type="url" name="recipe-url" onChange={this.handlRecipeUrlContentChange} ref={this.recipeScraperUrlTextField}  disabled={scraperUrlTextFieldDisabled} />
                <p>Or, copy and paste your recipe as-is and we'll do the rest.  You can enter most formats including plain text, JSON, and YAML.</p>*/}
                <p>If your recipe is not available on the site above, copy & paste your recipe as-is and we'll do the rest.  You can enter most formats including  text, JSON, and YAML.</p>
                <textarea id="recipe-content" onChange={this.handlRecipeUrlContentChange} disabled={scraperContentTextAreaDisabled} className="tall" ref={this.recipeScraperContentTextArea} ></textarea>
                <p>Add additional links to media like images & videos - one per line (optional)</p>
                <textarea id="recipe-media-content" className="short"></textarea>
                <button type="submit" className="green"><FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '12px' }} />Submit</button>
              </div>

            </form>
          </div>
        </div>
      </>

    );

  }

};

export default CreatorAddRecipePage;