import React, { Component } from 'react';
import RecipeTile from './RecipeTile';

//
import '../css/RecipeGrid.css';
import clsx from 'clsx';

//

class RecipeGrid extends Component {

    constructor (props) {
        super(props);
        this.state = {
            relativePath: window.location.pathname
        }
    }

    async componentDidMount () { }
    
    render () {

        const recipes = this.props.recipes;
        console.log('all recipes', recipes)
        
        if (!recipes || recipes.length === 0) return;
        const recipeTiles = recipes.map(recipe => { return <RecipeTile  key={recipe._id} recipe={recipe} type={this.props.type} user={this.props.user} tenant={this.props.tenant} handleTagClick={this.props.handleTagClick} /> });

        const gridColumnCount = this.props.gridColumnCount || 4;
   
        return <div className={clsx(["recipe-grid lg:grid gap-4", {
            "flex overflow-x-auto": this.state.relativePath === "/consumer/recipes"
        }])} style={{gridTemplateColumns:`repeat(${gridColumnCount}, 1fr)`}}>{recipeTiles}</div>
    }

}

export default RecipeGrid;