import React, { Component } from 'react';
import { Label } from "../../components/ui/label"
import { Switch } from "../../components/ui/switch"
import { cn } from "../../lib/utils"
import { Checkbox } from "../../components/ui/checkbox"

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select"

class MyFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            servings: 1,
            diet: {
                "No Preference": true,
                "Kosher": false,
                "Vegetarian": false,
                "Vegan": false,
                "Ketogenic (Keto)": false,
                "Paleolithic (Paleo)": false,
                "Dairy Free": false,
                "Gluten Free": false,
                "Halal": false,
                "Pescatarian": false,
            },
            allergies: {
                "Milk (Lactose)": false,
                "Eggs": false,
                "Fish": false,
                "Shellfish": false,
                "Peanuts": false,
                "Tree nuts": false,
                "Sesame": false,
                "Soy": false,
                "Wheat / Gluten": false
            },
            languages: {
                "English": true,
                "Spanish": false,
                "French": false,
                "Italian": false,
                "German": false,
                "Hindi": false,
                "Mandarin": false,
                "Japanese": false
            },
            mesurement: {
                "Metric": false,
                "Imperial": false
            },
            skill: {
                "Beginner": false,
                "Intermediate": false,
                "Advanced": false

            },
            appliances: {
                "Conventional Oven": false,
                "Convection Oven": false,
                "Air Fryer": false,
                "Microwave Oven": false,
                "Toaster Oven": false,
                "Pizza Oven": false,
                "Pressure Cooker": false,
                "Slow Cooker": false,
                "Multicooker": false,
                "Sous Vide": false,
                "Food Processor": false,
                "Blender": false,
                "Immersion Blender": false,
            },
            showError: false,
            temperature: false,
            language: "english",
            units: false,
            cuisine: "american",
            loading: false

        };

        // Bind the onChange handler to this component instance
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    // Handler for input changes
    handleInputChange(event) {
        const target = event.target;
        const value = target === Checkbox ? target.checked : target.value;
        const name = target.name;

        // Check if the input is part of the diet object
        if (name.startsWith('diet.')) {
            const dietKey = name.split('.')[1];
            this.setState(prevState => ({
                diet: {
                    ...prevState.diet,
                    [dietKey]: value
                }
            }));
        } else {
            // For other inputs, update the state directly
            this.setState({
                [name]: value
            });
        }
    }
    handleStateChange(key, updatingState) {
        this.setState(prevState => {
            const updatedState = {};
            for (const dietKey in prevState[updatingState]) {
                updatedState[dietKey] = (dietKey === key && !prevState[updatingState][key]);
            }
            return { [updatingState]: updatedState };
        });
        localStorage.setItem(updatingState, key)
    }
    handleMultiStateChange(key, updatingState) {
        this.setState(prevState => {
            const updatedState = { ...prevState[updatingState] };
            updatedState[key] = !updatedState[key];
            localStorage.setItem(JSON.stringify(updatingState), JSON.stringify(updatedState))
            return { [updatingState]: updatedState };

        });
    }
    handleInputValue(e) {
        console.log(e.target.value)
        if (Number(e.target.value) <= 0) {
            this.setState({
                showError: true
            })
        } else {
            this.setState({
                showError: false
            })
            localStorage.setItem('number_of_people', e.target.value)
        }
    }

    render() {
        return (
            <div className="">
                <div className="mb-6">
                    <div className="text-2xl text-black font-bold mb-5">Personalization Preferences</div>
                    <div className='text-xl text-black font-bold'>Diet</div>
                    <div className=''>
                        {Object.entries(this.state.diet).map(([key, value]) => {
                            return <button
                                onClick={() => {
                                    this.handleStateChange(key, "diet")
                                }}
                                className={value == 1 ? 'mr-3 mt-3 px-[12px] py-[3px] bg-[#0e4621] font-bold rounded-[32px] text-white' : ' mr-3 mt-3 px-[12px] py-[3px] bg-gray-200 rounded-[32px] opacity-50 hover:opacity-100 text-gray-500 font-bold border-2 border-black-500 hover:shadow-sm hover:text-white hover:bg-[#0e4621] text-black'}
                            >{key}
                            </button>
                        })}
                    </div>
                </div>
                <div className="mb-6">
                    <div className='text-xl text-black font-bold'>Allergies and Restrictions</div>
                    <div className=''>
                        {Object.entries(this.state.allergies).map(([key, value]) => {
                            return <button
                                onClick={() => {
                                    this.handleMultiStateChange(key, "allergies")
                                }}

                                className={value == 1 ? 'mr-3 mt-3 px-[12px] py-[3px] bg-[#0e4621] font-bold rounded-[32px] text-white' : ' mr-3 mt-3 px-[12px] py-[3px] bg-gray-200 rounded-[32px] opacity-50 hover:opacity-100 text-gray-500 font-bold border-2 border-black-500 hover:shadow-sm hover:text-white hover:bg-[#0e4621] text-black'}
                            >{key}
                            </button>
                        })}
                    </div>
                </div>
                <div className="mb-6">
                    <div className='text-xl text-black font-bold'>Number of people I typically cook for</div>
                    <div className='mt-3'>
                        <input min="1" type="number" onKeyUp={(e) => this.handleInputValue(e)} onBlur={(e) => this.handleInputValue(e)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[180px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="1" required />
                    </div>

                    <>
                        {
                            this.state.showError == true ? <div className='bg-red-600 text-white p-3 mt-2'>No of people should be at least 1 or above</div> : ""
                        }
                    </>
                </div>
                <div className="mb-6">
                    <div className='text-xl text-black font-bold'>Language Preferences</div>
                    <div className=''>
                        {Object.entries(this.state.languages).map(([key, value]) => {
                            return <button
                                onClick={() => {
                                    this.handleStateChange(key, "languages")
                                }}

                                className={value == 1 ? 'mr-3 mt-3 px-[12px] py-[3px] bg-[#0e4621] font-bold rounded-[32px] text-white' : ' mr-3 mt-3 px-[12px] py-[3px] bg-gray-200 rounded-[32px] opacity-50 hover:opacity-100 text-gray-500 font-bold border-2 border-black-500 hover:shadow-sm hover:text-white hover:bg-[#0e4621] text-black'}
                            >{key}
                            </button>
                        })}
                    </div>
                </div>
                <div className="mb-6">
                    <div className='text-xl text-black font-bold'>Units of Measurement</div>
                    <div className=''>
                        {Object.entries(this.state.mesurement).map(([key, value]) => {
                            return <button
                                onClick={() => {
                                    this.handleStateChange(key, "mesurement")
                                }}

                                className={value == 1 ? 'mr-3 mt-3 px-[12px] py-[3px] bg-[#0e4621] font-bold rounded-[32px] text-white' : ' mr-3 mt-3 px-[12px] py-[3px] bg-gray-200 rounded-[32px] opacity-50 hover:opacity-100 text-gray-500 font-bold border-2 border-black-500 hover:shadow-sm hover:text-white hover:bg-[#0e4621] text-black'}
                            >{key}
                            </button>
                        })}
                    </div>
                </div>
                <div className="mb-6">
                    <div className='text-xl text-black font-bold'>Skill Level</div>
                    <div className=''>
                        {Object.entries(this.state.skill).map(([key, value]) => {
                            return <button
                                onClick={() => {
                                    this.handleStateChange(key, "skill")
                                }}

                                className={value == 1 ? 'mr-3 mt-3 px-[12px] py-[3px] bg-[#0e4621] font-bold rounded-[32px] text-white' : ' mr-3 mt-3 px-[12px] py-[3px] bg-gray-200 rounded-[32px] opacity-50 hover:opacity-100 text-gray-500 font-bold border-2 border-black-500 hover:shadow-sm hover:text-white hover:bg-[#0e4621] text-black'}
                            >{key}
                            </button>
                        })}
                    </div>
                </div>
                <div className="mb-6">
                    <div className='text-xl text-black font-bold'>Kitchen Appliances I regularly use</div>
                    <div className=''>
                        {Object.entries(this.state.appliances).map(([key, value]) => {
                            return <button
                                onClick={() => {
                                    this.handleMultiStateChange(key, "appliances")
                                }}

                                className={value == 1 ? 'mr-3 mt-3 px-[12px] py-[3px] bg-[#0e4621] font-bold rounded-[32px] text-white' : ' mr-3 mt-3 px-[12px] py-[3px] bg-gray-200 rounded-[32px] opacity-50 hover:opacity-100 text-gray-500 font-bold border-2 border-black-500 hover:shadow-sm hover:text-white hover:bg-[#0e4621] text-black'}
                            >{key}
                            </button>
                        })}
                    </div>
                </div>
                <div className="mb-4 text-center">
                    <button disabled={this.state.loading} onClick={() => {
                        this.setState({
                            loading: true
                        })
                        if (localStorage.getItem('number_of_people') != undefined && Number(localStorage.getItem('number_of_people')) <= 0) {
                            this.setState({
                                showError: true
                            })
                            alert('please fill all the fields')
                            this.setState({
                                loading: false
                            })
                        } else {
                            this.props.onSubmit()
                        }
                    }} className="green">
                        {this.state.loading ? `Loading...`
                         : "Submit"}
                    </button>
                </div>
            </div >
        );
    }
}
export default MyFormComponent;
