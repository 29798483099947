import React, { Component } from 'react';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import ConsumerRecipeCard from '../../components/ConsumerRecipeCard';
import logger from "../../utils/log.js";

//


const log = logger("pages.brand.viewrecipe");

class BrandViewRecipeConsumerPage extends Component {

  constructor (props) {

    super(props);
    
    this.state = {
      recipe_id : this.props.params.recipe_id
    };

  }

  async componentDidMount () {   
  }

  render () {

    const manageRecipeUrl = `/brand/recipes/${this.state.recipe_id}/manage`;

    return (
      <>
        <div style={{fontSize: '12pt', fontWeight: '500', padding: '10px 0px 26px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ textAlign: 'left' }}><Link className="hover green" to={manageRecipeUrl}><FontAwesomeIcon icon={faArrowCircleLeft} style={{paddingRight: '12px'}} />Manage this recipe</Link></span>
          <span style={{ textAlign: 'center'}}>Viewing recipe as consumer</span>
          <span style={{ textAlign: 'right', width: '240px'}}></span>
        </div>
        <ConsumerRecipeCard recipe_id={this.state.recipe_id} type='brand' user={this.props.user} tenant={this.props.tenant} />
      </>
    )

  }

};

export default BrandViewRecipeConsumerPage;