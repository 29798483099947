import { faLemon } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

//
import RecipeGrid from '../components/RecipeGrid';
import RecipeService from '../services/recipe';
import shuffleArray from '../utils/shuffleArray';

//
 
class NoPagePage extends Component {

  componentDidMount () {
    this.getRecipes({limit:100});
    if (window.location.href.includes('/chris-de-la-rosa')) {
      window.location.href = '/consumer/recipes/creator/chris-de-la-rosa';
    }
  }

  async getRecipes (options) {

    console.log({msg:'getRecipes', options});

    let { user } = this.props;
    let recipes;

    try {
      options.hideDetails = true;
      recipes = await RecipeService.list(options, user);
      recipes = shuffleArray(recipes).splice(0, 4);
      this.setState({ recipes });
    } catch (err) {
      console.log('getRecipes.error');
      console.log(err);
      this.setState({ recipes });
    }
  }

  handleTagClick = async (tag) => {
  }

  //


  render () {

    if (!this.state?.recipes) return;

    return (
      <div className="max-w-[1250px]" style={{textAlign:'center'}}>
        <p className="text-[21pt] lg:text-[24pt]" style={{fontWeight: '400', marginBottom:'24px'}}><FontAwesomeIcon icon={faLemon} style={{ marginRight:'4px'}} /> Page not found!</p>
        <p className="text-[17pt] lg:text-[18pt]" style={{fontWeight: '100'}}>We're not sure where you're going but checkout some recipes while you're here.</p>
        <div style={{marginTop:'36px'}}>
          <RecipeGrid recipes={this.state.recipes} type='consumer' user={this.props.user} tenant={this.props.tenant} gridColumnCount='4' handleTagClick={this.handleTagClick} />
        </div>
      </div>
    );
  }



};
  
export default NoPagePage;