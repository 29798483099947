const convertParagraphToSentenceArray = (paragraph) => {
    if (!paragraph) return [];

    // Split potential sentences at each period, question mark, or exclamation point.
    let potentialSentences = paragraph.split(/([.!?])/);

    let sentences = [];
    let currentSentence = '';

    for (let i = 0; i < potentialSentences.length; i++) {
        // Include the punctuation in the sentence
        currentSentence += potentialSentences[i];
        if (i % 2 === 1) { // This checks if the index is odd, meaning the element is a punctuation mark
            // Check if this should end a sentence
            if (i + 1 < potentialSentences.length && /^[ "\n\r\t]*[A-Z]/.test(potentialSentences[i + 1])) {
                sentences.push(currentSentence.trim());
                currentSentence = '';
            }
        }
    }

    // Add any remaining sentence part
    if (currentSentence.trim().length > 0) {
        sentences.push(currentSentence.trim());
    }

    return sentences.map(sentence => sentence.replace(/\s+/g, ' '));
};


export default convertParagraphToSentenceArray;