import { faLineChart, faGlobe, faMoneyBill, faThumbsUp, faUsers, faFile, faEye } from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';

const weeklyinsights = [
    {
        key: 'weeklyEngagement',
        title: 'Weekly Engagement Rate',
        value: '+35%',
        description: 'Percentage of your followers actively engaged with your brands content',
        icon: faThumbsUp,
    },
    {
        key: 'monthlyActive',
        title: 'Monthly Active Users',
        value: '152,410',
        description: 'Users who have engaged with a recipe in the last month',
        icon: faUsers,
    },
    {
        key: 'views',
        title: 'Views',
        value: '+42%',
        description: 'Weekly views of your creators content over the last 7 days',
        icon: faMoneyBill,
    },
    {
        key: 'newFollowers',
        title: 'New Followers',
        value: '+23%',
        description: 'New followers that you\'ve gained in the last week',
        icon: faGlobe,
    },
];

const appsrecipes = [
    {
        key: 'newRecipes',
        title: 'New Recipes',
        value: '+142',
        description: 'New recipes added this week',
        icon: faFile,
    },
    {
        key: 'newContentViews',
        title: 'New Content Views',
        value: '+17%',
        description: 'Weekly views of your recipes and content over the last 7 days',
        icon: faEye,
    },
    {
        key: 'totalRecipes',
        title: 'Total Recipes',
        value: '3,984',
        description: 'Total Recipes for your brand',
        icon: faCheckSquare,
    },
    {
        key: 'creators',
        title: 'Creators',
        value: '2,450',
        description: 'Number of creators in your campaigns',
        icon: faLineChart,
    },
];

const sponsorships = [
    {
        key: 'sponsorships',
        title: 'Total Campaign Engagement',
        value: '74%',
        description: 'Percentage of your followers who actively engage with your content and recipes',
        icon: faThumbsUp,
    },
    {
        key: 'clickthroughRate',
        title: 'Clickthrough Rate',
        value: '28%',
        description: 'Weekly views of your recipes and content over the last 7 days',
        icon: faMoneyBill,
    },
    {
        key: 'activeCampaigns',
        title: 'Active Campaigns',
        value: '6',
        description: 'Total active campaigns',
        icon: faGlobe,
    },
    {
        key: 'earningsGrowth',
        title: 'Earnings Growth',
        value: '+11%',
        description: 'The increase or change in your revenue over the seven days and more.',
        icon: faLineChart,
    },
];

const getChartData = function getChartData () {

    const currentDate = new Date();
    const startDate = new Date();
    startDate.setMonth(currentDate.getMonth() - 2);
  
    const data = [];
    let currentDatePointer = new Date(startDate);
    let weekCount = 1;
  
    let previousFollowers = 17550;
  
    while (currentDatePointer <= currentDate) {
  
      const formattedDate = currentDatePointer.toLocaleDateString();
      const followers = previousFollowers + Math.floor(Math.random() * 15000) + 2500;
      previousFollowers = followers;
  
      const engagementRate = Math.random() * (58 - 25) + 25; // Random engagement rate between 25% and 58%
  
      data.push({ date: formattedDate, Followers: followers, 'Engagement Rate': engagementRate });
  
      currentDatePointer.setDate(currentDatePointer.getDate() + 7);
      weekCount++;
    }
  
    return data;
  
  }

export {
    weeklyinsights,
    appsrecipes,
    sponsorships,
    getChartData
};