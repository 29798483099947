import React, { Component } from 'react';
import mongoose from 'mongoose';
import ManageRecipeCard from '../../components/ManageRecipeCard';
import '../../css/recipe.css';
import logger from "../../utils/log.js";

//

const log = logger("pages.creator.managerecipe");

class CreatorManageRecipePage extends Component {

  constructor (props) {

    super(props);

    this.state = {
      recipe_id : this.props.params.recipe_id
    };

  }

  componentDidMount () {   
  }

  render () {

    if (mongoose.Types.ObjectId.isValid(this.state.recipe_id)) {
      return (
        <>
          <div className="content-title">Manage Recipe</div>
          <div className="content-description"></div>
          <div className="content-section">
            <ManageRecipeCard recipe_id={this.state.recipe_id} type='creator' user={this.props.user} />
          </div>      
        </>
      )
    } else {
      return (<p>Unable to find that recipe</p>)
    }

  }

};

export default CreatorManageRecipePage;