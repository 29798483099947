import React, { Component } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import logger from "../utils/log.js";

import '../css/RecipeGrid.css';
import '../css/RecipeList.css';

//

const log = logger('components.RecipeList');

//

class RecipeList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            page: 0,
            rowsPerPage: 25,
            recipeData: this.props.recipeData,
            totalRecipesLength: this.props.totalRecipesLength,
        };
        console.log({msg:'RecipeList', props});
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
    };

    render() {

        const { page, rowsPerPage, totalRecipesLength, recipeData } = this.state;
        const { tableCells, ListRow } = this.props;
        const visibleRows = recipeData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        if (!recipeData || recipeData.length === 0) return;

        const tableCellsHtml = tableCells.map(tableCell => {
            const label = tableCell.name ? tableCell.name : <></>;
            const iconHtml = tableCell.sortIcon ? <FontAwesomeIcon color='#000' style={{marginLeft: '6px'}} icon={tableCell.sortIcon} /> : <></>
            return <TableCell key={tableCell.id} align="left"><b>{label}</b>{iconHtml}</TableCell>
        });

        return (

            <>
                <TableContainer style={{ border: '1px solid #ddd', borderRadius: 15, padding: 12, backgroundColor: '#fdfdfd' }} component={Paper}>
                    <Table sx={{ minWidth: '1250px' }} className='recipe-list-table' aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                {tableCellsHtml}
                            </TableRow>
                        </TableHead>
                        <TableBody className='recipe-list-table'>
                            {visibleRows.map((recipeData) => (<ListRow key={recipeData.recipe._id} recipe={recipeData.recipe} metrics={recipeData.metrics} appliance={this.props.appliance} handleTagClick={this.props.handleTagClick} />))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={totalRecipesLength}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />

            </>

        );

    }

}

export default RecipeList;
