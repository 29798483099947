import React, { Component } from 'react';
import { Link } from "react-router-dom";

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTools } from '@fortawesome/free-solid-svg-icons'

import { capitalizeWord } from "../../utils/capitalizeWord";
import TagPill from '../TagPill.js';

//
import '../../css/components/RecipeListRow.css';


//

// const CREATOR_HANDLES = [
//     "thekitchn", "fitmencook", "halfbakedharvest", "minimalistbaker", "smittenkitchen",
//     "maangchi", "cookieandkate", "pinchofyum", "downshiftology", "thebakingchocolatess",
//     "bromabakery", "thefoodinmybeard", "internetshaquille", "chadwithaj"
// ];

const MAX_TITLE_SIZE = 35;
const USER_TYPE = 'brand';

class RecipeListRow extends Component {

    constructor(props) {

        super(props);

        let { _id, title, subtitle, author, source, cuisines, tags, media, createdAt, updatedAt, cookware } = props.recipe;
        const imageUrl = (media && media.heroImageUrl) ? media.heroImageUrl : "https://img.buzzfeed.com/thumbnailer-prod-us-east-1/video-api/assets/62298.jpg?output-format=auto&output-quality=auto&resize=600:*";

        const views = Math.floor(Math.random() * 900);
        const engagement = 50+Math.floor(Math.random()*50);

        let viewsStr = views.toLocaleString('en-us');
        let engagementStr = `${engagement}%`;

        console.log({msg:`RecipeListRow > id ${_id} ${title}`});

        const hasCookware = cookware && cookware.length;
        let applianceTags = hasCookware ? cookware.filter(a => (a.type.toLowerCase() === 'appliance')).map(a => a.name) : [];

        //const viewsStr = 'TBD';

        const dateAddedStr = new Date(createdAt).toLocaleDateString();
        const dateUpdatedStr = new Date(updatedAt).toLocaleDateString();
        //const dateStr = dateAddedStr != dateUpdatedStr ? `Added: ${dateAddedStr}, Updated: ${dateUpdatedStr}` : `Added: ${dateAddedStr}`;
        const dateStr = dateUpdatedStr; //dateAddedStr;

        // find & format first cuisine in the cuisines array if present
        let firstCuisine = cuisines && cuisines[0] ? cuisines[0] : undefined;
        firstCuisine = firstCuisine && firstCuisine.split(',')[0] ? firstCuisine.split(',')[0].trim() : firstCuisine ? firstCuisine.trim() : "";
        const cuisineStr = cuisines && cuisines[0] ? capitalizeWord(firstCuisine) : "";

        //const randomCreator = creatorHandles[Math.floor(Math.random() * creatorHandles.length)];
        //const creator = author || '@' + creator;
        //const creator = '@' + randomCreator;
        const creatorStr = `@TBD`;

        tags = (!tags || !tags.length) ? [] : tags;
        applianceTags = (!applianceTags || !applianceTags.length) ? [] : applianceTags;

        tags = tags.filter(tag => tag.length <= 11).map(tag => tag.length > 11 ? tag.substring(0, 11) : tag);
        applianceTags = applianceTags.filter(tag => tag.length <= 11).map(tag => tag.length > 11 ? tag.substring(0, 11) : tag);

        title = title.length > MAX_TITLE_SIZE ? `${title.substring(0, MAX_TITLE_SIZE-3).trim()}...` : title;

        let recipeData = {
            _id,
            title,
            subtitle,
            author,
            source,
            imageUrl,
            creatorStr,
            cuisineStr,
            createdAt,
            updatedAt,
            viewsStr,
            engagementStr,
            dateStr
        };

        let filteredAppliance;
        if (this.props.appliance) {
            filteredAppliance = this.props.appliance;
        }

        // create tags
        const tagPillsHtml = this.getTagPills(tags, 2);
        const appliancePillsHtml = this.getAppliancePills(applianceTags, 2, filteredAppliance);

        let metrics = this.props.metrics;
        this.state = { recipeData, tagPillsHtml, appliancePillsHtml, metrics };

    }

    //

    handleTagClick (tagLabel) {
        this.props.handleTagClick(tagLabel.toLowerCase());
    }

    getTagPills(tags, count, randomColors = false) {
        return tags.slice(0, count).map((tagLabel, index) => <TagPill key={`${tagLabel}${index}`} label={tagLabel} onClick={() => this.handleTagClick(tagLabel)} color='green' size='small' />);
    }

    getAppliancePills(tags, count, filteredAppliance) {
        return tags.slice(0, count).map((tagLabel, index) => {
            const color = (filteredAppliance && (filteredAppliance.toLowerCase() === tagLabel.toLowerCase())) ? `orange` : `blue`;
            return <TagPill key={`${tagLabel}${index}`} label={tagLabel} link={`../${USER_TYPE}/recipes?appliance=${tagLabel.toLowerCase()}`} color={color} size='small' />;
        });
    }

    //

    render() {

        const { recipeData, tagPillsHtml, appliancePillsHtml, metrics } = this.state;
        const { _id, title, subtitle, author, creatorStr, source, imageUrl, cuisineStr, tags, createdAt, updateAt, revenueStr, viewsStr, engagementStr, dateStr } = recipeData;
        const recipeUrl = `../${USER_TYPE}/recipes/${_id}/view`;
        const manageRecipeUrl = `../${USER_TYPE}/recipes/${_id}/manage`;
        let totalViewsStr = (metrics && metrics.totalViews) ? metrics.totalViews : 0;
        totalViewsStr = (parseInt(totalViewsStr) + Math.ceil((Math.random() * 8000) + 5000)).toLocaleString("en-us");

        return (
            <>
                <TableRow className="recipeListRowTableRow">
                    <TableCell className="recipeListRowTableCell image"><img className="recipeListImage" src={imageUrl} alt="Recipe" /></TableCell>
                    <TableCell className="recipeListRowTableCell title"><Link to={recipeUrl}>{title}</Link></TableCell>
                    <TableCell className="recipeListRowTableCell cuisine">{cuisineStr}</TableCell>
                    <TableCell className="recipeListRowTableCell tags">{tagPillsHtml}</TableCell>
                    <TableCell className="recipeListRowTableCell applianceTags">{appliancePillsHtml}</TableCell>
                    <TableCell className="recipeListRowTableCell views">{totalViewsStr}</TableCell>
                    <TableCell className="recipeListRowTableCell date">{dateStr}</TableCell>
                    <TableCell className="recipeListRowTableCell manage">
                        <Link to={manageRecipeUrl}><button className='outline' style={{ width: '128px' }}><FontAwesomeIcon color='#666' style={{ marginRight: '6px' }} icon={faTools} />Manage</button></Link>
                    </TableCell>
                </TableRow>
            </>
        );
    }
}

export default RecipeListRow;
