import config from "../utils/config.js";
import { capitalizeWord } from '../utils/capitalizeWord';

//import fetch from 'node-fetch';
const fetch = require('node-fetch');

//

const getRecipes = async function getRecipes (id="", options={}, user) {

  console.log(`services.recipe.getRecipes: ${id}`);

  if (!user) return [];

  const queryString = new URLSearchParams(options);

  console.log(`${queryString}`);

  let url = `${config.API_SERVER_URL}/api/v1/recipes`;
  if (id) {
      url += `/${id}`;
  }
  url += `?${queryString}`;

  console.log(`services.recipe.getRecipes.url: ${url}`);

  const request = {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
  };

  try {
    const response = await fetch(url, request);
    const status = response.status;
    console.log({msg:'services.recipe.getRecipes.status', status});
    if (status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'An error occurred.');
      // return
    };
    const jsonResponse = await response.json();
    console.log({msg:'services.recipe.getRecipes.jsonResponse', jsonResponse});
    return jsonResponse || [];
  } catch (err) {
    throw err;
  }

};

const modifyRecipe = function modifyRecipe (recipe) {
  
  // set properties if missing
  recipe.cuisines = recipe.cuisines || [];
  
  // clean-up description
  if (!recipe.description) recipe.description = "";

  return recipe;

}

class RecipeService {

  static async count (options={}, user) {

    let countOpts = JSON.parse(JSON.stringify(options));

    console.log('services.recipe.count');
    try {
      countOpts.limit = 1000;
      countOpts.hideDetails = true;
      const recipes = await getRecipes(undefined, countOpts, user);
      if (recipes) return recipes.length;
      return 0;
    } catch (err) {
      console.error({msg:'services.recipe.count.err', err});
    }
  }

  static async makefavourite(id, user) {
    console.log('services.makefavourite');
    let url = `${config.API_SERVER_URL}/api/v1/recipes/${id}/favorite`;
    const request = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
    };
    try {
      let recipes = await fetch(`${url}`,request)
      recipes = await recipes.json();
      console.log("these", recipes)
      if (recipes) return recipes;
      return [];
    } catch (err) {
      console.error({msg:'services.recipe.list.err', err});
      throw err;
    }
  }

  static async list (options, user) {
    console.log('services.recipe.list');
    try {
      let recipes = getRecipes(undefined, options, user);
      if (recipes) return recipes;
      return [];
    } catch (err) {
      console.error({msg:'services.recipe.list.err', err});
      throw err;
    }
  }
  
  static async getallRecipes (options, user) {
    console.log('services.allreecipes');
    let url = `${config.API_SERVER_URL}/api/v1/recipes/get-recipes`;
    const request = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
    };
    try {
      let recipes = await fetch(`${url}`,request)
      recipes = await recipes.json();
      console.log("these", recipes)
      if (recipes) return recipes;
      return [];
    } catch (err) {
      console.error({msg:'services.recipe.list.err', err});
      throw err;
    }
  }

  static async whatsInMyKitchen(options, user) {
    console.log('services.whatsInMyKitchen');
  
    // Set the URL for the new API endpoint
    let url = `${config.API_SERVER_URL}/api/v1/recipes/search-by-ingredients`;
  
    // Prepare the request payload
    const requestBody = {
      ingredients: options.ingredients || [],  // Assuming `options.ingredients` contains the list of ingredients
      page: options.page || 1,                 // Default to page 1 if not provided
    };
  
    // Prepare the fetch request options
    const request = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify(requestBody),  // Add the request body with ingredients and page
    };
  
    try {
      // Fetch data from the new API
      let response = await fetch(url, request);
      
      // Parse the response to JSON
      let recipes = await response.json();
  
      console.log("Fetched Recipes:", recipes);
      
      // Return recipes if available, otherwise return an empty array
      if (recipes) return recipes;
      return [];
    } catch (err) {
      console.error({ msg: 'services.whatsInMyKitchen.err', err });
      throw err;
    }
  }
  
  static async getCreatorRecipes (options, user) {
    console.log('services.getCreato');
    let url = `${config.API_SERVER_URL}/api/v1/recipes/get-creator-recipes`;
    const request = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
    };
    try {
      let recipes = await fetch(`${url}`,request)
      recipes = await recipes.json();
      console.log("these", recipes)
      if (recipes) return recipes;
      return [];
    } catch (err) {
      console.error({msg:'services.recipe.list.err', err});
      throw err;
    }
  }
  static async getCreatorRecipeswithoutuser (options) {
    console.log('services.getCreato');
    let url = `${config.API_SERVER_URL}/user/get-creator-recipes`;
    const request = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        
      },
      
    };
    try {
      let recipes = await fetch(`${url}`,request)
      recipes = await recipes.json();
      console.log("these", recipes)
      if (recipes) return recipes;
      return [];
    } catch (err) {
      console.error({msg:'services.recipe.list.err', err});
      throw err;
    }
  }
  static async insertRecentlyViewed (id, user) {
    console.log('services.allreecipes');
    let url = `${config.API_SERVER_URL}/api/v1/recipes/${id}/view/recipe`;
    const request = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
    };
    try {
      let recipes = await fetch(`${url}`,request)
      recipes = await recipes.json();
      console.log("these", recipes)
      if (recipes) return recipes;
      return {};
    } catch (err) {
      console.error({msg:'services.recipe.list.err', err});
      throw err;
    }
  }
  static async get (id, options, user) {
    try {
      let recipe = await getRecipes(id, options, user);
      console.log({msg:'services.recipe.get', recipe});
      if (recipe) return modifyRecipe(recipe);
      return;
    } catch (err) {
      console.error({msg:'services.recipe.get.err', err});
    }
  }

  static async create(data, user) {
  }

  //

  static async getCachedRecipes (id, user) {
    try {
      let cachedRes = await getRecipes(id, { cached: "true" }, user);
      cachedRes = cachedRes.map(r => r.recipedata);
      return cachedRes;
    } catch (err) {
      console.error({msg:'services.recipe.get.err', err});
    }
  }

}

export default RecipeService;
