import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import '../css/components/PersonalizationDropdown.css';
import clsx from 'clsx';

class PersonalizationDropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isDropdownVisible: false,
            isDisabled: this.props.isDisabled == undefined ? false : true,
        };

        this.dropdownRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
            this.setState({ isDropdownVisible: false });
            this.props.handleDropdownVisibility(null); // Close the dropdown
        }
    };

    handleDropdownToggle = () => {
        const { isDropdownVisible } = this.state;
        this.setState({ isDropdownVisible: !isDropdownVisible });
        this.props.handleDropdownVisibility(this.props.id);
    };

    handleOptionClick = (event, selectedOption) => {
        event.stopPropagation(); // Prevent event from bubbling up
        selectedOption = selectedOption.id === 'reset' ? undefined : selectedOption;
        this.setState({ isDropdownVisible: false });
        this.props.handleDropdownVisibility(null); // Close the dropdown
        const personalizationObj = {};
        personalizationObj[this.props.personalizationId] = selectedOption;
        this.props.personalizeRequestFunc(personalizationObj);
    };

    truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }

    render() {
        const { isDropdownVisible, isDisabled } = this.state;
        const { listOptions, listSelection, listIcon, listLabel, listName } = this.props;

        // Create list
        const list = listOptions.map((curObj) => {
            const curListClass = (listSelection && (listSelection.id === curObj.id)) ? 'selected' : undefined;
            const resetDividerHtml = curObj.id === 'reset' ? (<div style={{ borderBottom: '1px solid #DDD', width: '80%', marginLeft: '10%' }}></div>) : null;
            return (
                <React.Fragment key={`${listName}-${curObj.id}`}>
                    <li className={curListClass} onClick={(e) => this.handleOptionClick(e, curObj)}>
                        {curObj.label}
                    </li>
                    {resetDividerHtml}
                </React.Fragment>
            );
        });

        const dropdownClass = listSelection || isDropdownVisible ? 'personalize-dropdown enabled' : 'personalize-dropdown';
        const buttonText = listSelection 
            ? this.truncateText(listSelection.label, 7) 
            : listLabel 
                ? this.truncateText(listLabel, 7) 
                : this.truncateText(listName, 7);

        return (
            <div className={`relative h-full ${dropdownClass} ml-2`} ref={this.dropdownRef}>
                <button 
                    className={clsx(["py-2 px-1 text-[2vw] sm:text-[10pt] mr-5 w-[100px]", {
                        "text-[#333]": isDisabled
                    }])} 
                    onClick={this.handleDropdownToggle} 
                    disabled={isDisabled}
                >
                    <FontAwesomeIcon className="mr-2" icon={listIcon} />
                    {buttonText}
                </button>
                {isDropdownVisible && (
                    <ul className="absolute w-max">
                        {list}
                    </ul>
                )}
            </div>
        );
    }

}

export default PersonalizationDropdown;
