import React, { Component } from 'react';
import logger from "../../utils/log.js";

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faSliders } from '@fortawesome/free-solid-svg-icons'
import { faYoutube, faInstagram, faPinterest, faTiktok, faFacebook, faChrome } from '@fortawesome/free-brands-svg-icons';

const iconMap = {
    instagram: faInstagram,
    youtube: faYoutube,
    pinterest: faPinterest,
    tiktok: faTiktok,
    facebook: faFacebook,
    web: faChrome,
}

//

const log = logger('components.brand.ActiveSponsorshipRow');

//

const opportunities = [
    { type: 'Shoutout', base: 500, range: 750, color: '#CD7F32'  },
    { type: 'Partial', base: 2500, range: 1250, color: '#C0C0C0' },
    { type: 'Fully Integated', base: 5000, range: 3000, color: 'gold' } ,
];

class BrandActiveSponsorshipRow extends Component {

    constructor(props) {
        
        super(props);

        const opportunity = opportunities[Math.floor(Math.random()*3)];
        const opportunityType = opportunity.type;
        const opportunityTypeColor = opportunity.color;
        const dealDelta = (Math.floor(Math.random() * opportunity.range));
        const opportunityMin = opportunity.base;
        const opportunityMax = Math.round((opportunity.base + dealDelta) / 100) * 100

        this.state = { 'open': false, 'optedin': this.props.campaign.optedin, opportunityType, opportunityTypeColor, opportunityMin, opportunityMax };
    }

    async componentDidMount() {
    }

    render() {

        const { open, optedin, opportunityType, opportunityTypeColor, opportunityMin, opportunityMax } = this.state;
        const { campaign } = this.props;
        const { 'id' : campaignId, incentives, campaign_name, campaign_description, brandData, status } = campaign;
        const { 'id' : brandId, reach } = brandData;

        const statusText = (status == 'active') ? 'Active' : (status == 'draft' ? 'Draft' : 'Complete');
        const statusColor = status == 'draft' ? '#d92121' : undefined;

        const imageUrl = '/brands/' + brandId + '.png';
        const reachMetrics = reach.map(function (metric) {
            const reachIcon = <FontAwesomeIcon style={{ paddingRight: 4 }} icon={iconMap[metric.type]} />;
            return (<><span>{reachIcon}{metric.count}</span><br /></>);
        });

        return (
            <>
                <TableRow sx={{ borderBottom: '1px solid #ccc',}}>
                    <TableCell style={{ paddingTop: '44px', paddingBottom:'44px', fontSize: '13pt', fontWeight:'600', border: 'none', width: '20%', paddingLeft: '32px' }}>{campaign_name}</TableCell>
                    <TableCell style={{ fontSize: '13pt', fontWeight:'250', border: 'none', width: '16%', }}><FontAwesomeIcon style={{ color: opportunityTypeColor, paddingRight: '8px' }} icon={faCircle} />{opportunityType}</TableCell>
                    <TableCell style={{ fontSize: '13pt', fontWeight:'250', border: 'none', width: '46%' }}>{campaign_description}</TableCell>
                    <TableCell style={{ fontSize: '13pt', fontWeight:'250', border: 'none', width: '14%', color: statusColor}}>{statusText}</TableCell>
                    <TableCell style={{ fontSize: '13pt', fontWeight:'600', border: 'none', width: '14%', }}>${opportunityMin} - ${opportunityMax}</TableCell>
                    <TableCell style={{ fontSize: '13pt', fontWeight:'250', border: 'none', width: '4%' }}>
                        <button className='white' style={{ width: 128 }}><FontAwesomeIcon style={{ color: 'grey', paddingRight: '8px' }} icon={faSliders} />Manage</button>
                    </TableCell>
                </TableRow>


            </>
        );
    }
}

export default BrandActiveSponsorshipRow;
