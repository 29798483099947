import config from "../utils/config.js";

//import fetch from 'node-fetch';
const fetch = require('node-fetch');

//

async function getUserAppliances (id="", options={}, user) {

  console.log(`services.userappliance.getUserAppliances: ${id}`);

  if (!user) return [];

  const queryString = new URLSearchParams(options);

  console.log(`${queryString}`);

  let url = `${config.API_SERVER_URL}/api/v1/userappliances`;
  if (id) {
      url += `/${id}`;
  }
  url += `?${queryString}`;

  console.log(`services.userappliance.getUserAppliances.url: ${url}`);

  const request = {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
  };

  try {
    const response = await fetch(url, request);
    const status = response.status;
    console.log({msg:'services.userappliance.getUserAppliances.status', status});
    if (status !== 200) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'An error occurred.');
      // return
    };
    const jsonResponse = await response.json();
    console.log({msg:'services.userappliance.getUserAppliances.jsonResponse', jsonResponse});
    return jsonResponse || [];
  } catch (err) {
    throw err;
  }

};

async function createUserAppliance (data, user) {
  
  console.log('services.userappliance.createUserAppliance', data);

  if (!user) return;

  const serverUrl = `${config.API_SERVER_URL}/api/v1/userappliances`;
  console.log(`services.userappliance.createUserAppliance.url: ${serverUrl}`);

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(serverUrl, request);
    const jsonResponse = await response.json();
    const responseCode = response.status;
    console.log({msg:'services.userappliance.createUserAppliance.jsonResponse', responseCode, jsonResponse});

    if (response.status !== 201) return;
    return jsonResponse;

  } catch (err) {
    throw err;
  }

};

async function updateUserAppliance (id, data, user) {
  
  console.log('services.userappliance.updateUserAppliance', id, data, user);

  if (!user) return;

  const serverUrl = `${config.API_SERVER_URL}/api/v1/userappliances/${id}`;
  console.log(`services.userappliance.updateUserAppliance.url: ${serverUrl}`);

  const request = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(serverUrl, request);
    const jsonResponse = await response.json();
    const responseCode = response.status;
    console.log({msg:'services.userappliance.updateUserAppliance', responseCode, jsonResponse});

    if (responseCode !== 200) {
      console.log('responseCode not 200: ' + responseCode);
      return;
    }

    return jsonResponse;

  } catch (err) {
    throw err;
  }

};

async function removeUserAppliance (id, user) {
  
  console.log('services.userappliance.removeUserAppliance', id, user);

  if (!user) return;

  const serverUrl = `${config.API_SERVER_URL}/api/v1/userappliances/${id}`;
  console.log(`services.userappliance.removeUserAppliance.url: ${serverUrl}`);

  const request = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
  };

  try {
    const response = await fetch(serverUrl, request);
    const responseCode = response.status;
    console.log({msg:'services.userappliance.removeUserAppliance', responseCode});
    return (responseCode === 204)

  } catch (err) {
    throw err;
  }

};

//

class UserApplianceService {

  static async count (options={}, user) {
    console.log('services.userappliance.count');
    try {
      const userAppliances = await getUserAppliances(undefined, options, user);
      if (userAppliances) return userAppliances.length;
      return 0;
    } catch (err) {
      console.error({msg:'services.userappliance.count.err', err});
    }
  }

  static async list (options, user) {
    console.log('services.userappliance.list');
    try {
      let userAppliances = getUserAppliances(undefined, options, user);
      if (userAppliances) return userAppliances;
      return [];
    } catch (err) {
      console.error({msg:'services.userappliance.list.err', err});
      throw err;
    }
  }

  static async get (id, options, user) {
    try {
      const userAppliance = await getUserAppliances(id, options, user);
      console.error({msg:'services.userappliance.get', userAppliance});
      return userAppliance;
    } catch (err) {
      console.error({msg:'services.userappliance.get.err', err});
    }
  }

  static async update(id, data, user) {
    try {
      console.log({ msg: 'services.userappliance.update', data });
      const response = await updateUserAppliance(id, data, user);
      console.log({ msg: 'services.userappliance.update', response });
      return response;
    } catch (err) {
      console.error({ msg: 'services.userappliance.update > error', err });
      throw err;
    }
  }

  static async create(data, user) {
    try {
      console.log({ msg: 'services.userappliance.create', data });
      const response = await createUserAppliance(data, user);
      console.log({ msg: 'services.userappliance.create', response });
      return response;
    } catch (err) {
      console.error({ msg: 'services.userappliance.create > error', err });
      throw err;
    }
  }

  static async remove(id, user) {
    try {
      console.log({ msg: 'services.userappliance.remove', id });
      const response = await removeUserAppliance(id, user);
      console.log({ msg: 'services.userappliance.remove', response });
      return response;
    } catch (err) {
      console.error({ msg: 'services.userappliance.remove > error', err });
      throw err;
    }
  }

}

export default UserApplianceService;
