import config from "../utils/config.js";
import logger from "../utils/log.js";

//

const log = logger("services.parsereciperequest");

//

const getParseRecipeRequest = async function getParseRecipeRequest (id, queryParams={}, user) {

  let url = `${config.API_SERVER_URL}/api/v1/parsereciperequests/`;

  if (!user) return;

  if (id) url += `${id}`;
  if (Object.keys(queryParams).length > 0) {
    const queryString = new URLSearchParams(queryParams).toString();
    url += `?${queryString}`;
  } 

  const request = {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
  };

  try {
    const response = await fetch(url, request);
    const jsonResponse = await response.json();
    console.log({msg:'services.parsereciperequest.getParseRecipeRequest.jsonResponse', jsonResponse});
    return jsonResponse;
  } catch (err) {
    throw err;
  }

};

const createParseRecipeRequest = async function createParseRecipeRequest(data, user) {

  console.log('createParseRecipeRequest', data);

  if (!user) return;

  const serverUrl = `${config.API_SERVER_URL}/api/v1/parsereciperequests`;
  console.log(`services.parsereciperequest.createParseRecipeRequest.url: ${serverUrl}`);

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
    body: JSON.stringify(data)
  };

  console.log('services.parsereciperequest.createParseRecipeRequest', serverUrl, request);

  try {

    const response = await fetch(serverUrl, request);
    const jsonResponse = await response.json();
    const responseCode = response.status;
    console.log({ msg: 'services.parsereciperequest.createParseRecipeRequest.jsonResponse', responseCode, jsonResponse });

    if (response.status !== 200 && response.status !== 201 && response.status !== 202) return;
    return jsonResponse;

  } catch (err) {
    throw err;
  }

};

class ParseRecipeRequestService {

  static async get (id, user) {
    try {
      return await getParseRecipeRequest(id, {}, user);
    } catch (err) {
      console.error({msg:'services.parsereciperequest.get.err', err});
    }
  }

  static async getLastSuccessfulRequest (recipeId, user) {
    //console.log('services.parsereciperequest.getLastSuccessfulRequest', recipeId);
    const queryParams = {
      recipe: recipeId,
      completed: true,
      result: 'created,updated',
      limit: 1
    };
    const resultArr = await this.list(null, queryParams, user);
    return resultArr[0];
  }

  static async list (id, queryParams, user) {
    //console.log('services.parsereciperequest.list', id, queryParams);
    try {
      return await getParseRecipeRequest(null, queryParams, user);
    } catch (err) {
      console.error({msg:'services.parsereciperequest.list.err', err});
    }
  }

  static async create (data, user) {
    try {
      log.debug({ msg: 'services.parsereciperequest.create', data });
      let responses = await createParseRecipeRequest(data, user);
      log.debug({ msg: 'services.parsereciperequest.create', responses });
      return responses;
    } catch (err) {
      log.error({ msg: 'services.parsereciperequest.create > error', err });
      throw err;
    }
  }

}

export default ParseRecipeRequestService;
