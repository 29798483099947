import React, { Component } from 'react';
import logger from "../../utils/log.js";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faBriefcase, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import { faYoutube, faInstagram, faTwitch, faPinterest, faTiktok, faFacebook, faChrome } from '@fortawesome/free-brands-svg-icons';
import { faStar as farStar, faHeart as farHeart, faHandshake as farHandshake } from '@fortawesome/free-regular-svg-icons'

const iconMap = {
    instagram: faInstagram,
    youtube: faYoutube,
    pinterest: faPinterest,
    tiktok: faTiktok,
    facebook: faFacebook,
    web: faChrome,
}

//

const log = logger('components.creator.MarketplaceRow');

class CreatorMarketplaceRow extends Component {

    constructor(props) {
        super(props);
        this.state = { 'open': false, 'optedin': this.props.row.optedin };
    }

    async componentDidMount() {
    }

    render() {

        const { open, optedin } = this.state;
        const { row } = this.props;
        let { id, name, rating, reach, description, types, demographics, values, collaborations, competitors, socialresponsibility, ppc=0.15} = row;

        ppc = ((Math.random() * 0.15) +.15).toFixed(2);

        const imageUrl = '/brands/' + id + '.png';
        const reachMetrics = reach.map(function (metric) {
            const reachIcon = <FontAwesomeIcon style={{ paddingRight: 4 }} icon={iconMap[metric.type]} />;
            return (<><span>{reachIcon}{metric.count}</span><br /></>);
        });

        const ratingHtml = (rating == 3) ? (<><FontAwesomeIcon style={{ color: 'gold' }} icon={faStar} /><FontAwesomeIcon style={{ color: 'gold' }} icon={faStar} /><FontAwesomeIcon style={{ color: 'gold' }} icon={faStar} /></>) : rating == 2 ? <><FontAwesomeIcon style={{ color: 'darkgrey' }} icon={faStar} /><FontAwesomeIcon style={{ color: 'darkgrey' }} icon={faStar} /><FontAwesomeIcon style={{ color: 'lightgrey' }} icon={farStar} /></> : <><FontAwesomeIcon style={{ color: '#b08d57' }} icon={faStar} /><FontAwesomeIcon style={{ color: 'lightgrey' }} icon={farStar} /><FontAwesomeIcon style={{ color: 'lightgrey' }} icon={farStar} /></>;

        return (
            <>
                <TableRow>
                    <TableCell style={{ border: 'none', height: 128, paddingBottom: 32, paddingTop: 32 }}><img style={{ maxWidth: 80 }} src={imageUrl} /></TableCell>
                    <TableCell style={{ border: 'none' }} >{description}</TableCell>
                    <TableCell style={{ border: 'none' }}>{types.join(', ')}</TableCell>
                    <TableCell style={{ border: 'none' }}>{demographics}</TableCell>
                    <TableCell style={{ border: 'none' }}>${ppc}</TableCell>
                    <TableCell style={{ border: 'none' }}>{reachMetrics}</TableCell>
                    <TableCell style={{ border: 'none' }}>
                        <button className={optedin ? 'outline selected' : 'outline'} style={{ width: 128 }} onClick={() => this.setState({ optedin: !optedin })}>{optedin ? 'Opt Out' : 'Opt In'} </button>
                    </TableCell>
                    <TableCell style={{ border: 'none' }}>
                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ open: !open })} >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>

                <TableRow sx={{ marginBottom:32 }}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 0, marginBottom: 4, border: '1px solid #ddd', 'borderRadius': 2, backgroundColor: '#fcfcfc' }}>
                                <Table size="small">
                                    <Box sx={{ margin: 2 }}>
                                        <TableHead>
                                            <TableRow style={{ border: 'none' }}>
                                                <TableCell style={{ border: 'none', width: 324 }}><FontAwesomeIcon icon={farHeart} /><b> Values</b></TableCell>
                                                <TableCell style={{ border: 'none', width: 324 }}><FontAwesomeIcon icon={farHandshake} /><b> Prior Collaborations</b></TableCell>
                                                <TableCell style={{ border: 'none', width: 324 }}><FontAwesomeIcon icon={faGlobeAmericas} /><b> Social Responsibility</b></TableCell>
                                                <TableCell style={{ border: 'none', width: 324 }}><FontAwesomeIcon icon={faBriefcase} /><b> Competitors</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow style={{ border: 'none' }} key={id}>
                                                <TableCell style={{ border: 'none' }}>{values}</TableCell>
                                                <TableCell style={{ border: 'none' }}>{collaborations}</TableCell>
                                                <TableCell style={{ border: 'none' }}>{socialresponsibility}</TableCell>
                                                <TableCell style={{ border: 'none' }}>{competitors}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Box>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>

            </>
        );
    }
}

export default CreatorMarketplaceRow;
