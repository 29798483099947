import React, { Component } from 'react';

//
import '../css/components/SortDropdown.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown91, faArrowDownAZ, faArrowDownShortWide, faArrowUp19, faFire, faShuffle, } from '@fortawesome/free-solid-svg-icons';

//

const DEFAULT_OPTIONS = {
    random : { id: 'random', label: 'Random', icon: faShuffle },
    alphabetical : { id:'alphabetical', label: 'Alphabetical', icon: faArrowDownAZ },
    newest : { id:'newest', label: 'Most Recent', icon: faArrowUp19 },
    oldest : { id:'oldest', label: 'Least Recent', icon: faArrowDown91 },
    //popular : { id:'popular',  label: 'Most Popular', icon: faFire },
};

class SortDropdown extends Component {

    constructor () {
        
        super();
        
        this.state = {
            isDropdownVisible: false,
            sortOptions: this.props?.items ? this.props?.items : DEFAULT_OPTIONS,
            selectedOption: 'random',
        };

        this.dropdownRef = React.createRef();

    }

    //

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    //

    handleMenuClick = () => {
        this.setState({isDropdownVisible:!this.state.isDropdownVisible});
    };

    handleClickOutside = (event) => {
        if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
            this.setState({ isDropdownVisible: false });
        }
    };

    handleOptionClick = (selectedOption) => {
        this.setState({isDropdownVisible:false, selectedOption:selectedOption.id});
        this.props.handleOptionClick(selectedOption);
    };

    //

    render () {

        const { id, children } = this.props;
        const { sortOptions, selectedOption, isDropdownVisible } = this.state;

        // create list
        const list = Object.keys(sortOptions).map((key, index) => {
            let curObj = sortOptions[key];
            return (
                <React.Fragment key={`${children}-${curObj.id}-${index}`}>
                    <li onClick={() => this.handleOptionClick(curObj)}><FontAwesomeIcon className='sort-list-icon' icon={curObj.icon} />{curObj.label}</li>
                </React.Fragment>
            );
        });

        const dropdownClass = isDropdownVisible ? 'visible' : '';

        return (
            <div className='sort-dropdown' ref={this.dropdownRef}>
                <span className='sort-dropdown-label'>Sort by..</span>
                <span className='sort-dropdown-button' onClick={this.handleMenuClick}><FontAwesomeIcon className='sort-dropdown-icon' icon={sortOptions[selectedOption].icon} />{sortOptions[selectedOption].label}</span>
                <ul className={dropdownClass}>{list}</ul>
            </div>
        );

    }

}

export default SortDropdown;