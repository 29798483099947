import React, { Component } from 'react';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons';

import BrandActiveSponsorshipRow from '../../components/brand/ActiveSponsorshipRow';
import BrandInactiveSponsorshipRow from '../../components/brand/InactiveSponsorshipRow';
import BrandData from '../../data/brandData';
import SponsorshipData from '../../data/sponsorshipData';

import config from "../../utils/config.js";
import logger from "../../utils/log.js";

import '../../css/sponsorships.css';
import '../../css/home.css';


//

const log = logger("pages.brand.sponsorships");

//
class BrandSponsorshipPage extends Component {

  constructor(props) {

    super(props);

    document.title = `Ladle - Sponsorships`;

    const currentBrandId = localStorage.getItem('currentBrandId') ||  config.DEFAULT_BRAND_ID;

    const campaigns = SponsorshipData[currentBrandId];
    const activeCampaigns = campaigns ? campaigns.filter(campaign => campaign.status !== 'complete') : [];
    const inactiveCampaigns = campaigns ? campaigns.filter(campaign => campaign.status === 'complete') : [];
    const brandData = BrandData[currentBrandId];

    this.state = {
      isLoading: true,
      activeCampaigns,
      inactiveCampaigns,
      brandData
    };

  }

  async componentDidMount () {   
}

  getCampaignTable = (campaigns, brandData, headerColor, tableColor, isActive) => {

    const brandId = brandData ? brandData.id : 'bosch';
    const opacity = isActive ? 1 : 0.65;
    const Row = isActive ? BrandActiveSponsorshipRow : BrandInactiveSponsorshipRow;
    const tableKey = `${brandId}${isActive}`;

    const campaignHtml = campaigns.map(campaign => {
      campaign.brandData = brandData;
      return (<Row key={campaign.id} campaign={campaign} />)
    });

    return (
      <TableContainer key={tableKey} sx={{ opacity, backgroundColor: tableColor, marginBottom: '72px', borderRadius: '12px', boxShadow: '0px 0.5px 3px 0.2px rgba(255,215,0,0.4)' }} component={Paper}>
        <Table sx={{ minWidth: '1040px' }} aria-label="collapsible table">
          <TableHead sx={{ backgroundColor: headerColor }}>
            <TableRow>
              <TableCell style={{ paddingTop: '10px', paddingBottom: '10px', border: 'none', width: '20%', paddingLeft: '32px', fontSize: '8pt', fontWeight: 500, color: '#333' }}>CAMPAIGN</TableCell>
              <TableCell style={{ paddingTop: '10px', paddingBottom: '10px', border: 'none', width: '16%', fontSize: '8pt', fontWeight: 500, color: '#333' }}>TYPE</TableCell>
              <TableCell style={{ paddingTop: '10px', paddingBottom: '10px', border: 'none', width: '46%', fontSize: '8pt', fontWeight: 500, color: '#333' }}>DESCRIPTION</TableCell>
              <TableCell style={{ paddingTop: '10px', paddingBottom: '10px', border: 'none', width: '14%', fontSize: '8pt', fontWeight: 500, color: '#333' }}>STATUS</TableCell>
              <TableCell style={{ paddingTop: '10px', paddingBottom: '10px', border: 'none', width: '14%', fontSize: '8pt', fontWeight: 500, color: '#333' }}>COMPENSATION</TableCell>
              <TableCell style={{ paddingTop: '10px', paddingBottom: '10px', border: 'none', width: '4%', fontSize: '8pt', fontWeight: 500, color: '#333' }}></TableCell>
            </TableRow>
          </TableHead>
          {campaignHtml}
        </Table>
      </TableContainer>
    );

  }

  //


  render() {

    log.debug('render');

    const { activeCampaigns, inactiveCampaigns, brandData } = this.state;

    const activeHeaderColor = `rgb(244, 236, 190)`;
    const previousHeaderColor = `rgb(231, 230, 218)`;
    const activeTableColor = `rgb(250, 250, 250)`;
    const previousTableColor = `rgb(250, 250, 250)`;

    return (

      <>

        <div className="content-title">Sponsorships</div>
        <div className="content-description">Find, create, and manage campaigns with influential creators.</div>

        <div className="content-section">
          <div className="tile">

            <div style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <h3 style={{fontWeight: '600', textAlign: 'left'}}>Active Campaigns</h3>
                <button className="outline create-campaign"><FontAwesomeIcon icon={faPen} style={{ marginRight: '12px' }} />New Campaign</button>
            </div>
            { this.getCampaignTable(activeCampaigns, brandData, activeHeaderColor, activeTableColor, true) }

            <div style={{paddingBottom: '20px'}}><h3 style={{fontWeight: '600'}}>Previous Campaigns</h3></div>
            { this.getCampaignTable(inactiveCampaigns, brandData, previousHeaderColor, previousTableColor, false) }

          </div>
        </div>
      </>

    );

  }

};

export default BrandSponsorshipPage;