const brandData = {
    allclad: {
        id: 'allclad',
        name: 'All-Clad',
        handle: 'allclad',
        description: 'High-end cookware and kitchen appliances.',
        values: 'Quality craftsmanship, innovation, professional-grade.',
        types: ['Cookware'],
        tags: [],
        demographics: 'Cooking enthusiasts, home chefs, professional chefs.',
        reach: [{ type: 'youtube', count: '300k' }, { type: 'tiktok', count: '200k' }, { type: 'web', count: '100k' }],
        incentives: 'Free product samples, brand ambassador program.',
        requirements: '2 social media posts featuring recipes using All-Clad products.',
        collaborations: 'Collaborated with top chefs and food bloggers, cooking demonstrations.',
        socialresponsibility: 'Supports culinary education programs.',
        competitors: 'Calphalon, Le Creuset',
        campaign_description: 'Create delicious recipes with our brand\'s innovative kitchen appliances.',
        campaign_type: 'Product Launch',
        url: 'https://www.all-clad.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    kitchenaid: {
        id: 'kitchenaid',
        name: 'KitchenAid',
        handle: 'kitchenaid',
        description: 'Kitchen appliances and tools known for their durability.',
        types: ['Cookware', 'Appliances'],
        values: 'Innovation, versatility, empowering creativity.',
        tags: [],
        demographics: 'Home cooks, baking enthusiasts, culinary professionals.',
        reach: [{ type: 'youtube', count: '100k' }, { type: 'tiktok', count: '500k' }, { type: 'web', count: '350k' }],
        incentives: 'Product sponsorship, discounts for followers.',
        requirements: '1 YouTube video featuring a KitchenAid recipe tutorial.',
        collaborations: 'Collaborated with popular food YouTubers, recipe development.',
        socialresponsibility: 'Supports hunger relief organizations.',
        competitors: 'Cuisinart, Breville.',
        campaign_description: "Showcase your culinary skills using our premium cookware collection.",
        campaign_type: 'Seasonal',
        url: 'https://www.kitchenaid.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    oxo: {
        id: 'oxo',
        name: 'OXO',
        handle: 'oxo',
        description: 'Kitchen tools and gadgets designed for practicality and comfort.',
        values: 'Functional design, accessibility, quality.',
        types: ['Cookware'],
        tags: [],
        demographics: 'Home cooks, individuals with limited hand mobility. Facebook: 150k followers, Website: 80k monthly visitors.',
        reach: [{ type: 'youtube', count: '750k' }, { type: 'tiktok', count: '1M' }, { type: 'web', count: '200k' }],
        incentives: 'Product testing, influencer discounts.',
        requirements: '3 social media posts highlighting OXO tools in action.',
        collaborations: 'Collaborated with recipe developers, kitchen organization experts.',
        socialresponsibility: 'Supports initiatives promoting healthy eating and food accessibility.',
        competitors: 'Joseph Joseph, Chef',
        campaign_description: "Join our kitchen makeover challenge and transform your cooking space.",
        campaign_type: 'Social Cause',
        url: 'https://www.oxo.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    instantpot: {
        id: 'instantpot',
        name: 'Instant Pot',
        handle: 'instantpot',
        description: 'Multi-functional electric pressure cookers and kitchen appliances.',
        types: ['Appliances'],
        values: 'Efficiency, convenience, time-saving.',
        tags: [],
        demographics: 'Busy families, meal preppers, cooking enthusiasts.',
        reach: [{ type: 'youtube', count: '500k' }, { type: 'tiktok', count: '300k' }, { type: 'web', count: '400k' }],
        incentives: 'Sponsored content, product giveaways.',
        requirements: '1 recipe video showcasing a dish prepared in an Instant Pot.',
        collaborations: 'Collaborated with food bloggers, meal planning influencers.',
        socialresponsibility: 'Actively supports food waste reduction initiatives. Competitors: Ninja Foodi, Crock-Pot.',
        competitors: '',
        campaign_description: "Become a brand ambassador for our eco-friendly kitchen utensils.",
        campaign_type: 'Brand Awareness',
        url: 'https://instantpot.com/',
        optedin: false,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    lecreuset: {
        id: 'lecreuset',
        name: 'Le Creuset',
        handle: 'lecreuset',
        description: 'Premium cookware known for its iconic French design and durability.',
        types: ['Cookware'],
        values: 'Quality, craftsmanship, timeless design.',
        tags: [],
        demographics: 'Home cooks, professional chefs, individuals valuing high-quality cookware.',
        reach: [{ type: 'youtube', count: '750k' }, { type: 'tiktok', count: '1M' }, { type: 'web', count: '200k' }],
        incentives: 'Product samples, brand ambassador program.',
        requirements: '1 social media post featuring Le Creuset cookware.',
        collaborations: 'Collaborated with Michelin-starred chefs, culinary schools, food festivals.',
        socialresponsibility: 'Committed to sustainable manufacturing practices and reducing carbon footprint.',
        competitors: 'Staub, All-Clad.',
        campaign_description: "Share your favorite kitchen hacks using our time-saving gadgets.",
        campaign_type: 'Brand Awareness',
        url: 'https://www.lecreuset.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    calphalon: {
        id: 'calphalon',
        name: 'Calphalon',
        handle: 'calphalon',
        description: 'Kitchenware known for its durable and versatile non-stick cookware.',
        types: ['Cookware'],
        values: 'Reliability, versatility, durability.',
        tags: [],
        demographics: 'Home cooks, culinary enthusiasts, individuals seeking high-quality kitchen products.',
        reach: [{ type: 'youtube', count: '50k' }, { type: 'tiktok', count: '200k' }, { type: 'web', count: '50k' }],
        incentives: 'Product samples, sponsored content.',
        requirements: '1 blog post featuring Calphalon cookware and recipes.',
        collaborations: 'Collaborated with cooking influencers, recipe contests.',
        socialresponsibility: 'Committed to responsible sourcing and reducing environmental impact.',
        competitors: 'All-Clad, T-fal.',
        campaign_description: "Explore international cuisines with our authentic spice collection partnership.",
        campaign_type: 'Retargeting',
        url: 'https://www.calphalon.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    williamssonoma: {
        id: 'williamssonoma',
        name: 'Williams Sonoma',
        handle: 'williamssonoma',
        description: 'Upscale retailer offering high-quality cookware, kitchen appliances, and gourmet food.',
        types: ['Cookware'],
        values: 'Quality, craftsmanship, exceptional culinary experience.',
        demographics: 'Home cooks, culinary enthusiasts, individuals seeking premium kitchen products.',
        reach: [{ type: 'youtube', count: '1M' }, { type: 'tiktok', count: '2M' }, { type: 'web', count: '500k' }],
        incentives: 'Product discounts, exclusive events.',
        requirements: '2 blog posts featuring Williams Sonoma products and recipes.',
        collaborations: 'Collaborated with renowned chefs, cookbook authors, cooking shows.',
        socialresponsibility: 'Supports local food banks and sustainable agriculture initiatives.',
        competitors: 'Sur La Table, Crate and Barrel.',
        campaign_description: "Collaborate with us to promote healthy eating habits and recipes.",
        campaign_type: 'Social Cause',
        url: 'https://www.williams-sonoma.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    samsung: {
        id: 'samsung',
        name: 'Samsung',
        handle: 'samsung',
        description: 'Leading manufacturer of home appliances including refrigerators, ovens, washers, and more.',
        types: ['Appliances'],
        values: 'Innovation, advanced technology, stylish design.',
        demographics: 'Homeowners, tech-savvy individuals, those seeking modern and connected appliances.',
        reach: [{ type: 'youtube', count: '2M' }, { type: 'tiktok', count: '3M' }, { type: 'web', count: '1M' }],
        incentives: 'Product discounts, early access to new releases.',
        requirements: '1 social media post featuring a Samsung appliance in a home setting.',
        collaborations: 'Collaborated with interior designers, home improvement influencers.',
        socialresponsibility: 'Committed to eco-friendly manufacturing and energy efficiency.',
        competitors: 'LG, Whirlpool',
        campaign_description: "Join our baking challenge and inspire others with your creations.",
        campaign_type: 'Giveaway',
        url: 'https://www.samsung.com/',
        optedin: false,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    unbranded: {
        id: 'unbranded',
        name: 'Kitchen Brand',
        handle: 'yourkitchenbrand',
        description: 'Manufacturer of cookware, home appliances, and kitchen gear.',
        types: ['Appliances'],
        values: 'Innovation, cutting-edge technology, sleek design.',
        demographics: 'Tech enthusiasts, homeowners, individuals seeking smart and connected appliances.',
        reach: [{ type: 'youtube', count: '1.5M' }, { type: 'tiktok', count: '2M' }, { type: 'web', count: '800k' }],
        incentives: 'Product samples, brand ambassador program.',
        requirements: '2 social media posts showcasing our appliances in a modern home.',
        collaborations: 'Collaborated with kitchen designers & home automation experts.',
        socialresponsibility: 'Focused on sustainability and eco-friendly practices.',
        competitors: 'None',
        campaign_description: "Get creative with our versatile kitchen tools in unique recipe experiments.",
        campaign_type: 'Brand Awareness',
        url: 'https://www.yourkitchenbrand.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
        color: '#0038e4',
    },
    lg: {
        id: 'lg',
        name: 'LG ThinQ',
        handle: 'lg_thingq',
        description: 'Manufacturer of home appliances, electronics, and mobile devices.',
        types: ['Appliances'],
        values: 'Innovation, cutting-edge technology, sleek design.',
        demographics: 'Tech enthusiasts, homeowners, individuals seeking smart and connected appliances.',
        reach: [{ type: 'youtube', count: '1.5M' }, { type: 'tiktok', count: '2M' }, { type: 'web', count: '800k' }],
        incentives: 'Product samples, brand ambassador program.',
        requirements: '2 social media posts showcasing LG appliances in a modern home.',
        collaborations: 'Collaborated with interior designers, home automation experts.',
        socialresponsibility: 'Focused on sustainability and eco-friendly practices.',
        competitors: 'Samsung, Whirlpool',
        campaign_description: "Get creative with our versatile kitchen tools in unique recipe experiments.",
        campaign_type: 'Brand Awareness',
        url: 'https://www.lg.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
        color: '#e4003a',
    },
    whirlpool: {
        id: 'whirlpool',
        name: 'Whirlpool',
        handle: 'whirlpool',
        description: 'Manufacturer of home appliances including washers, dryers, refrigerators, and more.',
        types: ['Appliances'],
        values: 'Reliability, performance, ease of use.',
        demographics: 'Homeowners, families, individuals seeking dependable and efficient appliances.',
        reach: [{ type: 'youtube', count: '1M' }, { type: 'tiktok', count: '1.5M' }, { type: 'web', count: '500k' }],
        incentives: 'Product discounts, loyalty rewards program.',
        requirements: '1 video review featuring a Whirlpool appliance in a real-life usage scenario.',
        collaborations: 'Collaborated with home improvement bloggers, family-focused content creators.',
        socialresponsibility: 'Committed to reducing environmental impact and promoting sustainable practices.',
        competitors: 'Samsung, LG',
        campaign_description: "Become a meal prep influencer and inspire others with organization tips.",
        campaign_type: 'Brand Awareness',
        url: 'https://www.whirlpool.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
        color: '#ffb500',
    },
    bosch: {
        id: 'bosch',
        name: 'Bosch',
        handle: 'BoschHomeUS',
        platformName: 'Home Connect',
        description: 'Manufacturer of home appliances and power tools.',
        types: ['Appliances'],
        values: 'Innovation, precision, reliability.',
        demographics: 'Homeowners, professionals, individuals seeking high-quality appliances.',
        reach: [{ type: 'youtube', count: '800k' }, { type: 'tiktok', count: '1M' }, { type: 'web', count: '400k' }],
        incentives: 'Product discounts, loyalty program.',
        requirements: '1 video demonstration showcasing a Bosch appliance\'s advanced features.',
        collaborations: 'Collaborated with interior designers, home renovation experts.',
        socialresponsibility: 'Committed to sustainable manufacturing and eco-friendly practices.',
        competitors: 'Samsung, LG',
        campaign_description: "Bring out your inner chef and share your signature recipes.",
        campaign_type: 'Product Launch',
        url: 'https://www.bosch-home.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
        color: '#4377ed',
    },
    electrolux: {
        id: 'electrolux',
        name: 'Electrolux',
        handle: 'electrolux',
        description: 'Manufacturer of home appliances including refrigerators, dishwashers, and more.',
        types: ['Appliances'],
        values: 'Innovation, sustainability, Scandinavian design.',
        demographics: 'Homeowners, eco-conscious individuals, those seeking stylish and eco-friendly appliances.',
        reach: [{ type: 'youtube', count: '500k' }, { type: 'tiktok', count: '800k' }, { type: 'web', count: '300k' }],
        incentives: 'Product discounts, exclusive promotions.',
        requirements: '2 social media posts featuring Electrolux appliances in a modern and sustainable home.',
        collaborations: 'Collaborated with interior designers, sustainable living influencers.',
        socialresponsibility: 'Committed to reducing carbon footprint and promoting sustainable lifestyles.',
        competitors: 'Samsung, Bosch',
        campaign_description: "Capture the joy of cooking with our brand's kitchen essentials.",
        campaign_type: 'Seasonal',
        url: 'https://www.electrolux.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    maytag: {
        id: 'maytag',
        name: 'Maytag',
        handle: 'maytag',
        description: 'Manufacturer of home appliances known for durability and dependability.',
        types: ['Appliances'],
        values: 'Reliability, performance, long-lasting products.',
        demographics: 'Homeowners, families, individuals seeking sturdy and reliable appliances.',
        reach: [{ type: 'youtube', count: '300k' }, { type: 'tiktok', count: '500k' }, { type: 'web', count: '200k' }],
        incentives: 'Product warranties, loyalty rewards program.',
        requirements: '1 testimonial video showcasing a Maytag appliance\'s reliability and performance.',
        collaborations: 'Collaborated with home renovation experts, DIY enthusiasts.',
        socialresponsibility: 'Committed to responsible manufacturing and supporting local communities.',
        competitors: 'Whirlpool, KitchenAid',
        campaign_description: "Join our culinary adventure and explore new flavors together.",
        campaign_type: 'User Content',
        url: 'https://www.maytag.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    ge: {
        id: 'ge',
        name: 'GE Appliances',
        handle: 'ge_appliances',
        description: 'Manufacturer of a wide range of home appliances including refrigerators, ranges, and more.',
        types: ['Appliances'],
        values: 'Innovation, convenience, modern design.',
        demographics: 'Homeowners, tech-savvy individuals, those seeking cutting-edge appliances.',
        reach: [{ type: 'youtube', count: '800k' }, { type: 'tiktok', count: '1.2M' }, { type: 'web', count: '500k' }],
        incentives: 'Product discounts, early access to new features.',
        requirements: '1 social media post showcasing a GE appliance in a smart home environment.',
        collaborations: 'Collaborated with tech influencers, home automation experts.',
        socialresponsibility: 'Focused on energy efficiency and reducing environmental impact.',
        competitors: 'Samsung, LG',
        campaign_description: "Become a brand ambassador and inspire others with your cooking.",
        campaign_type: 'Contest',
        url: 'https://www.geappliances.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    frigidaire: {
        id: 'frigidaire',
        name: 'Frigidaire',
        handle: 'frigidaire',
        description: 'Manufacturer of home appliances known for affordability and reliability.',
        types: ['Appliances'],
        values: 'Affordability, dependability, value for money.',
        demographics: 'Budget-conscious homeowners, individuals seeking reliable and affordable appliances.',
        reach: [{ type: 'youtube', count: '400k' }, { type: 'tiktok', count: '600k' }, { type: 'web', count: '250k' }],
        incentives: 'Product discounts, loyalty rewards program.',
        requirements: '2 social media posts featuring Frigidaire appliances in real-life usage scenarios.',
        collaborations: 'Collaborated with home improvement bloggers, budget-friendly living influencers.',
        socialresponsibility: 'Committed to sustainability and supporting local communities.',
        competitors: 'Whirlpool, Maytag',
        campaign_description: "Unleash your creativity in our recipe contest for a chance to win.",
        campaign_type: 'Contest',
        url: 'https://www.frigidaire.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    haier: {
        id: 'haier',
        name: 'Haier',
        description: 'Manufacturer of home appliances and consumer electronics.',
        types: ['Appliances'],
        values: 'Innovation, versatility, compact design.',
        demographics: 'Urban dwellers, individuals with limited space, those seeking compact and efficient appliances.',
        reach: [{ type: 'youtube', count: '200k' }, { type: 'tiktok', count: '400k' }, { type: 'web', count: '250k' }],
        incentives: 'Product giveaways, influencer discounts.',
        requirements: '1 video demonstrating the versatility and space-saving features of a Haier appliance.',
        collaborations: 'Collaborated with small-space living influencers, interior designers.',
        socialresponsibility: 'Committed to energy efficiency and environmental sustainability.',
        competitors: 'Samsung, LG',
        campaign_description: "Showcase our brand's products in your favorite kitchen hacks.",
        campaign_type: 'Product Launch',
        url: 'https://www.haier.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    sharp: {
        id: 'sharp',
        name: 'Sharp',
        handle: 'sharp',
        description: 'Manufacturer of home appliances and consumer electronics with a focus on innovative technologies.',
        types: ['Appliances'],
        values: 'Cutting-edge technology, smart solutions, sleek design.',
        demographics: 'Tech enthusiasts, early adopters, individuals seeking advanced home appliances.',
        reach: [{ type: 'youtube', count: '300k' }, { type: 'tiktok', count: '500k' }, { type: 'web', count: '200k' }],
        incentives: 'Product samples, exclusive product trials.',
        requirements: '2 social media posts showcasing Sharp appliances in a futuristic home setup.',
        collaborations: 'Collaborated with tech influencers, smart home experts.',
        socialresponsibility: 'Committed to energy efficiency and reducing electronic waste.',
        competitors: 'Samsung, LG',
        campaign_description: "Collaborate with us to create mouthwatering food tutorials.",
        campaign_type: 'Brand Campaign',
        url: 'https://www.sharpusa.com/',
        optedin: false,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    miele: {
        id: 'miele',
        name: 'Miele',
        handle: 'miele',
        description: 'Manufacturer of premium home appliances known for their durability and performance.',
        types: ['Appliances'],
        values: 'Quality, craftsmanship, superior performance.',
        demographics: 'Homeowners, cooking enthusiasts, individuals valuing high-end and long-lasting appliances.',
        reach: [{ type: 'youtube', count: '500k' }, { type: 'tiktok', count: '700k' }, { type: 'web', count: '300k' }],
        incentives: 'Product warranties, exclusive events.',
        requirements: '1 testimonial video highlighting the exceptional performance of a Miele appliance.',
        collaborations: 'Collaborated with professional chefs, luxury home designers.',
        socialresponsibility: 'Committed to sustainability and supporting charitable initiatives.',
        competitors: 'Bosch, Siemens',
        campaign_description: "Share the love for cooking and be part of our foodie community.",
        campaign_type: 'Brand Campaign',
        url: 'https://www.miele.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    siemens: {
        id: 'siemens',
        name: 'Siemens',
        handle: 'siemens',
        description: 'Manufacturer of home appliances and electronics with a focus on innovative and sustainable solutions.',
        types: ['Appliances'],
        values: 'Innovation, sustainability, sleek design.',
        demographics: 'Eco-conscious individuals, homeowners, those seeking technologically advanced and energy-efficient appliances.',
        reach: [{ type: 'youtube', count: '400k' }, { type: 'tiktok', count: '600k' }, { type: 'web', count: '250k' }],
        incentives: 'Product discounts, loyalty program.',
        requirements: '2 social media posts showcasing Siemens appliances in a sustainable and eco-friendly home.',
        collaborations: 'Collaborated with environmental influencers, sustainable living advocates.',
        socialresponsibility: 'Committed to sustainable manufacturing and reducing carbon footprint.',
        competitors: 'Miele, Bosch',
        campaign_description: "Elevate your baking game and feature our premium bakeware.",
        campaign_type: 'Giveaway',
        url: 'https://www.siemens-home.bsh-group.com/',
        optedin: false,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    microplane: {
        id: 'microplane',
        name: 'Microplane',
        handle: 'microplane',
        description: 'Manufacturer of high-quality graters, zesters, and kitchen tools.',
        types: ['Cookware'],
        values: 'Precision, versatility, culinary creativity.',
        demographics: 'Home cooks, professional chefs, individuals passionate about flavor and presentation.',
        reach: [{ type: 'youtube', count: '100k' }, { type: 'tiktok', count: '200k' }, { type: 'web', count: '50k' }],
        incentives: 'Product giveaways, influencer discounts.',
        requirements: '2 social media posts featuring Microplane tools in unique culinary creations.',
        collaborations: 'Collaborated with recipe developers, mixologists, culinary influencers.',
        socialresponsibility: 'Committed to sustainable manufacturing and supporting culinary education.',
        competitors: 'Zyliss, Cuisipro',
        campaign_description: "Join our brand journey and help us revolutionize the kitchen.",
        campaign_type: 'Social Cause',
        url: 'https://www.microplane.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    victorinox: {
        id: 'victorinox',
        name: 'Victorinox',
        handle: 'victorinox',
        description: 'Manufacturer of Swiss-made kitchen knives and culinary tools.',
        types: ['Cookware'],
        values: 'Quality, functionality, Swiss precision.',
        demographics: 'Home cooks, culinary enthusiasts, individuals seeking reliable and versatile tools.',
        reach: [{ type: 'youtube', count: '150k' }, { type: 'tiktok', count: '100k' }, { type: 'web', count: '80k' }],
        incentives: 'Product discounts, loyalty rewards program.',
        requirements: '1 video showcasing the versatility and precision of a Victorinox knife.',
        collaborations: 'Collaborated with professional chefs, cooking schools, culinary influencers.',
        socialresponsibility: 'Committed to sustainable practices and supporting culinary education.',
        competitors: 'Global, Shun',
        campaign_description: "Participate in our cooking challenges to win exciting prizes.",
        campaign_type: 'Seasonal',
        url: 'https://www.victorinox.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    chefn: {
        id: 'chefn',
        name: 'Chef\'n',
        handle: 'chefn',
        description: 'Manufacturer of innovative and functional kitchen tools and gadgets.',
        values: 'Creativity, practicality, culinary joy.',
        demographics: 'Home cooks, individuals seeking unique and fun kitchen gadgets.',
        types: ['Cookware'],
        reach: [{ type: 'youtube', count: '80k' }, { type: 'tiktok', count: '50k' }, { type: 'web', count: '30k' }],
        incentives: 'Product giveaways, influencer discounts.',
        requirements: '3 social media posts featuring Chef\'n tools in creative culinary preparations.',
        collaborations: 'Collaborated with food stylists, cooking bloggers, recipe contests.',
        socialresponsibility: 'Focuses on sustainable manufacturing practices.',
        competitors: 'Joseph Joseph, Tovolo',
        campaign_description: "Highlight our brand's sustainable practices in your content.",
        campaign_type: 'Brand Awareness',
        url: 'https://www.chefn.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    },
    lodge: {
        id: 'lodge',
        name: 'Lodge',
        handle: 'lodge',
        description: 'Manufacturer of cast iron cookware and kitchen accessories.',
        types: ['Cookware'],
        values: 'Durability, tradition, timeless cooking.',
        demographics: 'Home cooks, outdoor enthusiasts, individuals seeking versatile and long-lasting cookware.',
        reach: [{ type: 'youtube', count: '100k' }, { type: 'tiktok', count: '80k' }, { type: 'web', count: '60k' }],
        incentives: 'Product discounts, loyalty program.',
        requirements: '1 recipe video showcasing a dish prepared using Lodge cast iron cookware.',
        collaborations: 'Collaborated with food bloggers, outdoor cooking enthusiasts.',
        socialresponsibility: 'Committed to sustainable practices and supporting local communities.',
        competitors: 'Staub, Le Creuset',
        campaign_description: "Share your authentic cooking experiences with our kitchen tools.",
        campaign_type: 'Seasonal',
        url: 'https://www.lodgemfg.com/',
        optedin: true,
        rating: Math.ceil(Math.random() * 3),
        ppc: Math.random().toFixed(2),
    }
};

export default brandData;