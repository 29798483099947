import React, { Component } from 'react';
import { Outlet } from "react-router-dom";
import MenuBar from '../components/MenuBar';
import { Toaster } from 'react-hot-toast';

//
import '../css/layout.css';

//

class Layout extends Component {

  render() {

    const { userType, user, tenant, currentCreatorId, location, navigate, setUserTypeFunc, logoutFunc } = this.props;

    console.log({ msg: 'layout > render', 'this.props': this.props });

    return (

      <div className="app">

        <MenuBar
          currentUserType={userType}
          currentUser={user}
          currentTenant={tenant}
          currentCreatorId={currentCreatorId}
          location={location}
          navigate={navigate}
          setUserTypeFunc={setUserTypeFunc}
          logoutFunc={logoutFunc}
        />
        <div className="main mt-[5rem] md:mt-[7rem] xl:p-0 px-4" id='mainId'>

        <Toaster />

          <section className="section-content lg:flex lg:justify-center">
            <Outlet />
          </section>
        </div>

        <footer className="text-xs lg:text-[16px]">
          Copyright 2024 Ladle Cooking, Inc.
        </footer>

      </div>

    );

  }

};

export default Layout;