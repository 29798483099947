import React, { Component } from 'react';
import LoadingIcons from 'react-loading-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import RecipeList from '../../components/RecipeList.js';
import RecipeService from '../../services/recipe';
import RecipeListRow from '../../components/creator/RecipeListRow.js';

import { capitalizeAllWords } from '../../utils/capitalizeWord.js';
import RecipeMetricService from '../../services/recipemetric.js';
import RemovableTagPill from '../../components/RemovableTagPill.js';

//
import '../../css/recipes.css';

//

class CreatorRecipeListPage extends Component {

  constructor(props) {

    super(props);

    document.title = `Ladle - Your Recipes`;

    this.state = {
      recipeData: [],
      isLoading: true,
      error: undefined,
    };

  }

  //

  async getRecipes(options) {

    console.log({ msg: 'getRecipes', options });

    let recipes, recipeData;
    options.hideDetails = true;
    options.limit = 1000;

    try {

      recipes = await RecipeService.list(options, this.props.user);
      const recipeIds = await recipes.map(recipe => recipe._id);
      const metrics = await RecipeMetricService.createRequest(recipeIds, {}, this.props.user);

      const recipeData = await recipes.map(recipe => {
        return {
          recipe,
          metrics: { totalViews: metrics.totalViews[recipe._id], }
        }
      });

      this.setState({ recipeData, isLoading: false, error: undefined, options });
    } catch (err) {
      console.error('getRecipes > error', err);
      this.setState({ recipeData, isLoading: false, error: err.message, options });
    }

  }

  //

  async componentDidMount() {

    const queryParams = new URLSearchParams(window.location.search);
    let options = {
      sort: 'updatedat',
      order: 'desc',
    };

    for (let [key, value] of queryParams.entries()) {
      options[key] = value;
    }

    // convert tags string to an array and clean-up
    if (options.tags) options.tags = options.tags.toLowerCase().split(',').map(t => t.trim());

    let totalRecipesLength = await RecipeService.count({}, this.props.user);
    totalRecipesLength = (totalRecipesLength * 10) + 3;

    this.setState({ totalRecipesLength, options });
    this.getRecipes(options);

  }

  handleTagClick = (tag) => {
    console.log({ msg: 'handleTagClick', tag });
    let options = this.state.options;
    options.tags = [tag];
    this.setState({ recipeData: [], isLoading: true, options })
    this.getRecipes(options);
  }

  handleRemoveTagClick = (tagToRemove) => {
    console.log({ msg: 'handleRemoveTagClick', tagToRemove });
    let options = this.state.options;
    options.tags = options.tags.filter(t => t !== tagToRemove.toLowerCase());
    this.setState({ recipeData: [], isLoading: true, options })
    this.getRecipes(options);
  }

  handleRemoveTitleClick = (titleToRemove) => {
    console.log({ msg: 'handleRemoveTitleClick', titleToRemove });
    let options = this.state.options;
    delete options.title;
    this.setState({ recipeData: [], isLoading: true, options })
    this.getRecipes(options);
  }
  render() {

    const { recipeData, isLoading, error, options, totalRecipesLength } = this.state;

    let totalRecipesLengthStr = new Intl.NumberFormat('en-US').format(totalRecipesLength);

    const activeTags = this.state.options?.tags ? this.state.options?.tags : [];
    const activeTitles = this.state.options?.title ? [this.state.options?.title] : [];
    console.log({ msg: 'render', activeTags });
    const activeTagPillsHtml = (activeTags && activeTags.length > 0) ? activeTags.map((t, index) => <RemovableTagPill key={`${t}${index}`} label={t} color='green' size='large' onClick={() => this.handleRemoveTagClick(t)} />) : <></>;
    const activeTitlesPillsHtml = activeTitles ? activeTitles.map((t, index) => <RemovableTagPill key={`${t}${index}`} label={t} color='fuchsia' size='large' onClick={() => this.handleRemoveTitleClick(t)} />) : <></>;

    const tableCells = [
      { id: "image" },
      { id: "recipe", name: "Recipe", sortIcon: faSort },
      { id: "cuisine", name: "Cuisine", sortIcon: faSort },
      { id: "tags", name: "Tags", sortIcon: faSort },
      { id: "views", name: "Views", sortIcon: faSort },
      { id: "lastUpdatedDate", name: "Updated", sortIcon: faSort },
      { id: "manage" },
    ];

    let loadingText = `Loading Recipes`;
    if (options && options.tags && options.tags.length > 0) {
      loadingText = `Loading '${capitalizeAllWords(options.tags.join(', '))}' Recipes`;
    } else if (options && options.title) {
      loadingText = `Loading '${capitalizeAllWords(options.title)}' Recipes`;
    }

    let contentSectionHtml;

    if (error) {
      contentSectionHtml = (
        <div className="content-section">
          <div style={{ minWidth: '1250px', textAlign: 'center', verticalAlign: 'middle', alignItems: 'center', justifyContent: 'center', height: '400px', display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: '16pt', marginBottom: '32px' }}><FontAwesomeIcon icon={faExclamationTriangle} style={{ paddingRight: '12px' }} />Unable to load recipes. Please check your server connection.</p>
          </div>
        </div>
      );
    } else if (isLoading || !recipeData) {
      contentSectionHtml = (
        <div className="content-section">
          <div style={{ minWidth: '1250px', textAlign: 'center', verticalAlign: 'middle', alignItems: 'center', justifyContent: 'center', height: '400px', display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: '16pt', marginBottom: '32px' }}>{loadingText}</p>
            <LoadingIcons.TailSpin height="48px" stroke="#0e4621" fill="#0e4621" />
          </div>
        </div>
      );
    } else {

      contentSectionHtml = (
        <>
          <div className="content-title">Your Recipes ({totalRecipesLengthStr})</div>
          <div className="content-description">Review, modify, and control personalization options for your recipes and content.</div>
          <div className="content-menu-bar">{activeTagPillsHtml}{activeTitlesPillsHtml}</div>
          <div className="content-section">
            <RecipeList recipeData={recipeData} totalRecipesLength={totalRecipesLength} type='creator' tableCells={tableCells} appliance={options.appliance} ListRow={RecipeListRow} handleTagClick={this.handleTagClick} />
          </div>
        </>
      );

    }

    return contentSectionHtml;

  }

}

export default CreatorRecipeListPage;