import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faLink, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import LoadingIcons from 'react-loading-icons'

//
import RecipeService from '../../services/recipe.js';
import SmartRecipeService from '../../services/smartrecipe.js';
import ParseRecipeRequestService from '../../services/parsereciperequest.js';

import logger from "../../utils/log.js";

//
import '../../css/addrecipe.css';
import config from '../../utils/config.js';

//

const log = logger("pages.consumer.addrecipe");

//
class ConsumerAddRecipePage extends Component {

  timer;

  constructor(props) {

    super(props);

    document.title = `Ladle - Add Recipe`;

    this.state = {
      isLoading: false,
      addedRecipe: undefined,
      errorAdding: false,
      submitDisabled: false,
      scraperUrlTextFieldDisabled: false,
      scraperContentTextAreaDisabled: false,
      recipeAdded: false,
      recipeTitle: ""
    };

    this.recipeScraperUrlTextField = React.createRef();
    this.recipeScraperContentTextArea = React.createRef();

  }

  componentDidMount() {

  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  //

  handlRecipeUrlContentChange = (index, value) => {

    console.log('handlRecipeUrlContentChange');

    const scrapeUrlText = this.recipeScraperUrlTextField.current.value;
    //const scrapeContentText = this.recipeScraperContentTextArea.current.value;

    //this.setState({  scraperUrlTextFieldDisabled: !!scrapeContentText, scraperContentTextAreaDisabled: !!scrapeUrlText, submitDisabled: !scrapeContentText && !scrapeUrlText });
    this.setState({ submitDisabled: !scrapeUrlText });

  };

  //

  parseResponse = async (response) => {
    let recipe;
    if (response.result === "created" || response.result === "updated") {
      recipe = await RecipeService.get(response.recipe, {}, this.props.user);
      this.setState({ isLoading: false, errorAdding: false, addedRecipe: recipe });
    } else if (response.result === "error") {
      this.setState({ isLoading: false, errorAdding: true, addedRecipe: undefined });
    }
  }

  checkRequest = async (id) => {
    console.log('checkRequest > id: ' + id);
    const response = await ParseRecipeRequestService.get(id, this.props.user);
    console.log({ msg: 'checkRequest', response });
    if (!response) {
      clearTimeout(this.timer);
      this.parseResponse({ result: "error" });
    } else if (response.completed) {
      this.parseResponse(response);
      clearTimeout(this.timer);
    } else if (response.error) {
      console.log("Error on response", response);
      this.parseResponse(response);
    } else {
      if (response.parsedData && response.parsedData.title) {
        this.timer = setTimeout(() => this.checkRequest(response._id), 2000);
        this.setState({
          'recipeTitle': response.parsedData.title
        });
      } else {
        this.timer = setTimeout(() => this.checkRequest(response._id), 500);
      }
    }
  }

  handleSubmit = async (event) => {

    event.preventDefault();

    const recipeUrl = this.recipeScraperUrlTextField.current.value;
    //const recipeContent = this.recipeScraperContentTextArea.current.value;

    // if (!recipeUrl && !recipeContent) {
    if (!recipeUrl) {
      this.setState({ isLoading: false, errorAdding: true, addedRecipe: undefined, recipeUrl: undefined, });
      return;
    }

    this.setState({ addedRecipe: undefined, errorAdding: false, isLoading: true, recipeUrl });

    let requestPayload;
    if (recipeUrl) {
      requestPayload = { type: 'url', urls: [recipeUrl] };
    }
    // } else if (recipeContent) {
    //   requestPayload = { type: 'text', url: recipeContent };
    // }


    const responses = await fetch(`${config.AI_BASE_URL}/backend/scrap_recipe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "key": config.AI_API_KEY,
        "recipe_url": recipeUrl
      })
    })
    const final = await responses.json()
    console.log(final, "final is ")
    log.debug({ msg: 'handleSubmit', responses });
    if (final.status == 200) {
      console.log("checking", final.recipe_data.recipe_id, final.recipe_data._id)
      this.setState({
        recipeAdded: true,
        recipeTitle: final.recipe_data.title
      })
      setTimeout(async () => {
        const getRecipeId = await SmartRecipeService.getNewRecipeid(final.recipe_data.recipe_id);
        if (getRecipeId) {
          window.location.href = `/consumer/recipes/${getRecipeId.copied_recipe_id}/view`
        } else {
          window.location.href = `/consumer/recipes`
        }

      }, 3000)
    }


  };

  //

  renderNewRecipe(recipe) {

    const recipeUrl = `/consumer/recipes/${recipe._id}/view`;
    const heroImageUrl = recipe.media.heroImageUrl;

    const bannerStyle = {
      background: 'url(' + heroImageUrl + '), rgba(160, 219, 128, 0.5)',
      backgroundSize: 'cover',
    };

    return (
      <>
        <div className="content-section" style={{ margin: '0px', marginBottom: '16px' }}>
          <Link to={recipeUrl}>
            <div className="tile" style={bannerStyle}>
              <div style={{ textAlign: 'center', verticalAlign: 'middle', margin: '0px', alignItems: 'center', justifyContent: 'center', height: '50px', display: 'flex', flexDirection: 'row' }}>
                <div><h3>Recipe Added</h3>{recipe.displayName}</div>
              </div>
            </div>
          </Link>
        </div>
      </>
    );

  }

  renderErrorAddingRecipe() {

    return (
      <>
        <div className="content-section" style={{ margin: '0px', marginBottom: '16px' }}>
          <div className="tile" style={{ borderColor: '#dc9797', backgroundColor: '#f5e3e3' }}>
            <div style={{ textAlign: 'center', verticalAlign: 'middle', margin: '0px', alignItems: 'center', justifyContent: 'center', height: '50px', display: 'flex', flexDirection: 'row' }}>
              <h4><FontAwesomeIcon icon={faTriangleExclamation} style={{ marginRight: '12px' }} />There was a problem adding your recipe. Please try again.</h4>
            </div>
          </div>
        </div>
      </>
    );

  }

  renderLoading(recipeTitle) {

    return (
      <>

        <div className="content-header">

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <div className="content-title">Add Recipe</div>
            <div className="content-description">Upload your latest recipes and content.</div>
          </div>
        </div>

        <div className="content-section">
          <div className="tile" style={{ minWidth: '1250px' }}>
            <div style={{ textAlign: 'center', verticalAlign: 'middle', alignItems: 'center', justifyContent: 'center', height: '400px', display: 'flex', flexDirection: 'column' }}>
              <p style={{ marginBottom: '12px' }}><b>{recipeTitle ? `Adding Recipe` : ''}</b></p>
              <p style={{ marginBottom: '32px' }}>{recipeTitle ? recipeTitle : ''}</p>
              <LoadingIcons.TailSpin height="48" stroke="#0e4621" fill="#0e4621" />
            </div>
          </div>
        </div>

      </>
    );

  }

  render() {

    log.debug('render');

    const { isLoading, recipeTitle, addedRecipe, errorAdding, scraperUrlTextFieldDisabled, scraperContentTextAreaDisabled, submitDisabled } = this.state;

    if (isLoading) {
      return this.renderLoading(recipeTitle);
    }

    const newRecipeHtml = addedRecipe ? this.renderNewRecipe(addedRecipe) : <></>;
    const errorAddingHtml = errorAdding ? this.renderErrorAddingRecipe() : <></>;

    return (

      <>
        {this.state.addedRecipe == true ? <div className='bg-green-800 mt-2 mb-4 text-white p-5 text-2xl'>
          Recipe {this.state.recipeTitle} successfully added!. Redirecting...
        </div> : ""}
        <div className="content-header">

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <div className="content-title">Add Recipe</div>
            <div className="content-description">Upload your latest recipes and content</div>

          </div>
        </div>

        {newRecipeHtml}
        {errorAddingHtml}

        <div className="content-section">

          <div className="tile">

            <form onSubmit={this.handleSubmit}>

              <div className="form-section">

                <h3>Recipe URL</h3>
                <div style={{ marginBottom: '24px' }}>
                  <p style={{ fontSize: '12pt', lineHeight: '16px' }}>Provide a link to your recipe.</p>
                  <p>Ladle will automatically parse the recipe and take care of the rest.  Click the Submit button at the bottom once you're done.</p>
                </div>

                <div className="input-container">
                  <input className="icon" type="url" placeholder='Recipe URL' required ref={this.recipeScraperUrlTextField} onChange={this.handlRecipeUrlContentChange} disabled={scraperUrlTextFieldDisabled} />
                  <FontAwesomeIcon className="input-icon" icon={faLink} />
                </div>
              </div>
              {/* 
              <div style={{paddingTop: 24}} className="form-section">
                <h3>Recipe Content</h3>
                <p>If your recipe is not available on the site above, copy & paste your recipe as-is and we'll do the rest.</p>
                <textarea id="recipe-content" onChange={this.handlRecipeUrlContentChange} disabled={scraperContentTextAreaDisabled} className="tall" ref={this.recipeScraperContentTextArea} ></textarea>
              </div> */}

              <button type="submit" disabled={!!submitDisabled} className="green"><FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '12px' }} />Submit</button>

            </form>
          </div>
        </div>
      </>

    );

  }

};

export default ConsumerAddRecipePage;