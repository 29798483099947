const fractions = {
  '0' : '',
  '0.1000': String.fromCharCode(8530), // ⅒
  '0.2500': String.fromCharCode(188),  // ¼
  '0.5000': String.fromCharCode(189),  // ½
  '0.7500': String.fromCharCode(190),  // ¾
  '0.3300': String.fromCharCode(8531),   // ⅓
  '0.3330': String.fromCharCode(8531),   // ⅓
  '0.3333': String.fromCharCode(8531), // ⅓
  '0.6600': String.fromCharCode(8532), // ⅔
  '0.6660': String.fromCharCode(8532), // ⅔
  '0.6666': String.fromCharCode(8532), // ⅔
  '0.6670': String.fromCharCode(8532), // ⅔
  '0.6667': String.fromCharCode(8532), // ⅔
  '0.2000': String.fromCharCode(8533), // ⅕
  '0.4000': String.fromCharCode(8534), // ⅖
  '0.6000': String.fromCharCode(8535), // ⅗
  '0.8000': String.fromCharCode(8536), // ⅘
  '0.1666': String.fromCharCode(8537), // ⅙
  '0.1667': String.fromCharCode(8537), // ⅙
  '0.8333': String.fromCharCode(8538), // ⅚
  '0.8334': String.fromCharCode(8538), // ⅚
  '0.1250': String.fromCharCode(8539), // ⅛
  '0.3750': String.fromCharCode(8540), // ⅜
  '0.6250': String.fromCharCode(8541), // ⅝
  '0.8750': String.fromCharCode(8542), // ⅞
  '0.1100' : String.fromCharCode(8529), // ⅑
  '0.1110' : String.fromCharCode(8529), // ⅑
  '0.1111' : String.fromCharCode(8529), // ⅑
  '0.2220' : '2/9', // doesn't exist as vulgar fraction
  '0.2200' : '2/9', // doesn't exist as vulgar fraction
  '0.2222' : '2/9', // doesn't exist as vulgar fraction
  '0.4400' : '4/9', // doesn't exist as vulgar fraction
  '0.4440' : '4/9', // doesn't exist as vulgar fraction
  '0.4444' : '4/9', // doesn't exist as vulgar fraction
  '0.5500' : '5/9', // doesn't exist as vulgar fraction 
  '0.5550' : '5/9', // doesn't exist as vulgar fraction 
  '0.5555' : '5/9', // doesn't exist as vulgar fraction 
  '0.7700' : '7/9', // doesn't exist as vulgar fraction
  '0.7770' : '7/9', // doesn't exist as vulgar fraction
  '0.7777' : '7/9', // doesn't exist as vulgar fraction
  '0.7778' : '7/9', // doesn't exist as vulgar fraction
  '0.8800' : '8/9', // doesn't exist as vulgar fraction
  '0.8880' : '8/9', // doesn't exist as vulgar fraction
  '0.8888' : '8/9', // doesn't exist as vulgar fraction
  '0.8889' : '8/9', // doesn't exist as vulgar fraction
};

export default fractions;