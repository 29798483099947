import React, { Component } from 'react';
import logger from "../../utils/log.js";

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { capitalizeWord } from '../../utils/capitalizeWord.js'

//

import '../../css/components/ConsumerAddApplianceRow.css';

const log = logger('components.consumer.ConsumerAddApplianceRow');

//

class ConsumerAddApplianceRow extends Component {

    constructor(props) {       
        super(props);
        this.state = {};
    }

    async componentDidMount() {
    }

    render() {

        const { applianceType, tenant } = this.props;
        console.log('render > applianceType', applianceType);
        const { name, brand, model } = applianceType;

        const imageUrl = `/brands/${tenant.id}/appliances/${model.toLowerCase()}.png`;

        return (
            <>
                <TableRow onClick={() => this.props.onClick(applianceType)} className='addApplianceRowTableRow'>
                    <TableCell className='addApplianceRowTableCell image'>
                        <div className='addApplianceRowImageContainer'>
                            <img className='addApplianceImage' src={imageUrl} alt={name} />
                        </div>
                    </TableCell>
                    <TableCell className='addApplianceRowTableCell model'>{capitalizeWord(brand)} {name}</TableCell>
                    <TableCell className='addApplianceRowTableCell space'></TableCell>
                </TableRow>
            </>
        );
    }
}

export default ConsumerAddApplianceRow;
