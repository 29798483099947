import React, { Component } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import BrandMarketplaceRow from '../../components/brand/MarketplaceRow';

import { faAdd, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CreatorData from '../../data/creatorData';
import shuffleObject from '../../utils/shuffleObject';

//
const shuffledRowData = shuffleObject(CreatorData);

//

class BrandMarketplacePage extends Component {

  constructor(props) {

    super(props);

    document.title = `Ladle - Creators`;

    this.state = {
      isLoading: true,
      page: 0,
      rowsPerPage: 10,
    };

  }

  async componentDidMount () {       
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage: parseInt(event.target.value, 10), page:0});
  };

  
  //

  render() {

    console.log({msg:'render', CreatorData});

    const { page, rowsPerPage } = this.state;

    const visibleRows = shuffledRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (

      <>

        <div className="content-title">Creator Marketplace</div>
        <div className="content-description">Connect with creators to leverage their recipes and reach more home cooks.</div>

        <TableContainer style={{ marginTop:'36px', marginBottom:'12px', border: '1px solid #ddd', borderRadius: 15, paddingTop: '0px', paddingBottom: '0px', backgroundColor: '#fdfdfd' }} component={Paper}>
          <Table sx={{ minWidth: 1040 }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: 'none',  paddingTop: '4px', paddingBottom:'8px'}} colSpan={6}>
                  <div className="input-container">
                    <FontAwesomeIcon className='input-icon' icon={faSearch} />
                    <input style={{border:'none'}} className="search-icon" type="text" id="creator-search" placeholder='Search for Creators -- e.g. Vegan, Pizza, French, Korean, Families, Healthy' />
                  </div>
                </TableCell>
                <TableCell colSpan={3} style={{ border: 'none'}}><button className={'outline'} style={{ borderRadius: '16px', fontSize: '11pt', width: '196px', backgroundColor: '#eed189'}}><FontAwesomeIcon color='#555' style={{marginRight: '6px'}} icon={faAdd} /> Add All</button></TableCell>
                <TableCell style={{ border: 'none', width:'72px'}}></TableCell>
              </TableRow>
              </TableBody>
          </Table>
        </TableContainer>

        <TableContainer style={{  border: '1px solid #ddd', borderRadius: 15, padding: 12, backgroundColor: '#fdfdfd' }} component={Paper}>
          <Table sx={{ minWidth: 1040 }} aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left"><b>Creator</b></TableCell>
                <TableCell align="left"><b>Description</b></TableCell>
                <TableCell align="left"><b>Recipes</b></TableCell>
                <TableCell style={{ width: '128px' }} align="left"><b>Social Reach</b></TableCell>
                <TableCell align="left"><b>Profile</b></TableCell>
                <TableCell align="left"><b>Add to Campaign</b></TableCell>
                <TableCell align="left"><b></b></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {visibleRows.map((row) => (<BrandMarketplaceRow key={row.id} row={row} />))}
            </TableBody>

          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={shuffledRowData.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />

      </>

    );

  }

};

export default BrandMarketplacePage;