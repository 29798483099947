import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faBriefcase, faGlobeAmericas, faUser, faUpRightFromSquare, faAdd } from '@fortawesome/free-solid-svg-icons'
import { faYoutube, faInstagram, faTwitch, faPinterest, faTiktok, faFacebook, faChrome } from '@fortawesome/free-brands-svg-icons';
import { faStar as farStar, faHeart as farHeart, faHandshake as farHandshake } from '@fortawesome/free-regular-svg-icons'

import logger from "../../utils/log.js";

const iconMap = {
    instagram: faInstagram,
    youtube: faYoutube,
    pinterest: faPinterest,
    tiktok: faTiktok,
    facebook: faFacebook,
    web: faChrome,
}

//

const log = logger('components.brand.MarketplaceRow');

class BrandActiveMarketplaceRow extends Component {

    constructor(props) {
        super(props);
        this.state = { 'open': false, 'optedin': this.props.row.optedin };
    }

    async componentDidMount() {
    }

    render() {

        const { open, optedin } = this.state;
        const { row } = this.props;
        let { id, name, handle, rating, social, description, recipe_count, geographic_reach, demographics, expertise, tags, values, types, ppc=0.15} = row;

        ppc = ((Math.random() * 0.15)+.15).toFixed(2);

        const imageUrl = '/creators/' + id + '.jpg';
        //const imageUrl = '/user-logo.jpg';
        const socialMetrics = social.map(function (metric) {
            const socialIcon = <FontAwesomeIcon style={{ paddingRight: 4 }} icon={iconMap[metric.type]} />;
            const socialUrl = 'http://' + metric.type + '.com/@' + metric.profile;
            const linkIcon = <FontAwesomeIcon color='#666' style={{ height: '12px', paddingLeft: '6px' }} icon={faUpRightFromSquare} />
            return (<><span>{socialIcon}{metric.count}<Link to={socialUrl}>{linkIcon}</Link></span><br /></>);
        });

        const ratingHtml = (rating == 3) ? (<><FontAwesomeIcon style={{ color: 'gold' }} icon={faStar} /><FontAwesomeIcon style={{ color: 'gold' }} icon={faStar} /><FontAwesomeIcon style={{ color: 'gold' }} icon={faStar} /></>) : rating == 2 ? <><FontAwesomeIcon style={{ color: 'darkgrey' }} icon={faStar} /><FontAwesomeIcon style={{ color: 'darkgrey' }} icon={faStar} /><FontAwesomeIcon style={{ color: 'lightgrey' }} icon={farStar} /></> : <><FontAwesomeIcon style={{ color: '#b08d57' }} icon={faStar} /><FontAwesomeIcon style={{ color: 'lightgrey' }} icon={farStar} /><FontAwesomeIcon style={{ color: 'lightgrey' }} icon={farStar} /></>;
        const recipeLink = `../brand/recipes/@${id}`;
        const creatorProfileLink = `../brand/creators/@${id}`;

        const addButtonHtml = <><FontAwesomeIcon color='#666' style={{marginRight: '6px'}} icon={faAdd} /> Add Creator</>

        return (
            <>
                <TableRow>
                    <TableCell style={{ border: 'none', height: 128, paddingBottom: 32, paddingTop: 32 }}>
                        <div className="profile-photo"><img style={{ maxWidth: 64 }} src={imageUrl} alt="Creator Profile Photo" /></div>
                    </TableCell>
                    <TableCell style={{ border: 'none' }} ><div style={{display: 'flex', flexDirection: 'column'}}><span style={{fontSize: '12pt'}}>@{handle}</span><span style={{fontSize: '11pt', color: '#666'}}>{name}</span></div></TableCell>
                    <TableCell style={{ border: 'none' }} >{description}</TableCell>
                    <TableCell style={{ border: 'none' }} ><Link to={recipeLink}>{recipe_count} <FontAwesomeIcon color='#666' style={{ height: '12px', paddingLeft: '4px' }} icon={faUpRightFromSquare} /></Link></TableCell>
                    <TableCell style={{ border: 'none' }}>{socialMetrics}</TableCell>
                    <TableCell style={{ border: 'none' }}>
                    <Link to={creatorProfileLink}><button className='outline' style={{ width: '128px' }}><FontAwesomeIcon color='#666' style={{marginRight: '6px'}} icon={faUser} /> View Profile</button></Link>
                    </TableCell>
                    <TableCell style={{ border: 'none' }}>
                        <button className={optedin ? 'outline selectedgreen' : 'outline'} style={{ width: '128px' }} onClick={() => this.setState({ optedin: !optedin })}>{optedin ? 'Added' : addButtonHtml} </button>
                    </TableCell>
                    <TableCell style={{ border: 'none' }}>
                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ open: !open })} >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>

                <TableRow sx={{ marginBottom:32 }}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 0, marginBottom: 4, border: '1px solid #ddd', 'borderRadius': 2, backgroundColor: '#fcfcfc' }}>
                                <Table size="small">
                                    <Box sx={{ margin: 2 }}>
                                        <TableHead>
                                            <TableRow style={{ border: 'none' }}>
                                                <TableCell style={{ border: 'none', width: 324 }}><FontAwesomeIcon icon={farHeart} /><b> Values</b></TableCell>
                                                <TableCell style={{ border: 'none', width: 324 }}><FontAwesomeIcon icon={farHandshake} /><b> Tags</b></TableCell>
                                                <TableCell style={{ border: 'none', width: 324 }}><FontAwesomeIcon icon={faGlobeAmericas} /><b> Rating</b></TableCell>
                                                <TableCell style={{ border: 'none', width: 324 }}><FontAwesomeIcon icon={faBriefcase} /><b> Demographics</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow style={{ border: 'none' }} key={id}>
                                                <TableCell style={{ border: 'none' }}>{values}</TableCell>
                                                <TableCell style={{ border: 'none' }}>{tags.join(', ')}</TableCell>
                                                <TableCell style={{ border: 'none' }}>{rating}</TableCell>
                                                <TableCell style={{ border: 'none' }}>{demographics}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Box>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>

            </>
        );
    }
}

export default BrandActiveMarketplaceRow;
