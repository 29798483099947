import React, { Component } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ConsumerApplianceRow from "../../components/consumer/ConsumerApplianceRow.js";
import ConsumerAddApplianceRow from "../../components/consumer/ConsumerAddApplianceRow.js";
import UserApplianceService from '../../services/userappliance.js';
import ApplianceTypeService from '../../services/appliancetype.js';

import '../../css/ConsumerManageAppliances.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material';
import { faAdd, faCirclePlay, faCirclePlus, faKitchenSet  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


//

class ConsumerDevicesPage extends Component {

  constructor(props) {
  
    super(props);

    document.title = `Ladle - My Kitchen`;

    this.state = {
      isLoading: true,
      userAppliances: undefined,
      applianceTypes: undefined,
      page: 0,
      rowsPerPage: 10,
      user: props.user,
      tenant: props.tenant,
      showAppliancesModal: false,
      snackbarText: undefined,
    };

  }
  
  //

  async componentDidMount () {
    
    try {

      let userAppliances = await this.getUserAppliances();
      userAppliances = userAppliances.sort(row => row.connected === true ? 1 : -1);   
      const applianceTypes = await this.getApplianceTypes();
      if (userAppliances && applianceTypes) {
        this.setState({ userAppliances, applianceTypes, isLoading: false, error: undefined });
      } else {
        this.setState({ userAppliances: undefined, applianceTypes: undefined, isLoading: false, error: 'Failed to load appliance data' });  
      }
    } catch (err) {
      console.error('componentDidMount > error', err);
      this.setState({ userAppliances: undefined, applianceTypes: undefined, isLoading: false, error: err.message });
    }

  }

  //

  getUserAppliances = async (options) => {
    return await UserApplianceService.list(options, this.props.user);
  }

  getApplianceTypes = async (options) => {
    return await ApplianceTypeService.list(options, this.props.user);
  }

  updateUserAppliance = async (userAppliance, data) => {
    return await UserApplianceService.update(userAppliance._id, data, this.props.user);
  }

  addUserAppliance = async (applianceType) => {
    console.log({msg:'addUserAppliance', applianceType});
    const addedAppliance = await UserApplianceService.create({ applianceType: applianceType._id, }, this.props.user);
    //const userAppliances = await this.getUserAppliances();
    const snackbarText = `${addedAppliance.name} added.`;
    this.setState({userAppliances:[...this.state.userAppliances, addedAppliance], showAppliancesModal:false, snackbarText});
  }

  removeUserAppliance = async (userAppliance) => {
    console.log('removeUserAppliance', userAppliance);
    const success = await UserApplianceService.remove(userAppliance._id, this.props.user);
    const snackbarText = success ? `Appliance removed.` : 'Failed to remove appliance';
    const userAppliances = await this.getUserAppliances();
    this.setState({userAppliances, showAppliancesModal:false, snackbarText});
  }

  setConnectState = async (userAppliance, connected) => {
    const updatedAppliance = await this.updateUserAppliance(userAppliance, { connected });
    const userAppliances = await this.getUserAppliances();
    let snackbarText = `Appliance ${updatedAppliance.connected ? 'connected successfully.' : 'disconnected successfully.'}`;
    this.setState({userAppliances, snackbarText});
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  showAddAppliancesModal = () => {
    this.setState({showAppliancesModal:true});
  }

  hideAddAppliancesModal = () => {
    this.setState({showAppliancesModal:false});
  }

  //

  getAppliancesSection () {

    const { userAppliances, applianceTypes, page, rowsPerPage, user, tenant, showAppliancesModal, snackbarText } = this.state;

    return (
      <>
        <TableContainer style={{ border: "1px solid #ddd", borderRadius: 15, padding: 12, backgroundColor: "#fdfdfd" }} component={Paper}>
            <Table className='userApplianceTable min-w-[1240px] lg:min-w-[100%]' aria-label="collapsible table">

              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left"><b>Device Name</b></TableCell>
                  <TableCell align="left"><b>Model</b></TableCell>
                  <TableCell align="left"><b>Type</b></TableCell>
                  <TableCell align="left"><b>Connected</b></TableCell>
                  <TableCell align="left"><b></b></TableCell>
                  <TableCell align="left"><b></b></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {userAppliances.map((userAppliance) => (
                  <ConsumerApplianceRow key={userAppliance._id} userAppliance={userAppliance} user={user} tenant={tenant} onConnectClick={() => this.setConnectState(userAppliance, !userAppliance.connected)} onRemoveClick={() => this.removeUserAppliance(userAppliance)} />
                ))}
              </TableBody>

            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userAppliances.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </>
    );

  }

  getNoAppliancesSection () {
    
    return (
      <>
        <TableContainer style={{ display: 'flex', justifyContent: 'center', border: "1px solid #ddd", borderRadius: 15, padding: 12, backgroundColor: "#fdfdfd" }} component={Paper}>
          <div style={{ minWidth: '80vw', textAlign:'center', verticalAlign:'middle', alignItems:'center', justifyContent:'center', height:'400px', display:'flex', flexDirection:'column' }}>
            <p style={{ fontSize: '20pt', marginBottom: '32px'}}>No appliances are set up</p>
            <button className="outline green" onClick={this.showAddAppliancesModal} style={{width:'240px'}}><FontAwesomeIcon icon={faKitchenSet} style={{ marginRight: '12px' }} />Click to add one</button>
          </div>
        </TableContainer>
      </>
    );

  }

  render() {

    const { userAppliances, applianceTypes, page, rowsPerPage, user, tenant, showAppliancesModal, snackbarText } = this.state;

    if (!userAppliances || !applianceTypes) return;

    const contentSection = (userAppliances.length === 0) ? this.getNoAppliancesSection() : this.getAppliancesSection();
    const addApplianceButton = (userAppliances.length === 0) ? <></> : (
      <div>
        <button className="outline green-white mr-1 w-52 lg:w-[240px] h-8 lg:h-[64px]" onClick={this.showAddAppliancesModal} style={{borderRadius: '16px', fontSize: '12pt'}}><FontAwesomeIcon icon={faCirclePlus} style={{ marginRight: '12px', height: '20px', width: '20px' }} />Add an appliance</button>
      </div>
    );

    return (
      <>
      <div className="lg:flex lg:flex-col lg:w-[1250px]">
        <div className="content-header-for-appliances flex flex-col md:flex-row justify-between items-end gap-2 lg:gap-0">
          <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
            <div className="content-title">{`${user.name.split(' ')[0]}'s Kitchen`}</div>
            <div className="content-description text-lg lg:text-[16pt]">Add and manage your appliances and cookware.</div>
          </div>
          {addApplianceButton}
        </div>
        {contentSection}

        <Dialog className='addApplianceDialog' open={showAppliancesModal} >

          <DialogTitle className='addApplianceDialogTitle'>Select an appliance</DialogTitle>

          <DialogContent sx={{padding:'24px' }}>
            <TableContainer className='addApplianceTableContainer' component={Paper}>
              <Table className='addApplianceTable' aria-label="collapsible table">
                <TableBody>
                  {applianceTypes.map((applianceType) => (
                    <ConsumerAddApplianceRow key={applianceType._id} applianceType={applianceType} user={user} tenant={tenant} onClick={this.addUserAppliance} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>

          <DialogActions className='addApplianceDialogActions'>
            <Button sx={{color: 'black'}} onClick={this.hideAddAppliancesModal}>Cancel</Button>
          </DialogActions>

        </Dialog>

        <Snackbar
          open={snackbarText !== undefined}
          autoHideDuration={6000}
          message={snackbarText}
          onClose={() => { this.setState({snackbarText:undefined})}}
        />
      </div>
      </>
    );
  }
}

export default ConsumerDevicesPage;