import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SideBar from '../../components/SideBar';
import logger from "../../utils/log.js";
import { weeklyinsights, appsrecipes, sponsorships, getChartData } from '../../data/brandDashboardData';

import '../../css/home.css';
import TenantMetricService from '../../services/tenantmetric.js';

//

const log = logger("pages.brand.dashboard");

//

function createDataTile (data) {
  return (
    <div className="insight-tile" key={data.key}>
      <FontAwesomeIcon style={{ height: 28, color: 'lightgreen', paddingBottom: 8 }} icon={data.icon} />
      <h3 style={{ fontWeight: 350 }}>{data.title}</h3>
      <h3 style={{ 'fontSize': 28, 'fontWeight': 500 }}>{data.value}</h3>
      <p>{data.description}</p>
      <button className='outline'>View</button>
    </div>
  )
}

function createDataTileRow(key, rowTitle, data) {
  return (
    <React.Fragment key={key}>
      <div className="content-title" style={{ marginBottom: '24px' }}>{rowTitle}</div>
      <div className="content-section">
        <div className="grid-container">
          <div className="insights-grid">
            {data.map(tileData => createDataTile(tileData))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

function createLineChart(key, title, dataKey) {
  return (
    <React.Fragment key={key}>
      <h4 style={{ marginBottom: '24px', fontWeight: 300 }}>{title}</h4>
      <LineChart style={{marginBottom:'24px'}} width={1240} height={400} data={getChartData()}>
        <CartesianGrid strokeDasharray="3 3" stroke="#90ee90" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey={dataKey} stroke="#999" activeDot={{ r: 8 }} strokeWidth={2} />
      </LineChart>
    </React.Fragment>
  )
}

class BrandDashboardPage extends Component {

  constructor(props) {
    super(props);
    document.title = `Ladle - Dashboard`;
    this.state = {};
  }

  async componentDidMount () {
    console.log({msg:'BrandDashboardPage > componentDidMount', 'props':this.props});
    await this.getTenantMetrics();
  }

  //
  
  async getTenantMetrics() {
    console.log({ msg: 'getTenantMetrics', 'user':this.props.user});
    try {
      const tenantMetrics = await TenantMetricService.get(this.props.user);
      this.setState({ tenantMetrics });
    } catch (err) {
      console.error('getTenantMetrics > error', err);
    }
  }

  //

  render() {

    return (

      <>
        <section className="section-primary" style={{ marginTop: '24px' }}>

          <section className="section-sidebar">
            <SideBar userType='brand' user={this.props.user} tenant={this.props.tenant} />
          </section>

          <section className="section-content">

            {createDataTileRow("weeklyinsights", "Weekly Insights", weeklyinsights)}
            {createDataTileRow("appsrecipes", "Apps & Recipes", appsrecipes)}
            {createDataTileRow("sponsorships", "Campaigns & Sponsorships", sponsorships)}

            <div className="content-title" style={{ marginBottom: '24px' }}>Latest Metrics</div>

            <div className="content-section">
              <div className="metric-container">
                <div className="tile">
                  {createLineChart("followers", "Followers (Last 2 months)", "Followers")}
                  {createLineChart("engagement", "Average Weekly Engagement Rate (Last 2 months)", "Engagement Rate")}
                </div>
              </div>
            </div>

          </section>
        </section>

      </>

    );

  }

};

export default BrandDashboardPage;