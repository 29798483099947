import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp, faListCheck, faRectangleAd, faPlus, faLineChart, faGlobe, faMoneyBill, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import SideBar from '../../components/SideBar';
import logger from "../../utils/log.js";

//css
import '../../css/home.css';
import { Link } from 'react-router-dom';
//


//

const log = logger("pages.creator.dashboard");

//

const getGreeting = (name) => {
  const currentHour = new Date().getHours();
  if (currentHour >= 5 && currentHour < 12) {
    return `Good morning, ${name}!`;
  } else if (currentHour >= 12 && currentHour < 18) {
    return `Good afternoon, ${name}!`;
  } else {
    return `Good evening, ${name}!`;
  }
};

function getChartData() {

  const currentDate = new Date();
  const startDate = new Date();
  startDate.setMonth(currentDate.getMonth() - 2);

  const data = [];
  let currentDatePointer = new Date(startDate);

  let previousFollowers = 17550;

  while (currentDatePointer <= currentDate) {
    const formattedDate = currentDatePointer.toLocaleDateString();
    const followers = previousFollowers + Math.floor(Math.random() * 15000) + 2500;
    previousFollowers = followers;
    const engagementRate = Math.random() * (58 - 25) + 25; // Random engagement rate between 25% and 58%
    data.push({ date: formattedDate, Followers: followers, 'Engagement Rate': engagementRate });
    currentDatePointer.setDate(currentDatePointer.getDate() + 7);
  }

  return data;

}


class CreatorDashboardPage extends Component {

  constructor(props) {
    super(props);
    document.title = `Ladle - Dashboard`;
    this.state = {};
  }

  componentDidMount() {
  }

  render() {

    const chartData = getChartData();
    console.log(chartData);
    const creatorFirstName = 'Zoe';

    return (

      <>
        <section className="section-title"><h2>{getGreeting(creatorFirstName)}</h2></section>

        <section className="section-primary">

          <section className="section-sidebar">
            <SideBar userType='creator' currentCreatorId={this.props.currentCreatorId} user={this.props.user} tenant={this.props.tenant} />
          </section>

          <section className="section-content">

            <div className="content-section">
              <div className="shortcuts">
                <button className="shortcut-button">
                  <div className="shortcut-button-icon">
                    <FontAwesomeIcon icon={faCloudArrowUp} />
                  </div>
                  <Link to='/creator/recipes/create'><span>Upload New Recipe</span></Link>
                </button>
                <button className="shortcut-button">
                  <div className="shortcut-button-icon">
                    <FontAwesomeIcon icon={faListCheck} />
                  </div>
                  <Link to='/creator/recipes'><span>Manage Recipes</span></Link>
                </button>
                <button className="shortcut-button">
                  <div className="shortcut-button-icon">
                    <FontAwesomeIcon icon={faRectangleAd} />
                  </div>
                  <Link to='/creator/marketplace'><span>Manage Brands</span></Link>
                </button>
                <button className="shortcut-button">
                  <div className="shortcut-button-icon">
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                  <span>Add Shortcut</span>
                </button>
              </div>
            </div>

            <div className="content-title" style={{ marginBottom: 24 }}>Weekly Insights</div>

            <div className="content-section">
              <div className="grid-container">
                <div className="insights-grid">
                  <div className="insight-tile">
                    <FontAwesomeIcon style={{ height: 28, color: 'lightgreen', paddingBottom: 8 }} icon={faThumbsUp} />
                    <h3 style={{ fontWeight: 350 }}>Weekly Engagement Rate</h3>
                    <h3 style={{ 'fontSize': 28, 'fontWeight': 500 }}>+35%</h3>
                    <p>Percentage of your followers who actively engage with your content and recipes</p>
                    <button className='outline'>View</button>
                  </div>
                  <div className="insight-tile">
                    <FontAwesomeIcon style={{ height: 28, color: 'lightgreen', paddingBottom: 8 }} icon={faMoneyBill} />
                    <h3 style={{ fontWeight: 350 }}>Views</h3>
                    <h3 style={{ 'fontSize': 28, 'fontWeight': 500 }}>+32%</h3>
                    <p>Weekly views of your recipes and content over the last 7 days</p>
                    <button className='outline'>View</button>
                  </div>
                  <div className="insight-tile">
                    <FontAwesomeIcon style={{ height: 28, color: 'lightgreen', paddingBottom: 8 }} icon={faGlobe} />
                    <h3 style={{ fontWeight: 350 }}>New Followers</h3>
                    <h3 style={{ 'fontSize': 28, 'fontWeight': 500 }}>3,206</h3>
                    <p>New followers that you've gained in the last week.</p>
                    <button className='outline'>View</button>
                  </div>
                  <div className="insight-tile">
                    <FontAwesomeIcon style={{ height: 28, color: 'lightgreen', paddingBottom: 8 }} icon={faLineChart} />
                    <h3 style={{ fontWeight: 350 }}>Earnings Growth</h3>
                    <h3 style={{ 'fontSize': 28, 'fontWeight': 500 }}>+11%</h3>
                    <p>The increase or change in your revenue over the seven days and more.</p>
                    <button className='outline'>View</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-title" style={{ marginBottom: 24 }}>Latest Metrics</div>

            <div className="content-section">
              <div className="metric-container">
                <div className="tile">
                  <h4 style={{ marginBottom: '24px', fontWeight: 300 }}>Followers (Last 2 months)</h4>
                  <LineChart width={1240} height={400} data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#90ee90" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Followers" stroke="#999" activeDot={{ r: 8 }} strokeWidth={2} />
                  </LineChart>

                  <h4 style={{ marginTop: '48px', marginBottom: '24px', fontWeight: 300 }}>Average Weekly Engagement Rate (Last 2 months)</h4>
                  <LineChart width={1240} height={400} data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#90ee90" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Engagement Rate" data stroke="#999" activeDot={{ r: 8 }} strokeWidth={2} />
                  </LineChart>
                </div>
              </div>
            </div>

          </section>
        </section>

      </>

    );

  }

};

export default CreatorDashboardPage;