import config from "../utils/config.js";
import logger from "../utils/log.js";
const fetch = require('node-fetch');

//

const log = logger("services.contentmetadata");

//

const getContentMetadata = async function getContentMetadata (id="", options={}, user) {

  if (!user) return;
  const queryString = new URLSearchParams(options);
  const url = `${config.API_SERVER_URL}/api/v1/contentmetadata/${id}?${queryString}`;

  const request = {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
  };

  try {
    const response = await fetch(url, request);
    const jsonResponse = await response.json();
    console.log({msg:'services.contentmetadata.getContentMetadata.jsonResponse', jsonResponse});
    return jsonResponse;
  } catch (err) {
    throw err;
  }

};

const createContentMetadata = async function createContentMetadata (data, user) {

  console.log('services.contentmetadata.createContentMetadata', data);

  if (!user) return;

  const serverUrl = `${config.API_SERVER_URL}/api/v1/contentmetadata`;
  console.log(`services.contentmetadata.createContentMetadata.url: ${serverUrl}`);

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(serverUrl, request);
    const jsonResponse = await response.json();
    const responseCode = response.status;
    console.log({msg:'services.contentmetadata.createContentMetadata.jsonResponse', responseCode, jsonResponse});

    if (response.status !== 200 && response.status !== 201) return;
    return jsonResponse;

  } catch (err) {
    throw err;
  }

};

class ContentMetadataService {

  static async count (user) {
    console.log('services.contentmetadata.count');
    try {
      const contentMetadata = await getContentMetadata(undefined, {}, user);
      return contentMetadata.length;
    } catch (err) {
      console.error({msg:'services.contentmetadata.count.err', err});
    }
  }

  static async list (options, user) {
    console.log('services.contentmetadata.list');
    try {
      return await getContentMetadata(undefined, options, user);
    } catch (err) {
      console.error({msg:'services.contentmetadata.list.err', err});
      throw err;
    }
  }

  static async get (id, options, user) {
    console.log({msg:'service.contentmetadata.get', user});
    try {
      let contentMetadata = await getContentMetadata(id, options, user);
      return contentMetadata;
    } catch (err) {
      console.error({msg:'services.contentmetadata.get.err', err});
    }
  }

  static async getByRecipeId (recipe_id, user) {
    console.log({msg:'service.contentmetadata.getByRecipeId', recipe_id, user});
    try {
      let contentMetadata = await getContentMetadata(undefined, { recipe_id }, user);
      return contentMetadata;
    } catch (err) {
      console.error({msg:'services.contentmetadata.getByRecipeId.err', err});
    }
  }

  static async getByContentId (content_id, user) {
    try {
      let contentMetadata = await getContentMetadata(undefined, { content_id }, user);
      return contentMetadata;
    } catch (err) {
      console.error({msg:'services.contentmetadata.getByContentId.err', err});
    }
  }

  static async create(data, user) {
    try {
      log.debug({ msg: 'services.contentmetadata.create', data });
      let response = await createContentMetadata(data, user);
      log.debug({ msg: 'services.contentmetadata.create', response });
      return response;
    } catch (err) {
      log.error({ msg: 'services.contentmetadata.create > error', err });
      throw err;
    }
  }

}

export default ContentMetadataService;
