import React, { Component } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import BrandApplianceRow from "../../components/brand/ApplianceRow.js";
import ApplianceTypeService from '../../services/appliancetype.js';

// css
import "../../css/sponsorships.css";
import "../../css/home.css";
import { faCircle, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


//

class BrandAppliancePage extends Component {

  constructor(props) {
  
    super(props);

    document.title = `Ladle - Manage Appliances`;

    this.state = {
      isLoading: true,
      applianceTypes: undefined,
      page: 0,
      rowsPerPage: 10,
      user: props.user,
      tenant: props.tenant,
    };

  }
  
  //

  async componentDidMount() {
    const applianceTypes = await this.getApplianceTypes({'tenant':this.state.tenant._id});
    this.setState({applianceTypes, isLoading: false, error: undefined });
  }

  //

  async getApplianceTypes (options) {
    try {
      return await ApplianceTypeService.list(options, this.props.user);
    } catch (err) {
      console.error('getRecipes > error', err);
      this.setState({ applianceTypes: undefined, isLoading: false, error: err.message, options });
    }
  }    

  updateApplianceType = async (userAppliance, data) => {
    return await ApplianceTypeService.update(userAppliance._id, data, this.props.user);
  }

  setEnabledState = async (applianceType, enabled) => {
    console.log('setEnabledState', applianceType);
    const updatedApplianceType = await this.updateApplianceType(applianceType, { enabled });
    const applianceTypes = await this.getApplianceTypes({'tenant':this.state.tenant._id});
    let snackbarText = `Appliance Type ${updatedApplianceType.enabled ? 'enabled successfully.' : 'disabled successfully.'}`;
    this.setState({applianceTypes, snackbarText});
  }
 
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  //

  render() {

    const { applianceTypes, user, tenant, page, rowsPerPage  } = this.state;
    if (!applianceTypes) return;

    
    console.log({msg:'applianceTypes', applianceTypes});
    let orderedApplianceTypes = applianceTypes.sort((a,b) => a.order - b.order);



    const addApplianceButton = (applianceTypes.length === 0) ? <></> : (
      <div style={{display:'flex', alignContent:'baseline'}}>
        <button className="outline green-white" onClick={this.showAddAppliancesModal} style={{ borderRadius: '20px', minWidth: '240px', minHeight: '64px', fontSize: '12pt'}}><FontAwesomeIcon icon={faCirclePlus} style={{ marginRight: '12px', height: '20px', width: '20px' }} />Add Appliance Type</button>
      </div>
    );

    console.log({ msg: "render", applianceTypes});

    return (
      <>

        <div className="content-header">
          <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
            <div className="content-title">Appliances</div>
            <div className="content-description">Add and manage your appliances & devices.</div>
          </div>
          {addApplianceButton}
        </div>

        <TableContainer
          style={{
            border: "1px solid #ddd",
            borderRadius: 15,
            padding: 12,
            backgroundColor: "#fdfdfd",
          }}
          component={Paper}
        >
          <Table sx={{ minWidth: "1240px" }} aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left"><b>Model</b></TableCell>
                <TableCell align="left"><b>Name</b></TableCell>
                <TableCell align="left"><b>Type</b></TableCell>
                <TableCell align="left"><b>Capabilities</b></TableCell>
                <TableCell align="left"><b>Active Count</b></TableCell>
                <TableCell align="left"><b>Status</b></TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {orderedApplianceTypes.map((applianceType) => (
                <BrandApplianceRow key={applianceType.model} applianceType={applianceType} tenant={tenant} onEnableClick={() => this.setEnabledState(applianceType, !applianceType.enabled)} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={applianceTypes.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </>
    );
  }
}

export default BrandAppliancePage;
