import { json } from "react-router-dom";
import config from "../utils/config.js";
const fetch = require('node-fetch');

//

class GuestService {

    static async login() {
        try {

            console.log('services.auth.guest');

            const serverUrl = `${config.AUTH_SERVER_URL}${config.AUTH_GUEST_ENDPOINT}`;
            console.log(serverUrl);

            const request = {
                method: 'GET',
            };

            const response = await fetch(serverUrl, request);
            console.log(response);

            const jsonResponse = await response.json();
            console.log('json response', jsonResponse)
            const responseCode = response.status;
            console.log({ msg: 'services.user.Guest.jsonResponse', request, responseCode, jsonResponse });

            if ((responseCode == 200 || responseCode == 201) && jsonResponse.user) {
                return { 'user': jsonResponse.user };
            } else {
                return { 'error': jsonResponse.error };
            }

        } catch (error) {
            console.log(error)
            return { error }
        }
    }

}

export default GuestService;
