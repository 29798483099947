import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import SponsorshipRow from '../../components/creator/CreatorSponsorshipRow';
import BrandData from '../../data/brandData';
import SponsorshipData from '../../data/sponsorshipData';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

import logger from "../../utils/log.js";

//

const log = logger("pages.creator.sponsorships");

//
class CreatorSponsorshipPage extends Component {

  constructor(props) {

    super(props);

    document.title = `Ladle - Sponsorships`;

    let tableData = [];
    for (const [key, value] of Object.entries(SponsorshipData)) {
      const obj = { campaigns: value, brandData: BrandData[key] };
      tableData.push(obj);
    }

    this.state = {
      isLoading: true,
      tableData,
    };

  }

  componentDidMount() {
  }

  //

  render() {

    log.debug('render');

    const { tableData } = this.state;

    const tableHtml = tableData.map((brand) => {

      // limit/randomize sponsorships displayed
      if ((Math.random() * 3) > 1) return <></>;

      const brandId = brand.brandData.id;
      const brandWebsite = brand.brandData.url;
      const imageUrl = '/brands/' + brandId + '.png';

      const campaignHtml = brand.campaigns.map(campaign => {
        campaign.brandData = brand.brandData;
        log.debug({ campaign });
        return (<SponsorshipRow key={campaign.id} campaign={campaign} />)
      });

      // let hue = Math.floor(Math.random() * 360);
      // let tableColor = `hsla(${hue}, 10%, 85%)`;
      // let headerColor = `hsla(${hue}, 10%, 95%)`;

      let headerColor = `hsl(52, 70%, 85%)`;
      let tableColor = `rgb(250, 250, 250)`;

      return (
        <>
          <TableCell style={{ border: 'none', paddingBottom: '24px', display: 'inline-flex', alignItems: 'center' }}><img style={{ maxWidth: '120px', marginRight:'24px' }} src={imageUrl} /><Link to={brandWebsite}><FontAwesomeIcon color='#666' style={{ height: '12px', paddingLeft: '4px' }} icon={faUpRightFromSquare} /></Link></TableCell>
          <TableContainer key={brandId} sx={{ backgroundColor: tableColor, marginBottom: '72px', borderRadius: '12px', boxShadow: '0px 0.5px 3px 0.2px rgba(255,215,0,0.4)' }} component={Paper}>
            <Table sx={{ minWidth: 1040 }} aria-label="collapsible table">
              <TableHead sx={{ backgroundColor: headerColor }}>
                <TableRow>
                  <TableCell style={{ paddingTop: '10px', paddingBottom: '10px', border: 'none', width: '20%', paddingLeft: '32px', fontSize: '8pt', fontWeight: 500, color: '#333' }}>CAMPAIGN</TableCell>
                  <TableCell style={{ paddingTop: '10px', paddingBottom: '10px', border: 'none', width: '16%', fontSize: '8pt', fontWeight: 500, color: '#333' }}>TYPE</TableCell>
                  <TableCell style={{ paddingTop: '10px', paddingBottom: '10px', border: 'none', width: '46%', fontSize: '8pt', fontWeight: 500, color: '#333' }}>DESCRIPTION</TableCell>
                  <TableCell style={{ paddingTop: '10px', paddingBottom: '10px', border: 'none', width: '14%', fontSize: '8pt', fontWeight: 500, color: '#333' }}>COMPENSATION</TableCell>
                  <TableCell style={{ paddingTop: '10px', paddingBottom: '10px', border: 'none', width: '4%', fontSize: '8pt', fontWeight: 500, color: '#333' }}></TableCell>
                </TableRow>
              </TableHead>
              {campaignHtml}
            </Table>
          </TableContainer>
        </>
      );

    });

    return (

      <>

        <div className="content-title">Sponsorships & Deal Opportunities</div>
        <div className="content-description">View available opportunities to connect and earn with manufacturers & brands.</div>

        <div className="content-section">
          <div className="tile">
            {tableHtml}
          </div>
        </div>
      </>

    );

  }

};

export default CreatorSponsorshipPage;