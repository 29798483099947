import config from "../utils/config.js";

//import fetch from 'node-fetch';
const fetch = require('node-fetch');

//
const addInteraction = async function addInteraction ({_id, updateType, data}, user) {

  if (!user) return {};

  let url = `${config.API_SERVER_URL}/api/v1/recipeinteractions`;

  const requestData = {
    _id,
    updateType,
    data
  };

  const request = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
    body: JSON.stringify(requestData),
  };

  try {
    const response = await fetch(url, request);
    const status = response.status;
    //console.log({msg:'services.recipemetrics.addInteraction.status', status});
    if (status !== 200 && status !== 201) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || 'An error occurred.');
      // return
    };
    const jsonResponse = await response.json();
    //console.log({msg:'services.recipemetrics.addInteraction.jsonResponse', jsonResponse});
    return jsonResponse || {};
  } catch (err) {
    console.log(err);
    throw err;
  }

};

class RecipeInteractionService {

  static async add ({_id, updateType, data}, user) {
    console.log(_id, updateType, data, "decbdcu")
    try {
      const recipeInteraction = await addInteraction({_id, updateType, data}, user);
      if (recipeInteraction) return recipeInteraction;
      return;
    } catch (err) {
      console.error('services.recipeinteraction.add.err');
      console.log(err);
    }
  }

}

export default RecipeInteractionService;