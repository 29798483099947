const sponsorshipData = {
    allclad: [
        {
            id: 'allclad-1',
            campaign_name: "Cookware Creations",
            campaign_description: "Show off your culinary skills using All-Clad cookware and create delicious recipes that inspire others to cook at home.",
            incentives: 'Free product samples, brand ambassador program.',
            requirements: '2 social media posts featuring recipes using All-Clad products.',
            campaign_type: 'Product Launch',
            optedin: true,
        },
        {
            id: 'allclad-2',
            campaign_name: "Master of Multi-Cooking",
            campaign_description: "Demonstrate the versatility of All-Clad's multi-cooker by preparing a variety of recipes, from slow-cooked comfort food to quick one-pot wonders.",
            incentives: 'Free product samples, brand ambassador program.',
            requirements: '2 social media posts featuring recipes using All-Clad products.',
            campaign_type: 'Product Launch',
            optedin: true,
        }
    ],
    kitchenaid: [
        {
            id: 'kitchenaid-1',
            campaign_name: "Bake It Beautiful",
            campaign_description: "Whip up stunning baked goods using KitchenAid appliances and showcase your artistic flair in the kitchen.",
            incentives: 'Product sponsorship, discounts for followers.',
            requirements: '1 YouTube video featuring a KitchenAid recipe tutorial.',
            campaign_type: 'Seasonal',
            optedin: true,
        },
        {
            id: 'kitchenaid-2',
            campaign_name: "Mix & Match Magic",
            campaign_description: "Create unique recipes with KitchenAid stand mixers and attachments, combining unexpected flavors and textures for delicious results.",
            incentives: 'Product sponsorship, discounts for followers.',
            requirements: '1 YouTube video featuring a KitchenAid recipe tutorial.',
            campaign_type: 'Seasonal',
            optedin: false,
        },
        {
            id: 'kitchenaid-3',
            campaign_name: "Blend It Bold",
            campaign_description: "Invent innovative smoothie recipes and refreshing summer drinks with KitchenAid blenders that pack a punch of flavor.",
            incentives: 'Product sponsorship, discounts for followers.',
            requirements: '1 YouTube video featuring a KitchenAid recipe tutorial.',
            campaign_type: 'Seasonal',
            optedin: false,
        },
    ],
    unbranded: [
        {
            id: 'unbranded-1',
            campaign_name: "Bake It Beautiful",
            campaign_description: "Whip up stunning baked goods using our appliances and showcase your artistic flair in the kitchen.",
            incentives: 'Product sponsorship, discounts for followers.',
            requirements: '1 YouTube shoutout featuring a recipe tutorial .',
            campaign_type: 'Seasonal',
            optedin: true,
        },
        {
            id: 'unbranded-2',
            campaign_name: "Mix & Match Magic",
            campaign_description: "Create unique recipes with our stand mixers and attachments, combining unexpected flavors and textures for delicious results.",
            incentives: 'Product sponsorship, discounts for followers.',
            requirements: '4 TikTok videos featuring a recipe tutorial and SmartRecipe.',
            campaign_type: 'Seasonal',
            optedin: false,
        },
        {
            id: 'unbranded-3',
            campaign_name: "Blend It Bold",
            campaign_description: "Invent innovative smoothie recipes and refreshing summer drinks with our blenders that pack a punch of flavor.",
            incentives: 'Product sponsorship, discounts for followers.',
            requirements: '1 YouTube video featuring a recipe tutorial.',
            campaign_type: 'Seasonal',
            optedin: false,
        },
        {
            id: "unbranded-4",
            campaign_name: "Baking Brilliance",
            campaign_description: "Unleash your baking skills with our innovative mixers and baking appliances, and share delightful recipes and tips with your audience.",
            incentives: "Get access to our latest baking appliances and receive compensation for sharing your baking journey.",
            requirements: "Must have a passion for baking and a strong presence in the food and baking community.",
            campaign_type: "Content Creation",
            optedin: true,
            status: 'draft',
        },
        {
            id: "unbranded-5",
            campaign_name: "Effortless Meal Prep",
            campaign_description: "Experience hassle-free meal prep with our efficient kitchen appliances and inspire others with easy, nutritious recipes.",
            incentives: "Receive a set of our kitchen appliances and be compensated for creating meal prep content.",
            requirements: "Must have a focus on healthy eating and an engaged audience interested in meal prepping.",
            campaign_type: "Product Review",
            optedin: true,
            status: 'complete',
        },
    ],
    oxo: [
        {
            id: 'oxo-1',
            campaign_name: "Kitchen Hacks Unleashed",
            campaign_description: "Reveal your favorite kitchen hacks using OXO tools and gadgets, making cooking more efficient and enjoyable for everyone.",
            incentives: 'Product testing, influencer discounts.',
            requirements: '3 social media posts highlighting OXO tools in action.',
            campaign_type: 'Social Cause',
            optedin: false,
        },
    ],
    instantpot: [
        {
            id: 'instantpot-1',
            campaign_name: "Effortless Meal Prep",
            campaign_description: "Simplify meal preparation with Instant Pot's multi-cooker and share time-saving recipes that make busy days a breeze.",
            incentives: 'Sponsored content, product giveaways.',
            requirements: '1 recipe video showcasing a dish prepared in an Instant Pot.',
            campaign_type: 'Brand Awareness',
            optedin: true,
        },
        {
            id: 'instantpot-2',
            campaign_name: "Instant Pot Innovations",
            campaign_description: "Experiment with unique recipes and techniques using Instant Pot's advanced features, inspiring others to get creative in the kitchen.",
            incentives: 'Sponsored content, product giveaways.',
            requirements: '1 recipe video showcasing a dish prepared in an Instant Pot.',
            campaign_type: 'Brand Awareness',
            optedin: true,
        }
    ],
    lecreuset: [
        {
            id: 'lecreuset-1',
            campaign_name: "Colorful Culinary Creations",
            campaign_description: "Create visually stunning dishes using Le Creuset cookware, celebrating the vibrant colors and exceptional quality of their products.",
            incentives: 'Product samples, brand ambassador program.',
            requirements: '1 social media post featuring Le Creuset cookware.',
            campaign_type: 'Brand Awareness',
            optedin: true,
        },
        {
            id: 'lecreuset-2',
            campaign_name: "From Oven to Table",
            campaign_description: "Prepare beautiful, oven-to-table meals using Le Creuset Dutch ovens and bakeware, showcasing the elegance and practicality of their designs.",
            incentives: 'Product samples, brand ambassador program.',
            requirements: '1 social media post featuring Le Creuset cookware.',
            campaign_type: 'Brand Awareness',
            optedin: true,
        },
    ],
    calphalon: [
        {
            id: 'calphalon-1',
            campaign_name: "Everyday Gourmet",
            campaign_description: "Elevate everyday cooking with Calphalon's high-quality cookware, inspiring others to embrace a gourmet approach in their own kitchens.",
            incentives: 'Product samples, sponsored content.',
            requirements: '1 blog post featuring Calphalon cookware and recipes.',
            campaign_type: 'Retargeting',
            optedin: false,
        },
    ],
    williamssonoma: [
        {
            id: 'williamssonoma',
            campaign_name: "Kitchen Must-Haves",
            campaign_description: "Highlight your favorite kitchen essentials from Williams Sonoma, demonstrating their usefulness and versatility in various recipes.",
            incentives: 'Product discounts, exclusive events.',
            requirements: '2 blog posts featuring Williams Sonoma products and recipes.',
            campaign_type: 'Social Cause',
            optedin: false,
        },
        {
            id: 'williamssonoma',
            campaign_name: "Seasonal Sensations",
            campaign_description: "Create seasonal recipes using Williams Sonoma's specialty ingredients and share the joy of cooking with fresh, seasonal produce.",
            incentives: 'Product discounts, exclusive events.',
            requirements: '2 blog posts featuring Williams Sonoma products and recipes.',
            campaign_type: 'Social Cause',
            optedin: false,
        },
    ],
    samsung: [
        {
            id: 'samsung-1',
            campaign_name: "Smart Kitchen Innovations",
            campaign_description: "Explore the cutting-edge features of Samsung's smart kitchen appliances and show how they make cooking easier and more enjoyable.",
            incentives: 'Product discounts, early access to new releases.',
            requirements: '1 social media post featuring a Samsung appliance in a home setting.',
            campaign_type: 'Giveaway',
            optedin: false,
        },
    ],
    lg: [
        {
            id: 'lg1',
            campaign_name: "Effortless Cooking Experience",
            campaign_description: "Experience the convenience and precision of LG's cooking appliances and inspire others to enjoy stress-free cooking adventures.",
            incentives: 'Product samples, brand ambassador program.',
            requirements: '2 social media posts showcasing LG appliances in a modern home.',
            campaign_type: 'Brand Awareness',
            optedin: false,
            status: 'active',
        },
        {
            id: "lg2",
            campaign_name: "Smart Kitchen Innovations",
            campaign_description: "Explore LG's smart kitchen appliances and showcase how they revolutionize cooking with advanced features and seamless connectivity.",
            incentives: "Receive a suite of LG smart kitchen appliances and compensation for creating engaging content.",
            requirements: "Must have a strong interest in technology and smart home solutions, with a tech-savvy audience.",
            campaign_type: "Product Promotion",
            optedin: true,
            status: 'active',
        },
        {
            id: "lg3",
            campaign_name: "Baking Brilliance with LG",
            campaign_description: "Unleash your baking skills with LG's innovative baking appliances, and share delightful recipes and tips with your audience.",
            incentives: "Get access to LG's latest baking appliances and receive compensation for sharing your baking journey.",
            requirements: "Must have a passion for baking and a strong presence in the food and baking community.",
            campaign_type: "Content Creation",
            optedin: true,
            status: 'draft',
        },
        {
            id: "lg4",
            campaign_name: "Effortless Meal Prep with LG",
            campaign_description: "Experience hassle-free meal prep with LG's efficient kitchen appliances and inspire others with easy, nutritious recipes.",
            incentives: "Receive a set of LG kitchen appliances and be compensated for creating meal prep content.",
            requirements: "Must have a focus on healthy eating and an engaged audience interested in meal prepping.",
            campaign_type: "Product Review",
            optedin: true,
            status: 'complete',
        },
        {
            id: "lg5",
            campaign_name: "Tech-Forward Kitchen Inspiration",
            campaign_description: "Embrace the future of cooking with LG's cutting-edge kitchen technologies, and share innovative recipes and ideas with your followers.",
            incentives: "Get the opportunity to experience LG's range of kitchen technologies and receive compensation for creating inspiring content.",
            requirements: "Must have knowledge of various kitchen technologies and the ability to create engaging and informative content.",
            campaign_type: "Content Creation",
            optedin: true,
            status: 'complete',
        },
        {
            id: "lg6",
            campaign_name: "Kitchen Transformation with LG",
            campaign_description: "Embark on a kitchen makeover journey with LG appliances, documenting and sharing the process of transforming your kitchen space.",
            incentives: "Receive a full kitchen makeover with LG appliances and compensation for documenting and sharing your kitchen transformation.",
            requirements: "Must have a kitchen in need of an upgrade and the ability to create captivating before-and-after content.",
            campaign_type: "Brand Collaboration",
            optedin: true,
            status: 'complete'
        }
    ],
    whirlpool: [
        {
            id: 'whirlpool',
            campaign_name: "Sustainable Cooking Solutions",
            campaign_description: "Promote eco-friendly cooking practices using Whirlpool appliances that prioritize sustainability, demonstrating how small changes can make a big impact.",
            incentives: 'Product discounts, loyalty rewards program.',
            requirements: '1 video review featuring a Whirlpool appliance in a real-life usage scenario.',
            campaign_type: 'Brand Awareness',
            optedin: false,
        },
        {
            id: 'whirlpool2',
            campaign_name: "Effortless Cooking Experience",
            campaign_description: "Experience the convenience and precision of Whirlpool's cooking appliances and inspire others to enjoy stress-free cooking adventures.",
            incentives: 'Product samples, brand ambassador program.',
            requirements: '2 social media posts showcasing Whirlpool appliances in a modern home.',
            campaign_type: 'Brand Awareness',
            optedin: false,
            status: 'active',
        },
        {
            id: "whirlpool3",
            campaign_name: "Smart Kitchen Innovations",
            campaign_description: "Explore Whirlpool's smart kitchen appliances and showcase how they revolutionize cooking with advanced features and seamless connectivity.",
            incentives: "Receive a suite of Whirlpool smart kitchen appliances and compensation for creating engaging content.",
            requirements: "Must have a strong interest in technology and smart home solutions, with a tech-savvy audience.",
            campaign_type: "Product Promotion",
            optedin: true,
            status: 'active',
        },
        {
            id: "whirlpool4",
            campaign_name: "Baking Brilliance with Whirlpool",
            campaign_description: "Unleash your baking skills with Whirlpool's innovative baking appliances, and share delightful recipes and tips with your audience.",
            incentives: "Get access to Whirlpool's latest baking appliances and receive compensation for sharing your baking journey.",
            requirements: "Must have a passion for baking and a strong presence in the food and baking community.",
            campaign_type: "Content Creation",
            optedin: true,
            status: 'draft',
        },
        {
            id: "whirlpool5",
            campaign_name: "Effortless Meal Prep with Whirlpool",
            campaign_description: "Experience hassle-free meal prep with Whirlpool's efficient kitchen appliances and inspire others with easy, nutritious recipes.",
            incentives: "Receive a set of Whirlpool kitchen appliances and be compensated for creating meal prep content.",
            requirements: "Must have a focus on healthy eating and an engaged audience interested in meal prepping.",
            campaign_type: "Product Review",
            optedin: true,
            status: 'complete',
        },
        {
            id: "Whirlpool5",
            campaign_name: "Tech-Forward Kitchen Inspiration",
            campaign_description: "Embrace the future of cooking with Whirlpool's cutting-edge kitchen technologies, and share innovative recipes and ideas with your followers.",
            incentives: "Get the opportunity to experience Whirlpool's range of kitchen technologies and receive compensation for creating inspiring content.",
            requirements: "Must have knowledge of various kitchen technologies and the ability to create engaging and informative content.",
            campaign_type: "Content Creation",
            optedin: true,
            status: 'complete',
        },
        {
            id: "Whirlpool6",
            campaign_name: "Kitchen Transformation with Whirlpool",
            campaign_description: "Embark on a kitchen makeover journey with Whirlpool appliances, documenting and sharing the process of transforming your kitchen space.",
            incentives: "Receive a full kitchen makeover with Whirlpool appliances and compensation for documenting and sharing your kitchen transformation.",
            requirements: "Must have a kitchen in need of an upgrade and the ability to create captivating before-and-after content.",
            campaign_type: "Brand Collaboration",
            optedin: true,
            status: 'complete'
        }
    ],
    bosch: [
        {
            id: 'bosch',
            campaign_name: "Efficiency Redefined",
            campaign_description: "Embrace the efficiency and reliability of Bosch kitchen appliances, sharing time-saving tips and recipes for busy individuals.",
            incentives: 'Product discounts, loyalty program.',
            requirements: '1 video demonstration showcasing a Bosch appliance\'s advanced features.',
            campaign_type: 'Product Launch',
            optedin: false,
        },
    ],
    electrolux: [
        {
            id: 'electrolux',
            campaign_name: "Kitchen Style & Function",
            campaign_description: "Combine style and functionality with Electrolux's elegant and efficient kitchen appliances, inspiring others to create beautiful meals effortlessly.",
            incentives: 'Product discounts, exclusive promotions.',
            requirements: '2 social media posts featuring Electrolux appliances in a modern and sustainable home.',
            campaign_type: 'Seasonal',
            optedin: false,
        },
    ],
    maytag: [
        {
            id: 'maytag',
            campaign_name: "Mighty Appliances for Modern Cooks",
            campaign_description: "Unleash the power of Maytag appliances and demonstrate how their durability and performance can handle any cooking challenge.",
            incentives: 'Product warranties, loyalty rewards program.',
            requirements: '1 testimonial video showcasing a Maytag appliance\'s reliability and performance.',
            campaign_type: 'User Content',
            optedin: false,
        },
    ],
    ge: [
        {
            id: 'ge',
            campaign_name: "Modern Kitchen Marvels",
            campaign_description: "Experience the latest kitchen innovations from GE and showcase their advanced features, making cooking a breeze for everyone.",
            incentives: 'Product discounts, early access to new features.',
            requirements: '1 social media post showcasing a GE appliance in a smart home environment.',
            campaign_type: 'Contest',
            optedin: false,
        },
    ],
    frigidaire: [
        {
            id: 'frigidaire',
            campaign_name: "Fresh & Fabulous",
            campaign_description: "Emphasize the freshness and versatility of Frigidaire appliances, creating delicious recipes that celebrate quality ingredients.",
            incentives: 'Product discounts, loyalty rewards program.',
            requirements: '2 social media posts featuring Frigidaire appliances in real-life usage scenarios.',
            campaign_type: 'Contest',
            optedin: false,
        },
    ],
    haier: [
        {
            id: 'haier',
            campaign_name: "Compact Kitchen Adventures",
            campaign_description: "Explore the possibilities of Haier's compact kitchen appliances and share recipes tailored for small spaces, making cooking accessible for all.",
            incentives: 'Product giveaways, influencer discounts.',
            requirements: '1 video demonstrating the versatility and space-saving features of a Haier appliance.',
            campaign_type: 'Product Launch',
            optedin: true,
        },
    ],
    sharp: [
        {
            id: 'sharp',
            campaign_name: "Precision in Every Cut",
            campaign_description: "Highlight the exceptional sharpness and precision of Sharp knives, demonstrating how they enhance cooking techniques and elevate dishes.",
            incentives: 'Product samples, exclusive product trials.',
            requirements: '2 social media posts showcasing Sharp appliances in a futuristic home setup.',
            campaign_type: 'Brand Campaign',
            optedin: false,
        },
    ],
    miele: [
        {
            id: 'miele',
            campaign_name: "Luxury Cooking Experiences",
            campaign_description: "Indulge in luxury cooking with Miele appliances and create extraordinary dishes that showcase the brand's commitment to perfection.",
            incentives: 'Product warranties, exclusive events.',
            requirements: '1 testimonial video highlighting the exceptional performance of a Miele appliance.',
            campaign_type: 'Brand Campaign',
            optedin: true,
        },
    ],
    siemens: [
        {
            id: 'siemens',
            campaign_name: "Tech-Forward Kitchen",
            campaign_description: "Discover the innovative features of Siemens kitchen appliances and inspire others to embrace a tech-forward approach to cooking.",
            incentives: 'Product discounts, loyalty program.',
            requirements: '2 social media posts showcasing Siemens appliances in a sustainable and eco-friendly home.',
            campaign_type: 'Giveaway',
            optedin: false,
        },
    ],
    microplane: [
        {
            id: 'microplane',
            campaign_name: "Grate It Great",
            campaign_description: "Demonstrate the superior grating capabilities of Microplane tools and create recipes that highlight the impact of finely grated ingredients.",
            incentives: 'Product giveaways, influencer discounts.',
            requirements: '2 social media posts featuring Microplane tools in unique culinary creations.',
            campaign_type: 'Social Cause',
            optedin: false,
        },
    ],
    victorinox: [
        {
            id: 'victorinox',
            campaign_name: "Swiss Precision in the Kitchen",
            campaign_description: "Showcase the precision and reliability of Victorinox knives, revealing how they elevate cooking techniques and deliver exceptional results.",
            incentives: 'Product discounts, loyalty rewards program.',
            requirements: '1 video showcasing the versatility and precision of a Victorinox knife.',
            campaign_type: 'Seasonal',
            optedin: false,
        },
    ],
    chefn: [
        {
            id: 'chefn',
            campaign_name: "Innovative Kitchen Gadgets",
            campaign_description: "Explore the fun and functionality of Chef'n gadgets, incorporating their unique tools into creative recipes that spark joy in the kitchen.",
            incentives: 'Product giveaways, influencer discounts.',
            requirements: '3 social media posts featuring Chef\'n tools in creative culinary preparations.',
            campaign_type: 'Brand Awareness',
            optedin: true,
        },
    ],
    lodge: [
        {
            id: 'lodge',
            campaign_name: "Cast Iron Magic",
            campaign_description: "Unleash the potential of Lodge cast iron cookware and create recipes that celebrate the exceptional heat retention and versatility of these classic pieces.",
            incentives: 'Product discounts, loyalty program.',
            requirements: '1 recipe video showcasing a dish prepared using Lodge cast iron cookware.',
            campaign_type: 'Seasonal',
            optedin: true,
        }
    ],
};

export default sponsorshipData;