const languageOptionsObj = {
  'reset': { id: 'reset', label: 'Reset' },
  // 'english': { id: 'english', label: 'English' },
  'spanish' : { id: 'spanish', label: 'Spanish' },
  'french' : { id: 'french', label: 'French' },
  'german' : { id: 'german', label: 'German' },
  'korean' : { id: 'korean', label: 'Korean' },
};

const languageOptions = [
  { id: 'reset', label: 'Reset' },
  // { id: 'english', label: 'English' },
  { id: 'spanish', label: 'Spanish' },
  { id: 'french', label: 'French' },
  { id: 'german', label: 'German' },
  { id: 'korean', label: 'Korean' },
];

//

const useAppliancesOptionsObj = {
  'reset': { id: 'reset', label: 'Reset' },
  'use-my-Appliances': { id: 'my', label: 'Use my appliances' },


};

const useAppliancesOption = [
  { id: 'reset', label: 'Reset' },
  { id: 'rice-cooker', label: 'KitchenAid Grain and Rice Cooker' }, 
  { id: 'wall-oven', label: 'GE Profile™ 30" Stainless Steel Single Electric Wall Oven' },
  { id: '5.3-u', label: 'The Maytag 5.3 cu. ft. Steam Clean Electric Range (Model MER4800PZ)' },
];

const temperatureUnitOptionsObj = {
  'reset': { id: 'reset', label: 'Reset' },
  'celsius': { id: 'c', label: 'Celsius' },
  'fahrenheit' : { id: 'f', label: 'Fahrenheit' },
};

const temperatureUnitOptions = [
  { id: 'reset', label: 'Reset' },
  { id: 'c', label: 'Celsius' },
  { id: 'f', label: 'Fahrenheit' },
];

//

const measurementUnitOptionsObj = {
  'reset': { id: 'reset', label: 'Reset' },
  'metric': { id: 'metric', label: 'Metric' },
  'imperial' : { id: 'imperial', label: 'Imperial' },
};

const measurementUnitOptions = [
  { id: 'reset', label: 'Reset' },
  { id: 'metric', label: 'Metric' },
  { id: 'imperial', label: 'Imperial' },
];

const applianceModes = [
  { id: 'airfry', label: 'Air-Fry' },
  { id: 'bake', label: 'Bake' },
  { id: 'baking', label: 'Baking' },
  { id: 'broil', label: 'Broiling' },
];

const applianceModesObj = {
  'airfry': { id: 'airfry', label: 'Air-Fry' },
  'bake' : { id: 'bake', label: 'Bake' },
  'baking' : { id: 'baking', label: 'Baking' },
  'broil' : { id: 'broil', label: 'Broiling' },
};

//

const dietOptionsObj = {
  'reset': { id: 'reset', label: 'Reset' },
  'vegetarian': { id: 'vegetarian', label: 'Vegetarian' },
  'vegan' : { id: 'vegan', label: 'Vegan' },
  'keto' : { id: 'keto', label: 'Keto' },
  'paleo': { id: 'paleo', label: 'Paleo' },
  'dairy-free': { id: 'dairy-free', label: 'Dairy Free' },
  'gluten-free': { id: 'gluten-free', label: 'Gluten Free' }
};

const dietOptions = [
  { id: 'reset', label: 'Reset' },
  { id: 'vegetarian', label: 'Vegetarian' },
  { id: 'vegan', label: 'Vegan' },
  { id: 'keto', label: 'Keto' },
  { id: 'paleo', label: 'Paleo' },
  { id: 'dairy-free', label: 'Dairy Free' },
  { id: 'gluten-free', label: 'Gluten Free' },
];

//

const servingOptionsObj = {
  'reset': { id: 'reset', label: 'Reset' },
  '1': { id: 1,  label: '1' },
  '2': { id: 2, label: '2' },
  '3': { id: 3, label: '3' },
  '4': { id: 4, label: '4' },
  '5': { id: 5, label: '5' },
  '6': { id: 6, label: '6' },
  '7': { id: 7, label: '7' },
  '8': { id: 8, label: '8' },
  '9': { id: 9, label: '9' },
  '10': { id: 10, label: '10' },
  '12': { id: 12, label: '12' },
  '16': { id: 16, label: '16' },
  '24': { id: 24, label: '24' },
};

const servingOptions = [
  { id: 'reset', label: 'Reset' },
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
  { id: 5, label: '5' },
  { id: 6, label: '6' },
  { id: 7, label: '7' },
  { id: 8, label: '8' },
  { id: 9, label: '9' },
  { id: 10, label: '10' },
  { id: 12, label: '12' },
  { id: 16, label: '16' },
  { id: 24, label: '24' },
];

//

export {
  languageOptions,
  languageOptionsObj,
  servingOptions,
  servingOptionsObj,
  temperatureUnitOptions,
  temperatureUnitOptionsObj,
  measurementUnitOptions,
  measurementUnitOptionsObj,
  dietOptions,
  dietOptionsObj,
  applianceModes,
  applianceModesObj,
  useAppliancesOptionsObj,
  useAppliancesOption
};