import config from "../utils/config.js";
import logger from "../utils/log.js";
const fetch = require('node-fetch');

//

const log = logger("services.smartrecipe");

//

const createSmartRecipe = async function createSmartRecipe(data, user) {

  console.log('createSmartRecipe', data);

  if (!user) return;

  const serverUrl = `${config.API_SERVER_URL}/api/v1/smartrecipes`;
  console.log(`SmartRecipeService.createSmartRecipe.url: ${serverUrl}`);

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${user.token}`
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(serverUrl, request);
    const jsonResponse = await response.json();
    const responseCode = response.status;
    console.log({ msg: 'SmartRecipeService.createSmartRecipe.jsonResponse', request, responseCode, jsonResponse });

    if (response.status !== 200 && response.status !== 201) return;
    return jsonResponse;

  } catch (err) {
    //throw err;
    return;
  }

};

const modifyRecipe = function modifyRecipe(recipe) {

  // set properties if missing
  recipe.cuisines = recipe.cuisines || [];

  // clean-up description
  if (!recipe.description) recipe.description = "";

  return recipe;

}

class SmartRecipeService {

  static async create(data, user) {
    try {
      log.debug({ msg: 'services.smartrecipe.create', data, user });
      let recipe = await createSmartRecipe(data, user);
      recipe = recipe ? modifyRecipe(recipe) : undefined;
      return recipe;
    } catch (err) {
      log.error({ msg: 'services.smartrecipe.create > error', err });
      throw err;
    }
  }
  static async updateRecipetoPersonalized(id, email) {
    
  }
// Define the addFeedback method within your component
static async addFeedback(recipeId, feedback) {
  try {
      // Construct the API URL
      const url = `${config.API_SERVER_URL}/api/v1/recipes/${recipeId}/feedback`;
      
      // Retrieve the authentication token from localStorage
      const authToken = localStorage.getItem('userToken');
      
      // Set up the request headers
      const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
      };

      // Make the API call to submit feedback
      const response = await fetch(url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
              feedback: feedback
          })
      });

      // Parse the JSON response
      const data = await response.json();

      // Check if the response indicates a success
      if (response.status === 200 || response.status === 201) {
          console.log("Feedback submitted successfully:", data);
          return data;
      } else {
          console.log("Failed to submit feedback:", data);
          throw new Error(data.error || 'Failed to submit feedback');
      }
  } catch (error) {
      console.error("Error submitting feedback:", error);
      throw error;
  }
}

  static async getNewRecipe(recipeid,personalized ) {
    console.log(recipeid, 'my new recipe id')
    console.log(personalized, 'personalized')
    const url = `${config.API_SERVER_URL}/api/v1/recipes/${recipeid}?personalized=${personalized}`;
    const authToken = localStorage.getItem('userToken');
    const headers = {
      'accept': 'application/json',
      'Authorization': `Bearer ${authToken}`
    };

   const data = await fetch(url, {
      method: 'GET',
      headers: headers
    })
    const response = await data.json();
    if (response.status !== 200 && response.status !== 201) {
      console.log("response is", response)
    };
    console.log("response is are", response)
    return response

  }
  static async getNewRecipeid(recipeid) {
    console.log("getNewRecipeid recipeid", recipeid)
    const url = `${config.API_SERVER_URL}/api/v1/recipes/getrecipeId/${recipeid}`;
    const authToken = localStorage.getItem('userToken');
    const headers = {
      'accept': 'application/json',
      'Authorization': `Bearer ${authToken}`
    };

   const data = await fetch(url, {
      method: 'GET',
      headers: headers
    })
    const response = await data.json();
console.log("myres", response)
    return response

  }
}

export default SmartRecipeService;
