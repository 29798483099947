import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';

//
import '../css/components/TagPill.css';

//
const TAG_COLORS = ['red', 'burntorange', 'blue', 'darkgreen', 'purple', 'magenta', 'orange', 'teal', 'navy', 'maroon', 'fuchsia', 'lime', 'olive'];
const DEFAULT_COLOR = 'green';

//

class TagPill extends Component {

    constructor (props, removable=false) {
        super(props);
        this.state = {
            label: undefined,
            color: undefined,
            link: undefined,
            size: '',
            removable
        };
    }

    componentDidMount () {
        if (this.props.color?.charAt(0) === '#') {
            document.documentElement.style.setProperty('--tag-color', `${this.props.color}66`);
            document.documentElement.style.setProperty('--tag-outline-color', `${this.props.color}99`);
        }
    }

    handleOnClick = async (label) => {
        if (this.props.onClick) await this.props.onClick(label);
        if (this.props.link && this.props.navigate) this.props.navigate(this.props.link);
    }

    render () {

        let { label, color, size } = this.props;
        let { removable=null } = this.state;

        if (color?.charAt(0) === '#') color = 'custom';
        if (color === 'random') color = TAG_COLORS[Math.floor(Math.random() * TAG_COLORS.length)];

        const tagPillOutlineClass = `tag-outline-${color}`;
        const tagPillColorClass = color ? `tag-${color}` : `tag-${DEFAULT_COLOR}`;
        const tagPillSizeClass = size ? `tag-${size}` : ``;
        const tagPillClass = `tag ${tagPillOutlineClass} ${tagPillColorClass} ${tagPillSizeClass}`;
        const removeButton = removable === true ? <FontAwesomeIcon icon={faRemove} className='tag-remove-button' /> : <></>;

        return (<div key={label} onClick={() => this.handleOnClick(label)} className={tagPillClass}>{label}{removeButton}</div>);

    }

}

export default TagPill;