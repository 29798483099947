import React from "react";
import { Link } from "react-router-dom";

class MenuBar extends React.Component {
  render() {
    return (
      <header className="bg-[#0e4621] flex items-center justify-center py-2 shadow-lg mobile-menu">
        <div className="container mx-auto flex justify-center">
          {/* Logo Centered */}
          <Link to="/" className="flex items-center justify-center">
            <img
              src="/Ladle_app_highres_rounded_square.png"
              alt="Ladle Logo"
              className="h-4 menubar-logo w-4 md:h-24 md:w-24 lg:h-20 lg:w-40"
            />
          </Link>
        </div>
      </header>
    );
  }
}

export default MenuBar;
