import React, { Component } from 'react';
import LoadingIcons from 'react-loading-icons';

import RecipeGrid from '../../components/RecipeGrid';
import RemovableTagPill from '../../components/RemovableTagPill.js';
import RecipeService from '../../services/recipe';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faExclamationTriangle, faRefresh, faTemperatureEmpty } from '@fortawesome/free-solid-svg-icons';

import '../../css/recipes.css';
import SortDropdown from '../../components/SortDropdown.js';
import { Player } from '@lottiefiles/react-lottie-player';
import loader from '../../loader/main_loading_animation.json';
import toast from 'react-hot-toast';

const RECIPES_PER_PAGE = 12;

const defaultRequestOptions = {
  offset: 0,
  hideDetails: true,
  limit: RECIPES_PER_PAGE,
}

class SearchIngredients extends Component {
  constructor(props) {
    super(props);
    document.title = `Ladle - Recipes`;

    // Extract ingredients from query params
    const queryParams = new URLSearchParams(this.props.location.search);
    const ingredients = queryParams.get('ingredients') ? queryParams.get('ingredients').split(',') : [];

    this.state = {
      recipes: undefined,
      user: this.props.user,
      totalRecipesLength: 0,
      ingredientsList: ingredients,
      options: {
        ...defaultRequestOptions,
        ingredients: ingredients,
      },
      isLoading: true,
      error: false,
    };
  }

  async componentDidMount() {
    this.updateOptions();
  }

  componentDidUpdate(prevProps) {
    const prevQueryParams = new URLSearchParams(prevProps.location.search);
    const currentQueryParams = new URLSearchParams(this.props.location.search);
    
    if (prevQueryParams.get('ingredients') !== currentQueryParams.get('ingredients')) {
      this.updateOptions();
    }
  }

  async updateOptions(existingOptions = {}) {
    let { ingredientsList } = this.state;
    let options = {
      ...defaultRequestOptions,
      ...existingOptions,
      ingredients: ingredientsList,
      offset: 0, // Start from the beginning when ingredients change
    };

    this.setState({ isLoading: true, options });
    this.getRecipes(options);
  }

  async getRecipes(options) {
    console.log({ msg: 'getRecipes', options });

    let { user } = this.props;
    let recipes;

    try {
      const page = Math.floor(options.offset / RECIPES_PER_PAGE) + 1;
      recipes = await RecipeService.whatsInMyKitchen({ ...options, page }, user);
      if(!recipes.recipes) {
        toast("End Of Recipes", {
            position: 'top-right'
        });
        
      }
      this.setState({
        recipes: recipes.recipes,
        options,
        isLoading: false,
        error: false,
        totalRecipesLength: recipes.recipes.length,
      });

    } catch (err) {
      console.error('getRecipes.error:', err);
      this.setState({ recipes: [], options, isLoading: false, error: err.message });
    }
  }

  handlePreviousClick = () => {
    let { options } = this.state;
    let offset = options.offset - RECIPES_PER_PAGE;
    if (offset < 0) offset = 0;
    options.offset = offset;
    this.setState({ options, isLoading: true }, () => this.getRecipes(options));
  };

  handleNextClick = () => {
    let { options } = this.state;
    let offset = options.offset + RECIPES_PER_PAGE;
    options.offset = offset;
    this.setState({ options, isLoading: true }, () => this.getRecipes(options));
  };

  reload = () => {
    this.setState({ recipes: undefined, error: false, isLoading: true });
    this.getRecipes(this.state.options);
  };

  render() {
    const { recipes, totalRecipesLength, options, isLoading, error, ingredientsList } = this.state;

    const loadingText = `Loading Recipes`;

    let contentSectionHtml;

    // Generate tag pills for ingredients
    const tagPillsHtml = ingredientsList.map((ingredient, index) => (
      <RemovableTagPill key={index} label={ingredient} color='green' size='large' />
    ));

    let recipesToShow = recipes ? recipes : [];
    console.log(recipes, "searchrecipes");
    if (error) {
      contentSectionHtml = (
        <div className="error-container">
          <p className="error-message">
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ paddingRight: '12px' }} />
            Unable to load recipes. Please check your server connection and try again.
          </p>
          <button className='outline' onClick={this.reload}>
            <FontAwesomeIcon icon={faRefresh} style={{ paddingRight: '12px' }} />
            Retry
          </button>
        </div>
      );
    } else if (isLoading) {
      contentSectionHtml = (
        <div className="loading-container">
          <p style={{ fontSize: '16pt', marginBottom: '32px' }}>{loadingText}</p>
          <LoadingIcons.TailSpin height="48px" stroke="#0e4621" fill="#0e4621" />
        </div>
      );
    } else if (recipes.length === 0) {
      contentSectionHtml = (
        <div className="no-results-container">
          <p className="no-results-message">
            <FontAwesomeIcon icon={faTemperatureEmpty} style={{ paddingRight: '12px' }} />
            No recipes found
          </p>
        </div>
      );
    } else {
      contentSectionHtml = (
        <div>
          <div className="recipe-grid-container">
            <RecipeGrid recipes={recipesToShow} type='consumer' user={this.props.user} tenant={this.props.tenant} handleTagClick={this.handleTagClick} />
          </div>
          <div className="flex justify-center space-x-4 my-8">
  <button
    className={`px-6 py-3 text-lg font-bold text-white bg-green-600 rounded-full shadow-lg hover:bg-green-700 transition duration-300 ease-in-out ${
      options.offset === 0 ? 'opacity-50 cursor-not-allowed' : ''
    }`}
    disabled={options.offset === 0}
    onClick={this.handlePreviousClick}
  >
    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Previous
  </button>

  <button
    className={`px-6 py-3 text-lg font-bold text-white bg-green-600 rounded-full shadow-lg hover:bg-green-700 transition duration-300 ease-in-out ${
      recipes.length < RECIPES_PER_PAGE ? 'opacity-50 cursor-not-allowed' : ''
    }`}
    disabled={recipes.length < RECIPES_PER_PAGE}
    onClick={this.handleNextClick}
  >
    Next <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
  </button>
</div>

        </div>
      );
    }

    return (
      <div className="search-ingredients-container p-8">
        <div className="content-menu-bar">
          {tagPillsHtml}
        </div>
        
        <h1 className='text-2xl mb-3 font-bold text-[#0e4621] mt-2 mb-3'>Recipes based on your Ingredients </h1>
      
        {contentSectionHtml}
      </div>
    );
  }
}

export default SearchIngredients;
