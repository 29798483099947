//import pino from "pino";
import config from "./config.js";

const level = config.LOG_LEVEL;

const logger = (name) => {
  return {

    debug: (val) => {
      if (typeof val === "object") val = JSON.stringify(val);
      console.log(`${name} : ${val}`);
    },

    info: (val) => console.log(`${name} : ${val}`),

    error: (val) => console.log(`${name} : ${val}`),

  }
}
export default logger;