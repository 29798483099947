import React, { Component } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders } from '@fortawesome/free-solid-svg-icons'

import { capitalizeWord } from '../../utils/capitalizeWord.js'

//

import '../../css/shared.css';
import '../../css/ApplianceRow.css';

//

class BrandApplianceRow extends Component {

    constructor(props) {       
        super(props);
        this.state = {
            activeCount: (Math.ceil(800+(Math.random()*4000))).toLocaleString('en-us'),
        };
    }

    async componentDidMount() {
    }

    render() {

        console.log({msg:'render', 'props':this.props})

        const { applianceType, tenant } = this.props;
        const { model, name, nameShort, category, enabled, capabilities } = applianceType;

        const imageUrl = `/brands/${tenant.id}/appliances/${model.toLowerCase()}.png`;

        return (
            <>
                <TableRow className="applianceRowTableRow">
                    <TableCell className="applianceRowTableCell image">
                        <div className="applianceRowImageContainer">
                            <img className="applianceImage" src={imageUrl} alt={nameShort} />
                        </div>
                    </TableCell>
                    <TableCell className="applianceRowTableCell model">{model}</TableCell>
                    <TableCell className="applianceRowTableCell name">{capitalizeWord(name)}</TableCell>
                    <TableCell className="applianceRowTableCell type">{capitalizeWord(category)}</TableCell>
                    <TableCell className="applianceRowTableCell capabilities">{capabilities.slice(0,3).map(i => capitalizeWord(i)).join(', ')}</TableCell>
                    <TableCell className="applianceRowTableCell activeCount">{this.state.activeCount}</TableCell>
                    <TableCell className={`applianceRowTableCell status ${enabled ? 'status-production' : 'status-staging'}`}>{enabled ? "Active" : "Staging"}</TableCell>
                    <TableCell className="applianceRowTableCellButton manageButton">
                        <button className="white manageApplianceButton" onClick={this.props.onEnableClick}><FontAwesomeIcon className="manageButtonIcon" icon={faSliders} />{enabled ? "Manage" : "Go Live"}</button>
                    </TableCell>
                </TableRow>
            </>
        );
    }
}

export default BrandApplianceRow;
