import React, { Component } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import MarketplaceRow from '../../components/creator/CreatorMarketplaceRow';
import BrandData from '../../data/brandData';

import shuffleObject from '../../utils/shuffleObject';

//

const shuffledRowData = shuffleObject(BrandData);
//

class CreatorMarketplacePage extends Component {

  constructor(props) {

    super(props);

    document.title = `Ladle - Marketplace`;

    this.state = {
      isLoading: true,
      page: 0,
      rowsPerPage: 10,
    };

  }

  //

  componentDidMount() {
  }

  //


  handleChangePage = (event, newPage) => {
    this.setState({page:newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage: parseInt(event.target.value, 10), page:0});
  };

  
  //


  render() {

    console.debug({msg:'render', BrandData});

    const { page, rowsPerPage } = this.state;

    const visibleRows = shuffledRowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (

      <>

        <div className="content-title">Marketplace</div>
        <div className="content-description">Opt-in to share your recipes, connect with manufacturers & brands, and reach more home cooks.</div>

        <TableContainer style={{  border: '1px solid #ddd', borderRadius: 15, padding: 12, backgroundColor: '#fdfdfd' }} component={Paper}>
          <Table sx={{ minWidth: 1040 }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>

        <TableContainer style={{  border: '1px solid #ddd', borderRadius: 15, padding: 12, backgroundColor: '#fdfdfd' }} component={Paper}>
          <Table sx={{ minWidth: 1040 }} aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell><b>Brand</b></TableCell>
                <TableCell align="left"><b>Description</b></TableCell>
                <TableCell align="left"><b>Type</b></TableCell>
                <TableCell align="left"><b>Target Audience</b></TableCell>
                <TableCell align="left"><b>PPC</b></TableCell>
                <TableCell style={{ width: 96 }} align="left"><b>Reach</b></TableCell>
                <TableCell align="left"><b></b></TableCell>
                <TableCell align="left"><b></b></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {visibleRows.map((row) => (<MarketplaceRow key={row.id} row={row} />))}
            </TableBody>

          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={shuffledRowData.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />

      </>

    );

  }

};

export default CreatorMarketplacePage;