import React, { Component } from 'react';
import LoadingIcons from 'react-loading-icons'
import logger from "../../utils/log.js";
import '../../css/consumerprofile.css';

//

const log = logger("pages.consumer.profile");

class ConsumerProfilePage extends Component {

  constructor(props) {

    super(props);

    document.title = `Ladle - Profile & Settings`;

    this.state = {
      user: this.props.user,
      isLoading: true,
      error: undefined,
    };

  }

  //


  async componentDidMount() {

    let { user } = this.props;
    this.setState({ user });

  }

  //

  render() {

    const { isLoading, error, user } = this.state;

    let contentSectionHtml;

    const formData = {
      name: user.name,
      email: user.email,
      password: '',
      location: 'Portland, Oregon',
      temperature: 'Fahrenheit',
      measurement: 'Imperial',
      language: 'English',
      diet: 'vegetarian',
      familySize: 2,
      likes: 'Apples, Bananas, Pasta',
      dislikes: 'Mushrooms, Onions, Fish',
    };

    Object.keys(formData).forEach((key) => {
      const element = document.getElementById(key);
      if (element) {
        element.value = formData[key];
      }
    });

    return (

      <>

        <div className="content-header">
          <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
            <div className="content-title">{`${user.name.split(' ')[0]}'s Profile`}</div>
            <div className="content-description">Adjust your settings and personalization options</div>
          </div>
        </div>

        <form>

          <div className="content-section consumer-profile">

            <div className="tile">
              <h2 style={{fontWeight:200}}>Personal Information</h2>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your name"
                value={formData.name}
                onChange={this.handleChange}
              />

              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your email"
                value={formData.email}
                onChange={this.handleChange}
              />

              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Update your password"
                value={formData.password}
                onChange={this.handleChange}
              />

              <label htmlFor="location">Location:</label>
              <input
                type="text"
                id="location"
                name="location"
                placeholder="Your location"
                value={formData.location}
                onChange={this.handleChange}
              />

            </div>

            <div className="tile">
            <h2 style={{fontWeight:200}}>Preferences</h2>

              <label htmlFor="temperature">Temperature Preference:</label>
              <select
                id="temperature"
                name="temperature"
                value={formData.temperature}
                onChange={this.handleChange}
              >
                <option value="weight">Celsius</option>
                <option value="volume">Fahrenheit</option>
              </select>

              <label htmlFor="measurement">Measurement Settings:</label>
              <select
                id="measurement"
                name="measurement"
                value={formData.measurement}
                onChange={this.handleChange}
              >
                <option value="weight">Weight</option>
                <option value="volume">Volume</option>
              </select>

              <label htmlFor="language">Language:</label>
              <input
                type="text"
                id="language"
                name="language"
                placeholder="Preferred language"
                value={formData.language}
                onChange={this.handleChange}
              />

              <label htmlFor="diet">Diet:</label>
              <select
                id="diet"
                name="diet"
                value={formData.diet}
                onChange={this.handleChange}
              >
                <option value="vegan">Vegan</option>
                <option value="vegetarian">Vegetarian</option>
                <option value="keto">Keto</option>
                <option value="pescetarian">Pescetarian</option>
                {/* Add more diet options as needed */}
              </select>

              <label htmlFor="family-size">Family Size:</label>
              <input
                type="number"
                id="family-size"
                name="familySize"
                placeholder="Number of family members"
                value={formData.familySize}
                onChange={this.handleChange}
              />
            </div>

            <div className="tile">
              <h2 style={{fontWeight:200}}>Food Preferences</h2>
              <label htmlFor="likes">Ingredients/Food I Like:</label>
              <textarea
                id="likes"
                name="likes"
                placeholder="List of liked ingredients/foods"
                value={formData.likes}
                onChange={this.handleChange}
              ></textarea>

              <label htmlFor="dislikes">Ingredients/Food I Dislike:</label>
              <textarea
                id="dislikes"
                name="dislikes"
                placeholder="List of disliked ingredients/foods"
                value={formData.dislikes}
                onChange={this.handleChange}
              ></textarea>
            </div>

            <button type="button">Save Settings</button>

          </div>

        </form>


      </>

    );

  }

}

export default ConsumerProfilePage;