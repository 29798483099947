import React, { Component } from 'react';
import config from "../utils/config.js";
import OpenAI from 'openai';
import { X } from 'lucide-react';

class ConsumerPersonaliseChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      userMessages: [{
        role: "assistant", content: "Hey there!"
      },
      {
        role: "assistant", content: "Want to add or replace an ingredient in this recipe? Just ask me!"
      }],
      promptMessages: [],
      loading: false,
      input: "",
      ingredientUpdated: false
    }
  }
  componentDidMount() {
    console.log("we think", this.props.recipe);
  }
  async changeRecipe(e) {
    this.setState({
      loading: true,
      input: ""
    })
    let ingredients = []
    this.props.recipe.ingredients_list.forEach((ingredient) => {
      ingredients.push(ingredient[0])
    })
    const promptTemplate = [];
    // const newIngredients = [];
    promptTemplate.push(...this.state.promptMessages, {
      role: 'assistant', content: `You're a sous chef assistant and always you sound like a professional chef. now your job is to help update user preferred ingredient, diet, cookware and answer questions like calories prep methods in the receipe provided. Only give information on recipe provided, Refrain from answering any other questio unless it's related to recipe such as calories etc
          Follow the steps below
          Step 0 : Always get user confirmation on what ingredient/cookware you're about change before changing/giving final output.
            Step 1 - User Asks a question, if it's about ingredient or cookware or dietery change, go to step 2 or otherwise answer
            Step 2 - If user says he wants to change a ingredient/cookware/dietery preference or don't have it.. based on the recipe name provided check if the ingredient is main ingredient (for example, lemon is main in lemon rice). if main goto step 3 or suggest a new ingredient without any additional texts and ask if you need to proceed
            Step 3 - If it's main ingredient your response should be -  Hey, lemon in lemon rice is a key ingredient. If I change this then it will become a new recipe. Would you want to continue?
            Step 4 - If user says yes, then update that ingredient with new one and return the ingredient. Make sure the ingredient change make sense.. like you cannot add sugar in traditional coffee right? and only change the one ingredient after suggesting it unless asked otherwise. For instance ask the user if they want to make changes to dietery preference or just the ingredient if the change requires dietery change unless just one. You can reject and let the user know if the ingredient doen't make sense.            important: When user says yes to change - return ingredient/cookware only as final response with ingredient: or Cookware: and importantly don't ask the user to give a ingredient or cookware, always you suggest those and ask if you can continue with the change
            important: When user says yes to change - return ingredient/cookware only as final response with ingredient: or Cookware:
              Remember you don't need to overthink as well. If user asks to remove cheese that doesn't me if need to make the dish dairy free. but then if recipe doesn't makes without it kindly say it and give options
            Example 
            Ingredient: new: 1/2 cup of white onion, old: old_id 
            if cookware
            Example 
            Cookware: new: Ladle, old: spoon 
            and don't add . or anything after old_id
            remember above is only example you're giving answers for specific ingrediet/cookware that user asks for the specific recipe provided
            think, follow steps and answer carefully. 
           }` })
    promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe Name is ${this.props.recipe.title}, and description is ${this.props.recipe.description},} their calories are Low: ${this.props.recipe.calories.low}, high: ${this.props.recipe.calories.high}}` }) //simple value

    ingredients.forEach((index, ingre) => {
      promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe steps are ${ingre}, ${index.name} their unique id is ${index.id}. consider this whole as one step` }) //simple value
    })
    this.props.recipe.steps_list.forEach((index, ingre) => {
      promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe making instructions are ${index.text}` }) //simple value
    })
    this.props.recipe.equipments_list.forEach((index, ingre) => {
      promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe making cookware & appliances  are ${index.name} and their type is ${index.type} ` }) //simple value
    })
    if (this.state.userMessages.length > 0) {
      promptTemplate.push(...this.state.promptMessages, ...this.state.userMessages)
    }
    promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `${this.state.message}` }) //simple value
    this.setState({ userMessages: [...this.state.userMessages, { role: "user", content: this.state.message }] }) //simple value

    try {
      const openai = new OpenAI({
        apiKey: config.OPENAI_KEY,
        dangerouslyAllowBrowser: true// This is the default and can be omitted
      });
      const chatCompletion = await openai.chat.completions.create({
        messages: promptTemplate,
        model: 'gpt-4o',
      });
      if ((chatCompletion.choices[0].message.content).toLowerCase().includes("ingredient:") || (chatCompletion.choices[0].message.content).toLowerCase().includes("ingredients:") || (chatCompletion.choices[0].message.content).toLowerCase().includes("cookwares:") || (chatCompletion.choices[0].message.content).toLowerCase().includes("cookware:")) {
        this.setState({
          loading: true
        })
        this.setState({ userMessages: [...this.state.userMessages, chatCompletion.choices[0].message] })
        this.setState({ userMessages: [...this.state.userMessages, { role: "assistant", content: "Quality check started... I'll update the ingredients and instructions if this <b>change request passes</b> our food quality test." }] })

        const ingredientString = chatCompletion.choices[0].message.content;

        const colonIndex = ingredientString.indexOf(':');

        const substring = ingredientString.substring(colonIndex + 1);

        const keyValuePairs = substring.split(', ');

        const oldPair = keyValuePairs.find(pair => pair.includes('old'));

        const oldValue = oldPair.split(': ')[1].trim();
        let newrecipe = this.props.recipe;
        let animatevalue;

        promptTemplate.push(...this.state.promptMessages, {
          role: 'user', content: `Hey chef, Your job is now to help updating the recipe title, description and ingredient, steps and give a proper output based on the ingredient you suggested. Think and change appropriately and give good results please.
          For instance if user asks for dairy free version, change only appropriate dairy options.
        also please make sure if cookware is changed, please adjust the time according to thr cooware you use
        response example below.
        give proper json and do not change the Data structure i given below
        Keep in mind do not change structure all all important. Steps need all text, instructions and duration. finally never forget to give duration based on cookware changed.
        
        Important step: Remember you are you assitant chef be responsible and don't include removed ingredients like - (Omit bread for serving to reduce calories), remove them and don't give inside ingredients and do not give vague values, ingredients like dairy-free Parmesan alternative etc.
        always give what is exact ingredient for what is dairy free alternative like soy milk
        need all data same as example.. 
        steps, title, description,cookware, ingredients all same data structure as below
        important: Do not change this data structure
        Example:
         {
    "_id": "6694bcde37419e916603c0f7",
    "recipe_id": "663e3fcce2a4c6d8aecff673",
    "source": "https://barefootcontessa.com/recipes/flag-cake",
    "title": "Flag Cake",
    "tags": [
        "Sides",
        "Kosher",
        "Eggs",
        "Egg",
        "Baking"
    ],
    "servings_count": 16,
    "total_time": 60,
    "prep_time": 30,
    "cook_time": 30,
    "description": "Indulge in a patriotic Flag Cake made with a moist vanilla sponge, creamy icing, and adorned with fresh blueberries and raspberries. This visually stunning dessert is perfect for celebrations, with a balance of sweet and tangy flavors that will impress guests of all ages.",
    "ingredients_list": [
        "1 4/5 tablespoon ( 1/5 stick ) unsalt butter , at room temperatur",
        "3/10 cup sugar",
        "3/5 extra-larg egg , at room temperatur",
        "1/10 cup sour cream , at room temperatur",
        "1/6 teaspoon pure vanilla extract",
        "3/10 cup all-purpos flour",
        "1/30 cup cornstarch",
        "1/10 teaspoon kosher salt",
        "1/10 teaspoon bake soda",
        "for the ice :",
        "2/5 pound ( 4/5 stick ) unsalt butter , at room temperatur",
        "3/10 pound cream chees , at room temperatur",
        "1/10 pound confection ’ sugar , sift",
        "1/6 teaspoon pure vanilla extract",
        "to assembl :",
        "1/5 half-pint blueberri",
        "3/10 half-pint raspberri"
    ],
    "steps_list": [
        {
            "number": 1,
            "text": "Heat the oven to 350 degrees. Butter and flour an 18 x 12 x 1-1/2-inch sheet pan.",
            "annotatedText": "Heat the {{a:oven:6557f80f79f50cfc4003d4f7}} to 350 degrees. Butter and {{s:flour:65766342aa11e4b0e50caec1}} an 18 x 12 x 1-1/2-inch {{c:sheet:6566708a449826ac2f9bacf4}} {{c:pan:6557f80d79f50cfc4003d4e0}}.",
            "duration": 10,
            "summary": "Preheat oven, prep pan"
        },
        {
            "number": 2,
            "text": "Cream the 1 4/5 tablespoon ( 1/5 stick ) unsalt butter , at room temperatur and 3/10 cup sugar in the bowl of an electric mixer fitted with the paddle attachment for 3 minutes on medium speed, until light and fluffy.",
            "annotatedText": "Cream the 1 4/5 {{c:tablespoon:65bc785dea81c1a3a5a309ca}} ( 1/5 stick ) un{{s:salt:656e5acd5c7fd483f85150b2}} {{s:butter:657645a6ecd044f37adf6a8a}} , at room temperatur and 3/10 cup {{s:sugar:65764e40317cf97e149077fb}} in the {{c:bowl:6557f016c6f6dfed8354f1ea}} of an {{a:electric mixer:655bc320f01e68da9322c342}} fitted with the paddle attachment for 3 minutes on medium speed, until light and fluffy.",
            "duration": 3,
            "summary": "Cream butter, sugar"
        },
        {
            "number": 3,
            "text": "Sift together the 3/10 cup all-purpos flour, 1/30 cup cornstarch, 1/10 teaspoon kosher salt, and 1/10 teaspoon bake soda in a bowl. With the mixer on low, add the flour mixture and mix until just combined.",
            "annotatedText": "Sift together the 3/10 cup all-purpos {{s:flour:65766342aa11e4b0e50caec1}}, 1/30 cup {{s:cornstarch:657676ef06ff6492155f5b31}}, 1/10 tea{{c:spoon:6557fa1ecd1217df22b56dd8}} {{s:kosher salt:657676ee06ff6492155f5b27}}, and 1/10 tea{{c:spoon:6557fa1ecd1217df22b56dd8}} bake soda in a {{c:bowl:6557f016c6f6dfed8354f1ea}}. With the {{c:mixer:65c1d421062ae5bd6a661675}} on low, add the {{s:flour:65766342aa11e4b0e50caec1}} mixture and mix until just combined.",
            "duration": 5,
            "summary": "Sift, mix dry ingredients"
        },
        {
            "number": 4,
            "text": "Smooth the top and bake in the center of the oven for 20 to 30 minutes, until a toothpick comes out clean.",
            "annotatedText": "Smooth the top and bake in the center of the {{a:oven:6557f80f79f50cfc4003d4f7}} for 20 to 30 minutes, until a {{s:toothpick:65764c7aff87f30203313252}} comes out clean.",
            "duration": 25,
            "summary": "Bake the cake"
        },
        {
            "number": 5,
            "text": "For the icing, combine the 2/5 pound ( 4/5 stick ) unsalt butter , at room temperatur, 3/10 pound cream chees , at room temperatur, and 1/6 teaspoon pure vanilla extract in the bowl of an electric mixer and mix until very smooth.",
            "annotatedText": "For the icing, combine the 2/5 pound ( 4/5 stick ) un{{s:salt:656e5acd5c7fd483f85150b2}} {{s:butter:657645a6ecd044f37adf6a8a}} , at room temperatur, 3/10 pound cream chees , at room temperatur, and 1/6 tea{{c:spoon:6557fa1ecd1217df22b56dd8}} pure vanilla extract in the {{c:bowl:6557f016c6f6dfed8354f1ea}} of an {{a:electric mixer:655bc320f01e68da9322c342}} and mix until very smooth.",
            "duration": 5,
            "summary": "Make the icing"
        },
        {
            "number": 6,
            "text": "Spread three quarters of the icing on the cake. Pipe stars on top of the 1/5 half-pint blueberri.",
            "annotatedText": "Spread three quarters of the icing on the cake. Pipe stars on top of the 1/5 half-pint blueberri.",
            "duration": 10,
            "summary": "Ice and decorate cake"
        }
    ],
    "cuisine": [
        [
            "american"
        ]
    ],
    "diet": [
        [
            "kosher"
        ]
    ],
    "method": [
        "Baking"
    ],
    "hero_image_src": "https://d14iv1hjmfkv57.cloudfront.net/assets/recipes/flag-cake/_600x600_crop_center-center_61_line/75063/IMG_1153.jpg?v=1708577662",
    "calories": 379,
    "nurtrition_table": "  - Calories: 379 Kcal - Carbohydrates: 45g - Protein: 4g - Fat: 21g - Saturated Fat: 13g - Cholesterol: 92mg - Sodium: 236mg - Fiber: 1g - Sugars: 32g ",
    "equipments_list": [
        "Sheet Pan",
        "Electric Mixer",
        "Paddle Attachment",
        "Bowl",
        "Sifter",
        "Rubber Spatula",
        "Toothpick",
        "Pastry Bag",
        "Star Tip"
    ],
    "enriched_keys": [
        {
            "tags": {
                "prev": "N/A",
                "new": [
                    "Sides",
                    "Kosher",
                    "Eggs",
                    "Egg",
                    "Baking"
                ]
            },
            "description": {
                "prev": "N/A",
                "new": "Indulge in a patriotic Flag Cake made with a moist vanilla sponge, creamy icing, and adorned with fresh blueberries and raspberries. This visually stunning dessert is perfect for celebrations, with a balance of sweet and tangy flavors that will impress guests of all ages."
            },
            "equipments_list": {
                "prev": "N/A",
                "new": [
                    "Sheet Pan",
                    "Electric Mixer",
                    "Paddle Attachment",
                    "Bowl",
                    "Sifter",
                    "Rubber Spatula",
                    "Toothpick",
                    "Pastry Bag",
                    "Star Tip"
                ]
            },
            "cuisine": {
                "prev": "N/A",
                "new": [
                    "american"
                ]
            },
            "diet": {
                "prev": "N/A",
                "new": [
                    "kosher"
                ]
            },
            "method": {
                "prev": "N/A",
                "new": [
                    "Baking"
                ]
            },
            "estimated_calories": {
                "prev": "N/A",
                "new": "379 Kcal per serving"
            },
            "nurtritional_info": {
                "prev": "N/A",
                "new": "  - Calories: 379 Kcal - Carbohydrates: 45g - Protein: 4g - Fat: 21g - Saturated Fat: 13g - Cholesterol: 92mg - Sodium: 236mg - Fiber: 1g - Sugars: 32g "
            },
            "prep_time": {
                "prev": "N/A",
                "new": 30
            },
            "total_time": {
                "prev": "N/A",
                "new": 60
            },
            "cook_time": {
                "prev": "N/A",
                "new": 30
            }
        }
    ],
    "changes": [
        {
            "previous": "For the Icing:",
            "new": "for the ice :"
        },
        {
            "previous": "To Assemble:",
            "new": "to assembl :"
        }
    ],
}
              
        ` })
        const openaiNew = new OpenAI({
          apiKey: config.OPENAI_KEY,
          dangerouslyAllowBrowser: true// This is the default and can be omitted
        });
        const chatCompletionnew = await openaiNew.chat.completions.create({
          messages: promptTemplate,
          model: 'gpt-4o-mini',
          response_format: { type: 'json_object' }
        });
        let newrecipeJson = JSON.parse(chatCompletionnew.choices[0].message.content);
      

        let currentRecipe = JSON.parse(localStorage.getItem('personalized_recipe'));

        // Update the necessary fields
        currentRecipe.personlized_recipe.steps_list = newrecipeJson.steps_list;
        currentRecipe.personlized_recipe.ingredients_list = newrecipeJson.ingredients_list;
        currentRecipe.personlized_recipe.equipments_list = newrecipeJson.equipments_list;
        currentRecipe.personlized_recipe.title = newrecipeJson.title;
        currentRecipe.personlized_recipe.description = newrecipeJson.description;


        // Save the updated recipe back to localStorage
        localStorage.setItem('personalized_recipe', JSON.stringify(currentRecipe));
        this.setState({ userMessages: [...this.state.userMessages, { role: "assistant", content: "Done!" }
        ] })

          this.setState({
            loading: false
          })
          window.location.reload();

      } else {
        this.setState({ userMessages: [...this.state.userMessages, chatCompletion.choices[0].message] }) //simple value
      }
      this.setState({ message: '' });
      this.setState({
        loading: false
      })
    } catch (error) {
      console.log('Error processing your request', error);
    }


  }
  render() {
    const messages = [
      { text: "Hey there! How are you doing?", time: "10:00 AM", sender: "user" },
      { text: "I'm good, thank you! How about you?", time: "10:05 AM", sender: "bot" }
    ];
    this.changeRecipe = this.changeRecipe.bind(this)

    return (
      <div className="max-w-lg mx-auto bg-white" style={{
        position: "sticky",
        top: "14%",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        overflowY: "none",
        height: "85vh"
      }}>
        <div className="flex flex-col bg-white rounded-3xl">
          <div className="p-6 rounded-t-2xl text-white font-bold text-center text-xl" style={{
            background: "linear-gradient(to right,  #0c5212 0%,  #3ba958 35%)"
          }}>
            Chat with our Sous Chef
          </div>
          <div className="flex-grow overflow-y-scroll" style={{
            height: "70vh",
            marginBottom: "40px"
          }}>
            <div className="flex flex-col space-y-2 p-4">
              {this.state.userMessages.map((message, index) => (
                <div key={index} className={`flex items-center ${message.role === 'user' ? 'self-end' : 'self-start'}`}>

                  <div className={`rounded-[10px] px-4 py-2 ${message.role === 'user' ? 'bg-green-600 text-white rounded-br-none' : 'bg-gray-300 rounded-bl-none'}`}
                    style={{
                      boxShadow: "0 2px 2px rgba(0,0,0,0.2)",
                      wordWrap: "break-word",
                      overflowWrap: "break-word"
                    }}>

                    <p style={{ wordWrap: "break-word", maxWidth: "330px", overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: message.content }}>
                    </p>
                  </div>
                </div>
              ))}
              {this.state.loading && (
                <div className="mt-6">
                  <div className="typing">
                    <span className="circle scaling"></span>
                    <span className="circle  scaling"></span>
                    <span className="circle scaling"></span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="flex items-center p-3 bg-white sticky bottom-0 shadow-md"
            style={{
              maxHeight: '60px',
              boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)' // Subtle shadow inside the textarea
            }}
          >
            {this.state.loading ? <div className="flex-grow outline-none opacity-55 text-gray-400" style={{
              border: 'none',
              resize: 'none',
              outline: 'none',
              width: '100%', // Ensure full width within the container
              minHeight: '70px', // Minimum height to accommodate text
            }}>Processing...</div> : <textarea
              type="text"
              id='input-field'
              value={this.state.input}
              placeholder='Type your message...'
              wrap='soft'
              style={{
                border: 'none',
                resize: 'none',
                outline: 'none',
                margin: 0,
                padding: '1rem',
              }}

              onChange={(e) => this.setState({ message: e.target.value, input: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();  // Prevent the default action
                  if (e.target.value === "") return
                  this.changeRecipe(e);
                }
              }}
              className="flex-grow outline-none"
            />}
            <button
              disabled={this.state.loading || this.state.input === ""}
              className="ml-4 bg-green-600 text-white rounded-full p-2"
              style={{
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              onClick={this.changeRecipe}
            >
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-6 h-6">
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }
}


export default ConsumerPersonaliseChat;
