import React, { Component } from 'react';
import SideBar from '../../components/SideBar';
import logger from "../../utils/log.js";
import '../../css/home.css';
//


//

const log = logger("pages.creator.editprofile");

//

const getGreeting = (name) => {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return `Good morning, ${name}!`;
  } else if (currentHour >= 12 && currentHour < 18) {
    return `Good afternoon, ${name}!`;
  } else {
    return `Good evening, ${name}!`;
  }
};


class CreatorEditProfilePage extends Component {

  constructor(props) {
    super(props);
    document.title = `Ladle - Edit Profile`;
    this.state = {};
  }

  componentDidMount() {
  }

  render() {

    const creatorFirstName = 'Zoe';

    return (

      <>
        <section className="section-title"><h2>{getGreeting(creatorFirstName)}</h2></section>

        <section className="section-primary">
          <section className="section-sidebar"><SideBar userType='creator' /></section>
          <section className="section-content">

            <div className="content-title">Edit Your Profile</div>
            <div className="content-description">Update your profile and preferences.</div>


            <div className="content-section">

              <div className="tile">

                <div style={{ marginBottom: '48px', width: '960px', height: '820px' }}>
                </div>
              </div>
            </div>

          </section>
        </section>

      </>

    );

  }

};

export default CreatorEditProfilePage;